import React, { useState, useEffect } from "react";
import Layout from "layouts/Layout/Layout";
import { utils, read, write } from "xlsx";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Select,
} from "@chakra-ui/react";
import axios from "axios";
import {
  AddIcon,
  CheckCircleIcon,
  DeleteIcon,
  WarningTwoIcon,
} from "@chakra-ui/icons";
import { toast } from "react-toastify";
import AddAudimembermodel from "./components/AddAudimembermodel";
import { Link, useNavigate } from "react-router-dom";
import useOrderStore from "contexts/Userhook";
const serverURL = process.env.REACT_APP_BACKEND_URL;
export default function Overview() {
  const { storedUser } = useOrderStore();
  const [isOpen, setIsOpen] = useState(false);
  const [audiences, setAudiences] = useState([]);
  const [AddmemeberaudiModel, setAddmemeberaudiModel] = useState(false);
  const [deleteaudiIndex, setdeleteaudiIndex] = useState("");
  const [AudiID, setAudiID] = useState("");
  const [AudiName, setAudiName] = useState("");

  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [duplicateEmails, setDuplicateEmails] = useState([]);

  const [deletaudiencesLoading, setdeletaudiencesLoading] = useState(false);
  const [AlertModel, setAlertModel] = useState(false);
  const [deletedAudiences, setDeletedAudiences] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState("");
  const [AlertMessage, SetAlertMessage] = useState({
    Inserted: "",
    uploaded: "",
    Totalduplicate: [],
    Audience: {},
  });
  const handleShowDuplicateModal = () => {
    setShowDuplicateModal(true);
    setDuplicateEmails(AlertMessage.Audience.duplicateEmails);
  };
  useEffect(() => {
    async function fetchDeletedAudiences() {
      console.log("a====>", storedUser._id);
      try {
        const { data } = await axios.get(
          `${serverURL}/api/Mygetdropdown/${storedUser._id}`
        );

        setDeletedAudiences(data);
      } catch (error) {
        console.log(error);
      }
    }

    fetchDeletedAudiences();
  }, [storedUser._id]);
  const [formData, setFormData] = useState({
    audienceTitle: "",
  });

  const handleAudienceSelect = (audienceId) => {
    setSelectedAudience(audienceId);
    console.log("aaaaaaaaaaaaaa", audienceId);
    navigate(`/audience-members/${audienceId}`);
  };
  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${serverURL}/api/createAudience`, {
        ...formData,
        user: storedUser._id,
      });
      console.log(response.data);
      setIsOpen(false);
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    async function fetchAudiences() {
      let Url=storedUser.role==="manager"?`${serverURL}/api/MygetAudience/${storedUser.roleby}`:`${serverURL}/api/MygetAudience/${storedUser._id}`
      try {
        const { data } = await axios.get(
          Url
        );
        console.log("ppapapap=====>", data);
        if (data) {
          setAudiences(data);
        } else {
          toast.warning(data.message);
        }
      } catch (error) {
        console.log(error);
        let err =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        toast.error(err);
      }
    }

    fetchAudiences();
  }, [storedUser._id, deleteaudiIndex, isOpen, AlertModel]);

  const DeleteAudience = async (x, i) => {
    setdeleteaudiIndex(i);
    try {
      setdeletaudiencesLoading(true);
      const { data } = await axios.delete(
        `${serverURL}/api/deleteAudience/${x}`
      );
      if (data.message === "deleted sucessfully") {
        setdeletaudiencesLoading(false);
        toast.success(data.message);
        setdeleteaudiIndex("");
      }
    } catch (error) {
      let err =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      toast.error(err);
      setdeletaudiencesLoading(false);
      setdeleteaudiIndex("");
    }
  };
  const ADDAudiencememeber = (x) => {
    console.log(x);
    setAddmemeberaudiModel(true);
    setAudiID(x._id);
    setAudiName(x?.audienceTitle);
  };

  const navigate = useNavigate();
  const mymemberpage = (x) => {
    navigate(`/mymember/${x}`);
  };

  const DownloadSampleFile = () => {
    let SampleData = [
      {
        firstName: "faaiz",
        lastName: "alam",
        emailAddress: "faaizalam@gmail.com",
        phoneNumber: "01",
        address: "surjani",
        Customeone: "AnyTetxone",
        Custometwo: "AnyTextTwo",
      },
      {
        firstName: "faaiz",
        lastName: "alam",
        emailAddress: "faaiz123alam@gmail.com",
        phoneNumber: "01",
        address: "surjani",
        Customeone: "AnyTetxone",
        Custometwo: "AnyTextTwo",
      },
    ];
    if (SampleData) {
      const wb = utils.book_new();
      const sheet = utils.json_to_sheet(SampleData);
      utils.book_append_sheet(wb, sheet, "Sheet1");
      const xls = write(wb, { bookType: "xls", type: "array" });
      const blob = new Blob([xls], { type: "application/vnd.ms-excel" });

      const a = document.createElement("a");
      const url = URL.createObjectURL(blob);
      a.href = url;
      a.download = "Audince upload demo";
      a.click();
    }
  };

  return (
    <Layout>
      <Box>
        <FormControl>
          <FormLabel>Select Deleted Audience</FormLabel>
          <Select
            value={selectedAudience}
            onChange={(e) => handleAudienceSelect(e.target.value)}
          >
            <option value="" disabled>
              Select Deleted Audience
            </option>
            {deletedAudiences.map((audience) => (
              <option key={audience._id} value={audience._id}>
                {audience.audienceTitle}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Box width="100%" display="flex" justifyContent="space-between">
          <Box>
            <Button
              color="white"
              backgroundColor="#422AFB"
              onClick={() => setIsOpen(true)}
            >
              Add Audience
            </Button>
          </Box>
          <Box>
            Sample file
            <Box
              color="blue"
              fontSize="16px"
              cursor="pointer"
              onClick={DownloadSampleFile}
            >
              Audience-file.csv
            </Box>
          </Box>
        </Box>
        <Box p={4} overflowX="auto">
          {" "}
          <Table variant="simple" size="md" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Audience Title</Th>
                <Th>Total Memebers</Th>
                <Th>ACtion</Th>
              </Tr>
            </Thead>
            <Tbody>
              {audiences.map((audience, index) => (
                <Tr
                  onClick={() => mymemberpage(audience._id)}
                  _hover={{ backgroundColor: "gray.200" }}
                  style={{ cursor: "pointer" }}
                  key={audience._id}
                  bg={index % 2 === 0 ? "gray.100" : "white"}
                >
                  <Td>{index + 1}</Td>
                  <Td>{audience.audienceTitle}</Td>
                  <Td>{audience.team.length}</Td>
                  <Td>
                    <Td
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        DeleteAudience(audience._id, index);
                      }}
                    >
                      <DeleteIcon />
                    </Td>
                    <Td
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        ADDAudiencememeber(audience);
                      }}
                    >
                      <AddIcon />
                    </Td>
                    {deletaudiencesLoading && deleteaudiIndex === index && (
                      <Td>
                        <Spinner color="red.500" />
                      </Td>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
        <AddAudimembermodel
          audiid={AudiID}
          isOpen={AddmemeberaudiModel}
          setAddmemeberaudiModel={setAddmemeberaudiModel}
          setAlertModel={setAlertModel}
          SetAlertMessage={SetAlertMessage}
        />
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Audience</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel>Audience Title</FormLabel>
                <Input
                  placeholder="Audeience Title"
                  onChange={(e) =>
                    setFormData({ ...formData, audienceTitle: e.target.value })
                  }
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                Save
              </Button>
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        {/* down model is for showing alert after  many audences uploaded */}
        <Modal isOpen={AlertModel} onClose={() => setAlertModel(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Sucessfully Import</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                <CheckCircleIcon
                  color="green"
                  fontSize="20px"
                  fontWeight="extrabold"
                />
                &nbsp;&nbsp; Sucessfully Attempted!
              </Box>
              <Box>
                <CheckCircleIcon
                  color="green"
                  fontSize="20px"
                  fontWeight="extrabold"
                />
                &nbsp;&nbsp; Added to{" "}
                <Link
                  to={`/mymember/${AudiID}`}
                  style={{ color: "blue", fontSize: "18px" }}
                >
                  {AudiName}
                </Link>{" "}
                Audience group
              </Box>
              <br />
              <Box>
                <CheckCircleIcon color="green" fontSize="20px" />
                &nbsp;&nbsp;{AlertMessage.Inserted}
              </Box>
              <br />
              <Box>
                {AlertMessage.uploaded > 0 ? (
                  <>
                    <WarningTwoIcon color="red" fontSize="20px" />
                    &nbsp;&nbsp;
                    {`${AlertMessage.uploaded} emails were not uploaded`}
                  </>
                ) : (
                  <div></div>
                )}
              </Box>
              <Box>
                {AlertMessage.Totalduplicate > 0 ? (
                  <>
                    <WarningTwoIcon color="red" fontSize="20px" />
                    &nbsp;&nbsp;
                    {`${AlertMessage.Totalduplicate} one or more emails were dupliacted`}
                    {console.log("ssssssasasasaa===>", AlertMessage)}
                  </>
                ) : (
                  <div></div>
                )}
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={handleShowDuplicateModal}
                color="white"
                bg="blue"
                mr={10}
              >
                Show Summary
              </Button>
              <Button
                onClick={() => setAlertModel(false)}
                color="white"
                bg="blue"
              >
                Done
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={showDuplicateModal}
          onClose={() => setShowDuplicateModal(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Duplicate Emails Summary</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {duplicateEmails.map((email, index) => (
                <div key={index}>{email}</div>
              ))}
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => setShowDuplicateModal(false)}
                color="white"
                bg="blue"
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Layout>
  );
}
