import axios from "axios";
import { toast } from "react-toastify";

const ServerUrl=process.env.REACT_APP_BACKEND_URL
const Facebook_url=process.env.REACT_APP_Facebook_url
const AppSecret=process.env.REACT_APP_Secret;
const AppID=process.env.REACT_App_ID;
console.log(Facebook_url,"fb",AppSecret,AppID)

const myfunct = async (x,navigate,storedUser ) => {
    console.log(x)
   
   
    
   
   if (x) {
    try {
      const {data:mylongtoken}=await axios.get(`${Facebook_url}/oauth/access_token?grant_type=fb_exchange_token&client_id=${AppID}&client_secret=${AppSecret}&fb_exchange_token=${x.accessToken}`)
     console.log(mylongtoken,"long")
      if (mylongtoken) {
      try {
    
          const { data } = await axios.post(`${ServerUrl}/fb/FBToken`, {
            name:x.name,
            access_token:mylongtoken.access_token,
            user: storedUser._id,
            facebookdata: x,
          });
          if (data) {
            console.log(data);
    
            // navigate("/Facebookpages");
            navigate("/connectfbpage");
       
          }
        } catch (error) {
          toast.error(error.message);
          
        }
        // toast.success(e.data.name, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
     }
      
    } catch (error) {
      toast.error(error.message);
      
    }
   }
   
   
  };














export const handleFacebookLogin = (navigate,storedUser) => {
    window.FB.login(response => {
      if (response.authResponse) {
        console.log(response.authResponse,"rr")
        // User is logged in and granted permissions
        getUserInfo(response.authResponse,navigate,storedUser );
      } else {
        
      }
    }, { scope:'pages_show_list pages_manage_posts pages_read_user_content' });
  };

  const getUserInfo = (x,navigate,storedUser) => {
    window.FB.api('/me', { fields: 'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender' }, response => {
      console.log('Facebook user info:', response);
      myfunct({...x,...response},navigate,storedUser)
      
    });
  };