// chakra imports
import { Box, Flex, HStack, Icon, Stack } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import useOrderStore from "contexts/Userhook";
// import SidebarCard from "components/sidebar/components/SidebarCard";
import { Scrollbars } from "react-custom-scrollbars-2";
import React, { useEffect, useRef } from "react";
import Routes from "../../../routes";
import { MdEmail, MdLogout } from "react-icons/md";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// FUNCTIONS

function SidebarContent(props) {
  const { storedUser, logout } = useOrderStore();
  const { routes } = props;
  console.log(routes, "con");
  // SIDEBAR
  const navigate = useNavigate();
  const logoutfunc = () => {
    // logout();
    logout();
    toast.success("You have logged out successfully");
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };
  const containerRef = useRef(null);

  // useEffect(() => {
  //   const scrollTimeout = setTimeout(() => {
  //     if (containerRef.current) {
  //       console.log("w")
  //       containerRef.current.scrollTop = containerRef.current.scrollHeight;
  //     }
  //   }, 1000); // Adjust the delay as needed

  //   return () => clearTimeout(scrollTimeout);
  // }, []);
  return (
    // <></>
    <Flex
      direction="column"
      height="100%"
      pt="25px"
      px="16px"
      borderRadius="30px"
    >
      <Brand />

      <Stack
        direction="column"
        mb="auto"
        mt="8px"
        maxH="80%"
        overflowY="scroll"
        ref={containerRef}
        sx={{
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            bg: "gray.300",
            borderRadius: "full",
          },
          "&::-webkit-scrollbar-track": {
            bg: "gray.100",
          },
        }}
      >
        <Box ps="20px" pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={Routes} containerRef={containerRef} />
        </Box>
      </Stack>

      <Box mt="0px" mb="60px" borderRadius="20px" bg={"#0B1EFC"}>
        <HStack>
          <Flex
            onClick={logoutfunc}
            w="100%"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
          >
            <Box cursor="pointer" me="18px">
              <Icon as={MdLogout} width="20px" height="20px" color="white" />
            </Box>

            <Box color="white">Logout &nbsp;&nbsp;{storedUser.name}</Box>
          </Flex>
          <Box h="36px" w="4px" borderRadius="5px" />
        </HStack>
      </Box>
    </Flex>
  );
}

export default SidebarContent;
