import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { AppFire } from "../../InstagramComp/Firebase/Firebase";
import axios from "axios";
import { toast } from "react-toastify";
const server = process.env.REACT_APP_BACKEND_URL;
const handleSubmitinsta = async (
  x,
  y,
  postData,
  setPostLoading,
  setPostData
) => {
  for (const i of y) {
    let mediaTypeValue = "";
    let urlEndpoint = "";
    switch (postData.contentType) {
      case "image":
        mediaTypeValue = undefined;
        urlEndpoint = `https://graph.facebook.com/v18.0/${i.instagram_business_account.id}/media?image_url=${x}`;
        break;
      case "reels":
        mediaTypeValue = "REELS";
        urlEndpoint = `https://graph.facebook.com/v18.0/${i.instagram_business_account.id}/media?video_url=${x}`;
        break;
      case "stories":
        mediaTypeValue = "STORIES";
        urlEndpoint = `https://graph.facebook.com/v18.0/${i.instagram_business_account.id}/media?image_url=${x}`;
        break;
      default:
        break;
    }
    const formData = new FormData();
    formData.append("image", x);
    formData.append("caption", postData.caption);
    try {
      console.log(
        i.instagram_business_account.id,
        i.access_token,
        "dekh faaiz"
      );
      setPostLoading(true);
      console.log(i.access_token, "i.instagram_business_account.id");
      const createContainerResponse = await axios.post(urlEndpoint, formData, {
        params: {
          caption: postData.caption,
          access_token: i.access_token,
          media_type: mediaTypeValue,
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const creationId = createContainerResponse.data.id;

      if (postData.contentType === "reels") {
        await new Promise((resolve) => setTimeout(resolve, 20000));
      }
      const response = await axios.get(
        `https://graph.facebook.com/v18.0/${creationId}?fields=status_code`,
        {
          params: {
            access_token: i.access_token,
          },
        }
      );

      await axios.post(
        `https://graph.facebook.com/v18.0/${i.instagram_business_account.id}/media_publish?creation_id=${creationId}&access_token=${i.access_token}`
      );

      setPostLoading(false);
      setPostData({
        image: null,
        caption: "",
        imageUrl: "",
        contentType: "image",
      });
      toast.success("Posted Successfully!");
    } catch (error) {
      setPostLoading(false);
      toast.error(error.message);
    }
  }
};
const saveToMongoDB = async (
  downloadURL,
  contentType,
  imageName,
  message,
  InstaPageIds,
  scheduleTime,
  userdata
) => {
  console.log(userdata,"dekh na")
  try {
    for (const InstaPageId of InstaPageIds) {
      const response = await axios.post(`${server}/api/scheduledpost`, {
        imageUrl: downloadURL,
        caption: message,
        contentType: contentType,
        businessAccountId: InstaPageId.instagram_business_account.id,
        pageID: InstaPageId.id,
        socialPlatform: "Instagram",
        token: InstaPageId.access_token,
        imagename: imageName,
        scheduledTime: scheduleTime,
        user:userdata._id,
        Admin:userdata.roleby
      });
      console.log("Data saved to MongoDB:", response.data);
    }
  } catch (error) {
    console.error("Error saving data to MongoDB:", error);
  }
};
export const handleSubmitUrl = async (
  postData,
  InstaPageIds,
  setPostLoading,
  setPostData,
  scheduleTime,
  addCalendarEvent,
  isManager,
  setAllLoading,
  storedUser
  
) => {
  console.log(
    "DDDDDDDDDDDEEEEEEEEEEEEDDDDDDDDDDDDDDDFFFFFFFFFFFFFFFFFFF123",
    storedUser
  );
  const Storagedb = getStorage(AppFire);
  const storageRef = ref(Storagedb);
  let imageName = postData.image.name;
  console.log("aaaaaaaaaaaaaaaa", imageName);
  const fileRef = ref(storageRef, `${imageName}`);
  await uploadBytes(fileRef, postData.image);
  const downloadURL = await getDownloadURL(fileRef);
  if (isManager) {
    let message = postData.caption;
    console.log("aaaaaaaaaaaaaaaaaaaa", message);
    let contentType = postData.contentType;
    saveToMongoDB(
      downloadURL,
      contentType,
      imageName,
      message,
      InstaPageIds,
      scheduleTime,
      storedUser
    );
    return;
  } else {
    if (scheduleTime) {
      console.log("Pass-2-------->", scheduleTime);
    }
    if (downloadURL) {
      console.log("Pass-1");
      if (scheduleTime && new Date(scheduleTime) > new Date()) {
        console.log("Pass-3");
        toast.success("Post scheduled successfully!");
        addCalendarEvent({
          title: `Posting Scheduled to Instagram`,
          start: scheduleTime,
          end: scheduleTime,
        });
        console.log("sufyan")
        setTimeout(async () => {
          await handleSubmitinsta(
            downloadURL,
            InstaPageIds,
            postData,
            setPostLoading,
            setPostData,
            // InstaPageIds
          );
          await deleteObject(fileRef);
        }, new Date(scheduleTime) - new Date());
      } else {
        console.log("sufyan 1",InstaPageIds)
        await handleSubmitinsta(
          downloadURL,
          InstaPageIds,
          postData,
          setPostLoading,
          setPostData
        );
        await deleteObject(fileRef);
        toast.success("Post submitted successfully!");
      }
    }
  }
};
