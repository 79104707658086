import axios from "axios";
import { toast } from "react-toastify";

export const ConnectedFbPgeApi=async(ServerUrl,storedUser,setdataloading)=>{
  let url=storedUser.role==="manager"?`${ServerUrl}/fb/ConnectedPage/${storedUser.roleby}`:`${ServerUrl}/fb/ConnectedPage/${storedUser._id}`
    try {
      const { data } = await axios.get(
        url
        // `${ServerUrl}/fb/ConnectedPage/${storedUser._id}`
      );
      if (data) {
      
          setdataloading(false);
          console.log("done fb")
          return data
       
       
      }
    } catch (error) {
      toast.error(error.message);
      setdataloading(false);
    }
  
}