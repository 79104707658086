import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Input,
  Switch,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import Layout from "layouts/Layout/Layout";
import axios from "axios";
import useOrderStore from "contexts/Userhook";
import { toast } from "react-toastify";

import { FcCancel } from "react-icons/fc";
import { BsSave } from "react-icons/bs";
import { SwitchButton } from "./SwitchButton";

const server = process.env.REACT_APP_BACKEND_URL;

const AllManagers = () => {
  const [managers, setManagers] = useState([]);
  const [permissiondata, setpermission] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [managerIdToDelete, setManagerIdToDelete] = useState(null);
  const { storedUser } = useOrderStore();
  const [editdata, setEditData] = useState({
    name: "",
    email: "",
    media: false,
    EmailCampaigns: false,
    Audiences: false,
    Emails: false,
    Editors: false,
    ApprovePost: false,
    Calendar: false,
    Manager: false,
    Profile: false,
    AllManager: false,
    id: "",
  });

  useEffect(() => {
    const fetchManagers = async () => {
      try {
        const response = await axios.get(
          `${server}/auth/managers/${storedUser._id}`
        );
        setManagers(response.data.managers);
      } catch (error) {
        console.error("Error fetching managers:", error);
      }
    };

    fetchManagers();
  }, [storedUser._id]);

  const handleDeleteManager = async (managerId) => {
    try {
      await axios.delete(`${server}/auth/manager/${managerId}`);
      const response = await axios.get(
        `${server}/auth/managers/${storedUser._id}`
      );
      setManagers(response.data.managers);
      toast.success("Manager Deleted Successfully");
    } catch (error) {
      console.error("Error deleting manager:", error);
    } finally {
      setManagerIdToDelete(null);
      setShowConfirmationModal(false);
    }
  };

  const handleShowConfirmationModal = (managerId) => {
    setManagerIdToDelete(managerId);
    setShowConfirmationModal(true);
  };
  const handleEdit = (manager) => {
    setEditData({});
    setpermission(manager?.PagesPermission[0]);
    setEditData({
      name: manager.name,
      email: manager.email,
      ...manager?.PagesPermission[0],
      id: manager._id,
      isEmailAllowd:manager?.isEmailAllowd
    });
  };

  const handleCancel = () => {
    setEditData({});
  };

  const handleUpdate = async (managerId) => {
    console.log(editdata, "khan");
    const { email, name, id,isEmailAllowd, ...other } = editdata;
    try {
      await axios.put(`${server}/auth/manager/${managerId}`, {
        email,
        name,
        id,
        PagesPermission: other,
        isEmailAllowd:isEmailAllowd
      });
      const response = await axios.get(
        `${server}/auth/managers/${storedUser._id}`
      );
      setManagers(response.data.managers);
      toast.success("Manager Updated Successfully");
      handleCancel();
    } catch (error) {
      console.error("Error updating manager:", error);
    }
  };
  let permission = [
    "Profile",
    "AllManager",
    "media",
    "EmailCampaigns",
    "Audiences",
    "Emails",
    "Editors",
    "Calendar",
    "ApprovePost",
    "Manager",
    "isEmailAllowd"
    
  ];
  
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    console.log(name, name === "media", "name", value, checked);
    if (permission.includes(name)) {
      console.log("truejh");
      setEditData((prevData) => ({ ...prevData, [name]: checked }));
      return;
    }

    setEditData((prevData) => ({ ...prevData, [name]: value }));
    // const { name, value, type, checked } = e.target;
    // const newValue = type === "checkbox" ? checked : value;
    // setEditData((prevData) => ({
    //   ...prevData,
    //   [name]: newValue,
    // }));
  };

  return (
    <Layout>
      <div style={{ maxHeight: "400px", Width: "100%", overflowY: "auto" }}>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Profile</Th>
            <Th>AllManager</Th>
            <Th>media</Th>
            <Th>EmailCampaigns</Th>
            <Th>Audiences</Th>
            <Th>Manager</Th>
            <Th>Calendar</Th>
            <Th>ApprovePost</Th>
            <Th>Editors</Th>
            <Th>Emails</Th>
            <Th>Is email Allow</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {managers.map((manager, index) =>
            editdata.id === manager._id ? (
              <Tr key={manager._id}>
                {console.log(editdata.id === manager._id, "kkkkk")}
                <Td>{index + 1}</Td>
                <Td>
                  {editdata.id === manager._id ? (
                    <Input
                      name="name"
                      border={"none"}
                      _focus={{ border: "none" }}
                      value={editdata.name}
                      onChange={handleChange}
                    />
                  ) : (
                    manager.name
                  )}
                </Td>
                <Td>
                  {editdata.id === manager._id ? (
                    <Input
                      border={"none"}
                      _focus={{ border: "none" }}
                      name="email"
                      value={editdata.email}
                      onChange={handleChange}
                    />
                  ) : (
                    manager.email
                  )}
                </Td>

                <SwitchButton
                  editdata={editdata}
                  manager={manager}
                  handleChange={handleChange}
                />

                <Td>
                  {editdata.id === manager._id ? (
                    <>
                      <IconButton
                        // m={"1px"}
                        colorScheme="yellow"
                        aria-label="Cancel"
                        icon={<FcCancel />}
                        onClick={handleCancel}
                      />
                      &nbsp; &nbsp;
                      <IconButton
                        m={"1px"}
                        colorScheme="red"
                        aria-label="Save"
                        icon={<BsSave />}
                        onClick={() => handleUpdate(manager._id)}
                      />
                    </>
                  ) : (
                    <>
                      <IconButton
                        colorScheme="red"
                        aria-label="Delete Manager"
                        icon={<DeleteIcon />}
                        // m={"1px"}
                        onClick={() => handleShowConfirmationModal(manager._id)}
                      />
                      &nbsp; &nbsp;
                      <IconButton
                        //  m={"1px"}
                        colorScheme="red"
                        aria-label="Edit Manager"
                        icon={<EditIcon />}
                        onClick={() => handleEdit(manager)}
                      />
                    </>
                  )}
                </Td>
              </Tr>
            ) : (
              <Tr key={manager._id}>
                <Td>{index + 1}</Td>
                <Td p={"1px"}>
                  {/* {editdata.id === manager._id ? ( */}
                  <Input
                    w={"max-content"}
                    name="name"
                    value={manager.name}
                    onChange={handleChange}
                    border={"none"}
                    _focus={{ border: "none" }}
                  />
                </Td>
                <Td p={"5px"}>
                  <Input
                    border={"none"}
                    _focus={{ border: "none" }}
                    w={"max-content"}
                    name="email"
                    value={manager.email}
                    onChange={handleChange}
                  />
                </Td>

                <SwitchButton
                  editdata={manager}
                  manager={manager}
                  handleChange={handleChange}
                />

                <Td display={"flex"}>
                  {/* {editdata.id === manager._id ? (
                  <>
                    <IconButton
                    m={"1px"}
                      colorScheme="yellow"
                      aria-label="Cancel"
                      icon={<FcCancel />}
                      onClick={handleCancel}
                    />
                    &nbsp; &nbsp;
                    <IconButton m={"1px"}
                      colorScheme="red"
                      aria-label="Save"
                      icon={<BsSave />}
                      onClick={() => handleUpdate(manager._id)}
                    />
                  </>
                ) : ( */}
                  <>
                    <IconButton
                      margin={"2px"}
                      colorScheme="red"
                      aria-label="Delete Manager"
                      icon={<DeleteIcon />}
                      // m={"1px"}
                      onClick={() => handleShowConfirmationModal(manager._id)}
                    />
                    &nbsp; &nbsp;
                    <IconButton
                      //  m={"1px"}
                      colorScheme="red"
                      aria-label="Edit Manager"
                      icon={<EditIcon />}
                      onClick={() => handleEdit(manager)}
                    />
                  </>
                </Td>
              </Tr>
            )
          )}
        </Tbody>
      </Table>
      </div>
      <Modal
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this manager?</ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              onClick={() => handleDeleteManager(managerIdToDelete)}
            >
              Yes
            </Button>
            <Button
              variant="ghost"
              onClick={() => setShowConfirmationModal(false)}
            >
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Layout>
  );
};

export default AllManagers;
