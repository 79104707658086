export const openPopup = async (emailEditorRef,setDesginExsits,setIsModalOpen) => {
    
    if (emailEditorRef.current.editor) {
      let y = await new Promise((resolve, reject) => {
        emailEditorRef.current?.editor?.exportHtml((data) => {
          resolve(data.html);
        });
      });
      const paragraphRegex = /<p\b[^>]*>(.*?)<\/p>/gi;
      const imageRegex = /<img\b[^>]*>/gi;

      // Check if <p> tags exist
      const paragraphsExist = paragraphRegex.test(y);
      const imagesExist = imageRegex.test(y);
      console.log(paragraphsExist,imagesExist,"see")
      if (paragraphsExist || imagesExist) {
       
        setDesginExsits(true);
      }
    }

    setIsModalOpen(true);
  };