import axios from "axios";
import { PieChartHook } from "contexts/Charthook";
import useOrderStore from "contexts/Userhook";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
const server = process.env.REACT_APP_BACKEND_URL;
const LineChart = (props) => {
  const { storedUser } = useOrderStore();
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const {LineChartdata}=PieChartHook()
  console.log(props.myfilter,"alam")

  useEffect(() => {
   

    const mydata=async()=>{
      let TotalSent=[0,0,0,0,0,0]
      let TotalSentOpened=[0,0,0,0,0,0]
      const {data}=await axios.get(`${server}/recipient/LineChartdata/Linechart/${storedUser._id}?date=${JSON.stringify(props.myfilter)}`)
    if (data) {
      for (let index = 0; index <6; index++) {
        let sentemail=data.TotalSent[index]?._id
        if (sentemail) {
          const dateObj = new Date(sentemail).getDay();
          const mydata = data.TotalSent[index]?.sent;
          TotalSent[dateObj]=mydata
          }
          let Opendemail=data.TotalOpened[index]?._id
        if (Opendemail) {
          const dateObj = new Date(Opendemail).getDay();
          console.log(dateObj,'z')
          const mydata = data.TotalOpened[index]?.sent;
          TotalSentOpened[dateObj]=mydata
          }
        // console.log(data.TotalSent[0]?._id,"z")

      //   let sent=data.TotalSent[index]?.sent||0
      //   TotalSent.push(sent)
      //  let opend=data.TotalOpened[index]?.sent||0
      //  TotalSentOpend.push(opend)

        



        
      }
    }
    console.log(TotalSent,TotalSentOpened,"z") 

    setChartData([
      {
        name: "Email Sent",
        data: TotalSent
      },
      {
        name: "Email Opened",
        data: TotalSentOpened,
      },
    ]);
  }
  
    mydata()

    
    console.log(props.chartData)
  }, [LineChartdata, props.chartData, props.myfilter, storedUser._id]);

  return (
    <ReactApexChart
      options={{...chartOptions,title: {
        text: 'Email Activities Week',
        align: 'left'
      },
    
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        title: {
          text: 'Week'
        }
      },
    
    }}
      series={chartData}
      type="line"
      width="100%"
      height="100%"
    />
  );
};

export default LineChart;
