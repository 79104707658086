import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useCalendar } from "../context/CalendarContext";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Layout from "layouts/Layout/Layout";
import { BellIcon, EmailIcon } from "@chakra-ui/icons";

const localizer = momentLocalizer(moment);
const server = process.env.REACT_APP_FRONTEND_URL;
const CalendarPage = () => {
  const { events } = useCalendar();

  const handleClick = (event, action) => {
    const selectedDate = event.start;
    switch (action) {
      case "openEmail":
        const urlEmail = `${server}/editor?date=${encodeURIComponent(
          selectedDate
        )}`;
        window.open(urlEmail, "_blank", "width=600,height=600");
        break;
      case "openDetails":
        const url = `${server}/AllinOne?date=${encodeURIComponent(
          selectedDate
        )}`;
        window.open(url, "_blank", "width=600,height=600");
        break;
      default:
        break;
    }
  };

  return (
    <Layout>
      <div>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{
            height: 870,
          }}
          components={{
            event: ({ event }) => (
              <div style={{ backgroundColor: "#F4F7FE" }}>
                <div
                  onClick={() => handleClick(event, "openDetails")}
                  style={{ backgroundColor: "#F9A501" }}
                >
                  <BellIcon mr={2} />
                  {event.title} Social
                </div>
                <br></br>
                <div
                  onClick={() => handleClick(event, "openEmail")}
                  style={{ backgroundColor: "#F60002" }}
                >
                  <EmailIcon mr={2} />
                  {event.title} Email
                </div>
              </div>
            ),
          }}
        />
      </div>
    </Layout>
  );
};

export default CalendarPage;
