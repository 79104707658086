import { Button } from '@chakra-ui/react';
import axios from 'axios';
import { Fbpage } from 'components/AllScocail/FbAllcomponenet/FbAll';
import useOrderStore from 'contexts/Userhook';
import Layout from 'layouts/Layout/Layout'
import React, { useCallback, useEffect, useState } from 'react'
import { FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { ConnectedFbPgeApi } from './Connectedpageapi/ConnectedfbpageApi';
import { useNavigate } from 'react-router-dom';
const ServerUrl = process.env.REACT_APP_BACKEND_URL;
const Connectfbpage = () => {
    const { storedUser } = useOrderStore();
    const [dataloading, setdataloading] = useState(false);
    const [pagesloading, setpagesloading] = useState(false);
    const [allpages, setallpages] = useState([]);
    const [ConnectingPageLoading, setConnectingPageLoading] = useState(false);
     const [FbPageIds, setFbPageIds] = useState([]);

 

    const gettingpageapi = useCallback(async (userid, token) => {
        try {
          setpagesloading(true);
          const { data } = await axios.get(
            `https://graph.facebook.com/v18.0/${userid}/accounts?access_token=${token}&fields=access_token,id,name,cover,category,likes,fan_count`
          );
          if (data) {
            // console.log(data.data, "token 29");
            const pagesWithLikesAndFollowers = data.data.map((page) => ({
              ...page,
              likes: page.likes,
              followers: page.fan_count,
            }));
            const mypages=async()=>{
                let data= await ConnectedFbPgeApi(ServerUrl,storedUser,setdataloading)
                if (data.length>0) {
                    const uIds = data.filter((x)=>!x.Instagram).map((item) => item.id);
                    
                       const allConnected = pagesWithLikesAndFollowers.filter((x) => !uIds.includes(x.id));
                       setallpages(allConnected);
                    
                }else{
                    setallpages(pagesWithLikesAndFollowers);

                }
                }
                mypages()
            
  
            
    
            setpagesloading(false);
          }
        } catch (error) {
         
          let err =
            error.message && error.response.data.error.message
              ? error.response.data.error.message
              : error.message;
          toast.error(err);
          setpagesloading(false);
        }
      }, [storedUser]);
    
    

      useEffect(() => {
       
    
     

        // For the facebook
        const mydata = async () => {
          await Fbpage(ServerUrl, setdataloading, storedUser, gettingpageapi);
          console.log("done fb 2")
        };
        mydata();
      }, [gettingpageapi, storedUser]);
    

  



    const CheckBox = (x) => {
      
          const Exist = FbPageIds?.find((y) => y.id === x.id);
          if (Exist) {
            setFbPageIds((p) => {
              return p.filter((z) => z.id !== x.id);
            });
          } else {
            setFbPageIds((p) => {
              return [...p, x];
            });
          }
        
       
      };


const navigate=useNavigate()
const Connect=async()=>{
    try {
        setConnectingPageLoading(true)
        const data=await axios.post(
            `${ServerUrl}/fb/ConnectPage`,{ConnectedPage:FbPageIds,user:storedUser._id}
          );
          if (data) {
            navigate("/connectedPages")
          }
        
    } catch (error) {
        
    }
      
   

      }
  return (
    <Layout>
    {dataloading && <FaSpinner className="mx-auto mt-4 text-4xl" />}
    <div className="text-center text-4xl font-bold mb-4">Connect the Pages</div>
    {pagesloading && <FaSpinner className="mx-auto mt-4 text-4xl" />}
    <Button onClick={Connect} disabled={FbPageIds.length>0?false:true} bg={"blue.200"}>Connect</Button>
    <div className="flex flex-wrap mx-5">
      {allpages.length > 0
        ? allpages?.map((x) => (
            <div
              key={x.id}
              className="mr-10 my-5 p-5 w-72 border-2 border-black h-[80px] flex  "
            >
              {/* <Input type='checkbox'></Input> */}
              <input
                type="checkbox"
                className="mr-[10px]"
                onClick={() => CheckBox(x)}
              ></input>
              <ul className="flex">
             
                  <li className="mb-2 mr-[15px]">
                    <img
                      src={x?.cover?.source}
                      alt="coverphoto"
                      className="mb-4 h-[50px] w-[50px] "
                    />
                  </li>
                
                {/* <li className="mb-2"> {x.instagram_business_account&&<CgInstagram/>}</li> */}
                <li className="mb-2"> {x.name}</li>
              </ul>
            </div>
          ))
        : "No Pages are Available"}
    </div>

    
  </Layout>
  )
}

export default Connectfbpage