import axios from "axios";
import useOrderStore from "contexts/Userhook";
import { toast } from "react-toastify";
const ServerUrl=process.env.REACT_APP_BACKEND_URL
const Facebook_url=process.env.REACT_APP_Facebook_url
const AppSecret=process.env.REACT_APP_Secret;
const AppID=process.env.REACT_App_ID;
console.log(Facebook_url,"fb",AppSecret,AppID)

const myfunct = async (x,navigate,storedUser ) => {
    console.log(x)
   
   
    
    // https://graph.facebook.com/v18.0/oauth/access_token?grant_type=fb_exchange_token&client_id=1071845430722175&client_secret=0da28418fabd35558ad8d5e7299feb8a&fb_exchange_token=EAAPO1nYmZCn8BO4Se2yZACLrrrmDtUGUYsuQXoNptBYkdcAVvnZCPXxiW606WjT0y8XLxMW8FCySpheHYd1zZCptP027ZAOZAchtGzVKDWvYEWCTHxqma5yQt6Lu4dCA6tGbyaZBJVM7dKJpaq4bBJO5kspQ5rEAtMCDHP4JHIdZAquVztOnWq1ZCS7jLq697AlvYcJUkRJhQdtdgIijwOYp5tK8hisfRK9kX7wZDZD
   if (x) {
    try {
      const {data:mylongtoken}=await axios.get(`${Facebook_url}/oauth/access_token?grant_type=fb_exchange_token&client_id=${AppID}&client_secret=${AppSecret}&fb_exchange_token=${x.accessToken}`)
     console.log(mylongtoken,"long")
      if (mylongtoken) {
      try {
    
          const { data } = await axios.post(`${ServerUrl}/fb/FBToken`, {
            name:x.name,
            access_token:mylongtoken.access_token,
            user: storedUser._id,
            facebookdata: x,
          });
          if (data) {
            console.log(data);
    
            navigate("/connectInstapage");
       
          }
        } catch (error) {
          
          toast.error(error.message);
        }
        // toast.success(e.data.name, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        }
        
      } catch (error) {
      toast.error(error.message);
      
    }
   }
   
   
  };













export const handleInstaLogin = (navigate,storedUser ) => {
    window.FB.login(response => {
      if (response.authResponse) {
        console.log(response.authResponse,"rr")
        // User is logged in and granted permissions
        getUserInfoInsta(response.authResponse,navigate,storedUser );
      } else {
        
      }
    }, { scope:'instagram_basic pages_show_list pages_manage_posts pages_read_user_content instagram_manage_events instagram_manage_insights instagram_content_publish business_management instagram_manage_messages' });
  };

  const  getUserInfoInsta = (x,navigate,storedUser ) => {
    window.FB.api('/me', { fields: 'id,name' }, response => {
        myfunct({...x,...response},navigate,storedUser )
      
    });
  };