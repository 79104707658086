import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { MdDone, MdEmail } from "react-icons/md";

const Status = ({ recipients, deleteEmail, loading, sentLoading, error }) => {
  const [visibleMessages, setVisibleMessages] = useState({});

  const toggleMessageVisibility = (index) => {
    setVisibleMessages((prevVisibleMessages) => ({
      ...prevVisibleMessages,
      [index]: !prevVisibleMessages[index],
    }));
  };

  const sortedRecipients = recipients.slice().sort((a, b) => {
    return new Date(b.statusTime) - new Date(a.statusTime);
  });

  console.log(visibleMessages, "visible");
  const emailOpened = (x) => {
    let emailid = x.id;
    let RecipientOne = x.RecipientOne[0];
    console.log(RecipientOne, "one");
    if (x.targetedAudeince) {
      let exist = x.targetedAudeince?.team;

      let matchingElements = exist?.flatMap((count) => {
        let data = count.member.OpenedTimes.filter(
          (x) => x.emailtrack === emailid && x.email === RecipientOne
        );

        return data;
      });

      console.log(matchingElements, "dekh", x);
      return matchingElements ? <div>{matchingElements.length}</div> : "0";
    } else {
      let datanonaudi = x?.OpenedTimes?.filter((x) => x.emailtrack === emailid);

      return datanonaudi ? <div>{datanonaudi.length}</div> : "0";
    }
  };
  console.log(sortedRecipients, "all");
  return (
    <VStack spacing={4} align="stretch" mt={50}>
      {loading && (
        <Text fontSize="lg" color="white">
          Loading...
        </Text>
      )}
      {!loading &&
        !error &&
        Array.isArray(sortedRecipients) &&
        sortedRecipients.length > 0 &&
        sortedRecipients.map((recipient, index) => (
          <Box
            key={Math.random() + index}
            bg="#373DFF"
            borderRadius="md"
            p="20px"
            mb="20px"
            boxShadow="lg"
          >
            <Flex justifyContent="flex-end">
              <Button
                onClick={() => {
                  deleteEmail(recipient.id);
                }}
                transform="rotate(45deg)"
                borderRadius="full"
                bgColor="red"
                color="white"
                fontSize="18px"
                fontWeight="bold"
              >
                +
              </Button>
            </Flex>
            <Flex align="center" mb="20px">
              <VStack align="center" spacing="5px">
                <Text fontSize="lg" fontWeight="bold" color="white">
                  Status:
                </Text>
                {recipient.status === "Sent" ? (
                  <MdEmail color="gray" size={50} />
                ) : (
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "50%",
                      padding: "5px",
                    }}
                  >
                    <MdDone color="green" size={50} />
                  </div>
                )}
                <Text fontSize="md" color="white">
                  {recipient.status}
                </Text>
              </VStack>
              <Text ml="20px" fontSize="md" color="white">
                {new Date(recipient.statusTime).toLocaleString("en-US")}
              </Text>
            </Flex>
            <VStack align="stretch" spacing="20px">
              <Text ml="20px" fontSize="md" color="white">
                No. of Times Opened :{emailOpened(recipient)}
              </Text>
              <Text fontSize="lg" fontWeight="bold" color="white">
                Subject: {recipient.subject}
              </Text>
              <Button
                fontSize="lg"
                color="green"
                onClick={() => toggleMessageVisibility(index)}
                textDecoration="underline"
                variant="outline"
                borderColor="green.500"
                backgroundColor="white"
                _hover={{ textDecoration: "none" }}
              >
                Message
              </Button>
              {visibleMessages[index] && (
                <div
                  dangerouslySetInnerHTML={{ __html: recipient.message }}
                  style={{ fontSize: "md", color: "white" }}
                />
              )}
            </VStack>
            <HStack justify="space-between">
              <Text fontSize="md" color="white">
                <strong>Sent:</strong>{" "}
                {new Date(recipient.sentTime).toLocaleString("en-US")}
              </Text>
              <Text fontSize="md" className="recipient" color="white">
                <strong>Recipient:</strong> {recipient?.RecipientOne}
              </Text>
            </HStack>
          </Box>
        ))}
      {sentLoading && (
        <Text className="sent-loading" color="white">
          Loading...
        </Text>
      )}
    </VStack>
  );
};

export default Status;
