import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
} from "@chakra-ui/react";

const columns = [
  { id: "name", label: "Name" },
  { id: "age", label: "Age" },
  { id: "city", label: "City" },
  { id: "city", label: "City" },
  { id: "city", label: "City" },
  { id: "city", label: "City" },
  { id: "city", label: "City" },
  { id: "city", label: "City" },
];

const rows = [
  { id: 1, name: "John Doe", age: 25, city: "New York" },
  { id: 2, name: "Jane Smith", age: 30, city: "San Francisco" },
  { id: 2, name: "Jane Smith", age: 30, city: "San Francisco" },
  { id: 2, name: "Jane Smith", age: 30, city: "San Francisco" },
  { id: 2, name: "Jane Smith", age: 30, city: "San Francisco" },
  { id: 2, name: "Jane Smith", age: 30, city: "San Francisco" },
  { id: 2, name: "Jane Smith", age: 30, city: "San Francisco" },
  { id: 2, name: "Jane Smith", age: 30, city: "San Francisco" },
  { id: 2, name: "Jane Smith", age: 30, city: "San Francisco" },
  { id: 2, name: "Jane Smith", age: 30, city: "San Francisco" },
  { id: 2, name: "Jane Smith", age: 30, city: "San Francisco" },
  { id: 2, name: "Jane Smith", age: 30, city: "San Francisco" },
  { id: 2, name: "Jane Smith", age: 30, city: "San Francisco" },
  { id: 2, name: "Jane Smith", age: 30, city: "San Francisco" },
  // Add more rows as needed
];

const ActiveCampaignTable = () => {
  return (
    <Table
      size="sm"
      variant="simple"
      borderWidth="2px"
      borderColor="gray.300"
      borderRadius="md"
    >
      <TableCaption placement="top" fontWeight="bold" fontSize="md">
        Active Campaigns
      </TableCaption>
      <Thead>
        <Tr>
          {columns.map((column) => (
            <Th
              key={column.id}
              bg="#6AD2FF"
              color="white"
              fontWeight="bold"
              fontSize="sm"
              p={2}
            >
              {column.label}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {rows.map((row) => (
          <Tr key={row.id}>
            {columns.map((column) => (
              <Td key={column.id} p={2} fontSize="sm">
                {row[column.id]}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default ActiveCampaignTable;
