import { create } from "zustand";

const useOrderStore = create((set) => {
  return {
    storedUser: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
    loginLoading: false,

    loginLoadingFunction: (status) => {
      set({
        loginLoading: status
      });
    },


    login: (data) => {
      const newUser =data.userdata;
      localStorage.setItem("user", JSON.stringify(newUser));
      set((state)=>{
        console.log("dekh")
        return{
            storedUser: newUser,
            loginLoading: false
        }
      })
    },
   
   
logout: () => {
     localStorage.removeItem("user")
      set((state)=>{
        console.log("dekh")
        return{
            storedUser: {},
          
        }
      })
  },


  };
});

export default useOrderStore;


//   set(() => ({
//   }));