import { Badge, Box, HStack, Spinner, Stack, Text } from "@chakra-ui/react";
import { PieChartHook } from "contexts/Charthook";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { CgSpinner } from "react-icons/cg";

const PieChart = (props) => {
  const [TotalSent, setTotalSent] = useState(0);
  const [TotalOpened, setTotalOpened] = useState(0);
  const [TotalNotOpened, setTotalNotOpened] = useState(0);
 const {piedata}=PieChartHook()
 
 useEffect(() => {

  if (Array.isArray(Object.keys(piedata))) {
    
   
   
  if (Number(piedata?.TotalSent?.length)===0) {
    console.log("if")
    setTotalSent(1)
  }else{
    setTotalSent(Number(piedata.TotalSent?.[0]?.totalAmount));
    
  }

  if (Number(piedata?.TotalOpened?.length)===0) {
    console.log("if")
    setTotalOpened(1)
    
  }else{
    setTotalOpened(Number(piedata.TotalOpened?.[0]?.totalAmount));

  }
    
    
  }
}, [piedata]);


 
 const lables=()=>{
  
  if (piedata.TotalSent) {
    if (Number(piedata?.TotalSent?.length)===0&&Number(piedata?.TotalOpened?.length)===0) {
      return ["No data", "No data"]
      
    }else{
      return ["Email Sent", "Email Opened"]
    }
    
  }
 }

  return (
   
    <Box>

  

   
    <Text fontSize="md" fontWeight="600" >
            Your Pie Chart
          </Text>
   {piedata.TotalSent?( <ReactApexChart
    
    options={{
      labels: lables(),
      colors: ["#4318FF", "#6AD2FF", "green"],
      chart: {
        width: "50px",
      },
     
   legend: {
        show: false,
      },
      dataLabels: {
        
         enabled: false,
   
      },
   
      fill: {
        colors: ["#4318FF", "#6AD2FF", "#1508FF"],
      },
      stroke: {
        colors: ['blue'], // Border color
        width: 1, // Border width
      },
      
      
    }
  }
  series={[TotalSent,TotalOpened]}
    type='pie'
    width='100%'
    height='55%'
  />):(<CgSpinner/>)}
<Stack direction={['column', 'column']} spacing='24px' float="right">
  <Box w='150px' h='20px'>
    <Badge>Email summary: </Badge>
  </Box>
  <Box w='200px' h='20px'  >
  <Badge> Emails Opened: </Badge>{piedata.TotalOpened?.[0]?.totalAmount||0}
 
  </Box>
  <Box w='200px' h='20px'>
  <Badge>Emails Not Opened: </Badge>{piedata.TotalNotOpened?.[0]?.totalAmount||0}
  </Box>
  <Box w='200px' h='20px'>
  <Badge>Emails Sent: </Badge>{piedata.TotalSent?.[0]?.totalAmount||0}
  </Box>
</Stack>
   
      </Box>
  
  );
};

export default PieChart;
