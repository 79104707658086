import React, { useRef, useState } from "react";
import {
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import { toast } from "react-toastify";
import { FaFileUpload } from "react-icons/fa";
import { read, utils } from "xlsx";
import useOrderStore from "contexts/Userhook";

const serverURL = process.env.REACT_APP_BACKEND_URL;
const AddAudimembermodel = ({
  audiid,
  isOpen,
  setAddmemeberaudiModel,
  setAlertModel,
  SetAlertMessage,
}) => {
  // setAlertModel={setAlertModel}
  //         SetAlertMessage={SetAlertMessage}
  const [memberloading, setmemberlaoding] = useState(false);
  const [filename, setfilename] = useState("");
  const [isFileexist, setisFileexist] = useState(false);
  const [manyMember, setmanyMember] = useState([]);
  console.log(audiid, "ss");
  const { storedUser } = useOrderStore();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    contactType: "",
    address: "",
    Customeone: "",
    Custometwo: "",
    isMany: false,
    team: null,
  });

  //  to create memebers
  const handleSubmit = async () => {
    try {
      setmemberlaoding(true);
      if (isFileexist) {
        const duplicatesCount = {};
        const uniqueMembers = [];
        const duplicateEmails = [];
        let Totalduplicate = 0;

        const duplicateChcek = () => {
          for (const i of manyMember) {
            console.log("aaaaaaaaaasasassasas>>>>====", i, manyMember);
            const exsit = i.emailAddress.toLowerCase();
            if (duplicatesCount[exsit]) {
              Totalduplicate++;
              duplicateEmails.push(exsit);
            } else {
              uniqueMembers.push(i);
              duplicatesCount[exsit] = i;
            }
          }
          console.log("u", duplicatesCount, uniqueMembers);
        };
        duplicateChcek();
        console.log(manyMember, "kk");

        // api
        const { data } = await axios.post(`${serverURL}/api/createmembers`, {
          manyMember: uniqueMembers,
          isMany: true,
          team: audiid,
          user: storedUser._id,
          duplicate: duplicateEmails,
        });
        if (data) {
          setAddmemeberaudiModel(false);
          setmemberlaoding(false);
          setTimeout(() => {
            setAlertModel(true);
          }, 1000);
          // if (Totalduplicate>0) {
          //   toast.success(`${Totalduplicate} email were duplicated`)

          // }

          let messone = `${data.totalinserted} out of ${data.totallength} Inserted`;
          let difference =
            Number(data.totallength) - Number(data.totalinserted);
          SetAlertMessage({
            Inserted: messone,
            uploaded: difference,
            Totalduplicate: Totalduplicate,
            Audience: data.audiences,
          });
        }
      } else {
        const { data } = await axios.post(`${serverURL}/api/createmembers`, {
          ...formData,
          team: audiid,
          user: storedUser._id,
        });
        if (data) {
          toast.success(data.message);
          setAddmemeberaudiModel(false);
          setmemberlaoding(false);
        }
      }
    } catch (error) {
      let err =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      toast.error(err);
      setmemberlaoding(false);
    }
  };

  // to import file
  const fileinput = useRef(null);
  const Myuploadfile = async (e) => {
    const fileInput = fileinput.current;
    const file = e.target.files[0];
    // console.log(file)
    if (file) {
      setisFileexist(true);
      formData.isMany = true;
      console.log(file);
      setfilename(e.target.files[0]["name"]);
      const reader = await new FileReader();
      reader.onload = (e) => {
        console.log(e.target.result);
        var data = e.target.result;
        var book = read(data, { type: "array" });
        const sheet = book.Sheets[book.SheetNames[0]];
        const ArrayData = utils.sheet_to_json(sheet);
        console.log(ArrayData);
        setmanyMember(ArrayData);
        setmanyMember(ArrayData);

        // console.log(fileInput)
        fileInput.value = "";
      };

      reader.readAsArrayBuffer(file);
    }
  };

  return (
    // <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
    <Modal isOpen={isOpen} onClose={() => setAddmemeberaudiModel(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Audience</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          sx={{
            overflowY: "scroll",
            maxHeight: "450px",
            "&::-webkit-scrollbar": {
              width: "12px",
              height: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              bg: "#422afb",
              borderRadius: "full",
            },
            "&::-webkit-scrollbar-track": {
              bg: "gray.300",
            },
          }}
        >
          <FormControl mt={4}>
            <FormLabel
              htmlFor="myfile"
              style={{ fontSize: "1em", color: "blue" }}
            >
              <FaFileUpload />
              {filename !== "" && filename !== undefined ? (
                <h3 class="text-3xl mb-2">{filename} </h3>
              ) : (
                <h3>Upload the file</h3>
              )}
            </FormLabel>
            {filename !== "" && filename !== undefined && (
              <h2
                onClick={() => {
                  setisFileexist(false);
                  setfilename("");
                  formData.isMany = false;
                  setmanyMember([]);
                }}
              >
                <CloseButton />
              </h2>
            )}
            <Input
              id="myfile"
              style={{ display: "none" }}
              type="file"
              ref={fileinput}
              onChange={Myuploadfile}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>First Name</FormLabel>
            <Input
              placeholder="First Name"
              onChange={(e) =>
                setFormData({ ...formData, firstName: e.target.value })
              }
              disabled={isFileexist}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Last Name</FormLabel>
            <Input
              placeholder="Last Name"
              onChange={(e) =>
                setFormData({ ...formData, lastName: e.target.value })
              }
              disabled={isFileexist}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Email Address</FormLabel>
            <Input
              placeholder="Email Address"
              onChange={(e) =>
                setFormData({ ...formData, emailAddress: e.target.value })
              }
              disabled={isFileexist}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Phone Number</FormLabel>
            <Input
              placeholder="Phone Number"
              onChange={(e) =>
                setFormData({ ...formData, phoneNumber: e.target.value })
              }
              disabled={isFileexist}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Address</FormLabel>
            <Input
              placeholder="Address"
              onChange={(e) =>
                setFormData({ ...formData, address: e.target.value })
              }
              disabled={isFileexist}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Contact Type</FormLabel>
            <Input
              placeholder="Contact Type"
              onChange={(e) =>
                setFormData({ ...formData, contactType: e.target.value })
              }
              disabled={isFileexist}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Custome One</FormLabel>
            <Input
              placeholder="Contact Type"
              onChange={(e) =>
                setFormData({ ...formData, Customeone: e.target.value })
              }
              disabled={isFileexist}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Custome two</FormLabel>
            <Input
              placeholder="Contact Type"
              onChange={(e) =>
                setFormData({ ...formData, Custometwo: e.target.value })
              }
              disabled={isFileexist}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Save {memberloading && <Spinner color="red.500" />}
          </Button>
          <Button onClick={() => setAddmemeberaudiModel(false)}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddAudimembermodel;
