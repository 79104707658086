
export const handleReset = (setEmailData) => {
    setEmailData({
      from: "",
      to: "",
      cc: "",
      bcc: "",
      subject: "",
      message: "",
      atOnce: false,
    });
  };
