import axios from "axios";
import { toast } from "react-toastify";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { AppFire } from "components/InstagramComp/Firebase/Firebase";

export const handlePostSubmissionFb = async (
  setPostLoading,
  postData,
  post,
  setPostData,
  server,
  setDeletedSucess,
  posts,
  scheduleTime,
  addCalendarEvent
) => {
  console.log("click");
  try {
    setPostLoading(true);
    const formData = new FormData();
    formData.append("message", post.caption);

    if (scheduleTime) {
      console.log("AAAAAAAAAAAAAAAAAAAAostdata", scheduleTime);
      formData.append(
        "scheduled_publish_time",
        Math.floor(scheduleTime.getTime() / 1000)
      );
      formData.append("published", "false");
      addCalendarEvent({
        title: `Posting Scheduled to Facebook`,
        start: scheduleTime,
        end: scheduleTime,
      });
    }
    if (post.imageUrl) {
      formData.append("url", post.imageUrl);
      console.log("pic");
      const { data } = await axios.post(
        `https://graph.facebook.com/v18.0/me/photos?access_token=${post.token}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      console.log("ioi", data);
      if (data) {
        console.log("ioi", data);
        setPostLoading(false);
        toast.success("Posted Successfully");
        const exist = posts.filter((x) => x.imagename === post.imagename) || [];
        if (exist.length > 1) {
          setTimeout(async () => {
            await DeletePostFromMongodb(
              post,
              server,
              setPostLoading,
              setDeletedSucess
            );
          }, 1000);
        } else {
          setTimeout(async () => {
            const Storagedb = getStorage(AppFire);
            const storageRef = ref(Storagedb, post.imagename);
            await deleteObject(storageRef);
            await DeletePostFromMongodb(
              post,
              server,
              setPostLoading,
              setDeletedSucess
            );
          }, 1000);
        }
        // await DeletePostFromMongodb(post,server,setPostLoading,setDeletedSucess)

        // if (postdata.scheduleTime) {
        //   addCalendarEvent({
        //     title: `Posting image to Facebook`,
        //     start: postdata.scheduleTime,
        //     end: postdata.scheduleTime,
        //   });
        // }
      }

      return;
    } else if (post.caption.length > 0 && post.contentType !== "video") {
      console.log("not pic", formData);
      const { data } = await axios.post(
        `https://graph.facebook.com/v18.0/me/feed?access_token=${post.token}`,
        formData
      );
      if (data) {
        console.log(data, "else");

        // if (postdata.scheduleTime) {
        //   addCalendarEvent({
        //     title: `Posting video to Facebook`,
        //     start: postdata.scheduleTime,
        //     end: postdata.scheduleTime,
        //   });
        // }
        setPostLoading(false);

        toast.success("Posted Successfully");
        await DeletePostFromMongodb(
          post,
          server,
          setPostLoading,
          setDeletedSucess
        );
      }
      return;
    }

    if (post.contentType === "video") {
      formData.append("message", post.caption);
      formData.append("file_url", post.imageUrl);
      formData.append("title", post.caption);
      formData.append("description", post.caption);
      console.log("not pic", Array.from(formData));

      const { data } = await axios.post(
        `https://graph.facebook.com/v18.0/me/videos/?access_token=${post.token}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (data) {
        console.log(data, "else");

        // if (postdata.scheduleTime) {
        //   addCalendarEvent({
        //     title: `Posting video to Facebook`,
        //     start: postdata.scheduleTime,
        //     end: postdata.scheduleTime,
        //   });
        // }
        setPostLoading(false);

        toast.success("Posted Successfully");
        const exist = posts.filter((x) => x.imagename === post.imagename) || [];
        if (exist.length > 1) {
          setTimeout(async () => {
            await DeletePostFromMongodb(
              post,
              server,
              setPostLoading,
              setDeletedSucess
            );
          }, 1000);
        } else {
          setTimeout(async () => {
            const Storagedb = getStorage(AppFire);
            const storageRef = ref(Storagedb, post.imagename);
            await deleteObject(storageRef);
            await DeletePostFromMongodb(
              post,
              server,
              setPostLoading,
              setDeletedSucess
            );
          }, 1000);
        }
      }

      return;
    }
  } catch (error) {}
};

const DeletePostFromMongodb = async (
  p,
  server,
  setPostLoading,
  setDeletedSucess
) => {
  console.log("kjo");
  try {
    setPostLoading(true);
    const { data } = await axios.delete(
      `${server}/api/scheduledposts/delete/${p._id}`
    );
    if (data) {
      toast.success("Post has been removed from Approved");
      setDeletedSucess(true);
      setPostLoading(false);
    }
  } catch (error) {
    toast.error(error.message);
  }
};
