import axios from "axios";
import React, { useState, useRef } from "react";
import { FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";
import { useCalendar } from "../../context/CalendarContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useOrderStore from "../../../contexts/Userhook";
import { AppFire } from "../../InstagramComp/Firebase/Firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
const server = process.env.REACT_APP_BACKEND_URL;
export const CreatePost = ({ token, currentPageClicked }) => {
  const [postdata, setpostdata] = useState({
    image: null,
    text: "",
    video: null,
    scheduleTime: null,
    contentType: "image",
  });
  const fileInputRef = useRef(null);
  const [Postloading, setpostloading] = useState(false);
  const resetState = () => {
    setpostdata({
      image: null,
      text: "",
      video: null,
      scheduleTime: null,
      contentType: "image",
    });

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const { storedUser } = useOrderStore();
  // console.log(storedUser, "user");
  // const isManager = storedUser && storedUser.role === "manager";
  const isManager = storedUser && (storedUser.role === "manager"&&!storedUser.socailpermission);

  const handleDateChange = (date) => {
    const selectedDate = date || new Date();
    setpostdata((prev) => ({ ...prev, scheduleTime: selectedDate }));
  };
  const { addCalendarEvent } = useCalendar();
  const myform = (e) => {
    let verifyvideo;
    let datatype = e.target.name;
    if (datatype !== "text") {
      verifyvideo = ["video/mp4", "audio/mp3"].includes(
        e.target?.files[0].type
      );
    }

    if (datatype === "file" && !verifyvideo) {
      setpostdata((prev) => ({ ...prev, image: e.target.files[0] }));
    } else if (datatype === "text") {
      console.log(e.target.value, "data");

      setpostdata((prev) => ({ ...prev, text: e.target.value }));
    } else if (verifyvideo) {
      setpostdata((prev) => ({
        ...prev,
        video: e.target.files[0],
        contentType: "video",
      }));
    }
  };

  const uploadFileToFirebase = async (file, folder) => {
    const storage = getStorage(AppFire);
    const storageRef = ref(storage, `${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  };

  const saveToMongoDB = async (
    imageDownloadURL,
    videoDownloadURL,
    imageName,
    videoName,
    contentName,
    scheduledTime
  ) => {
    try {
      const response = await axios.post(`${server}/api/scheduledpost`,{
        imageUrl: imageDownloadURL || videoDownloadURL,
        caption: postdata.text,
        socialPlatform: "Facebook",
        token: currentPageClicked.access_token,
        imagename: imageName || videoName,
        contentType: contentName,
        scheduledTime: scheduledTime,
        userid:storedUser._id,
        Admin:storedUser.roleby
      });
      console.log("Data saved to MongoDB:", response.data);
    } catch (error) {
      console.error("Error saving data to MongoDB:", error);
    }
  };
  const Posting = async () => {
    try {
      if (isManager) {
        setpostloading(true);

        const imageDownloadURL = postdata.image
          ? await uploadFileToFirebase(postdata.image, "images")
          : null;
        const videoDownloadURL = postdata.video
          ? await uploadFileToFirebase(postdata.video, "videos")
          : null;
        let imageName = postdata?.image?.name;

        const videoName = postdata?.video?.name;

        const contentName = postdata.contentType;
        console.log("AAAAAAAAAAAAAAAAA", postdata.scheduleTime);
        const scheduledTime = postdata.scheduleTime;
        saveToMongoDB(
          imageDownloadURL,
          videoDownloadURL,
          imageName,
          videoName,
          contentName,
          scheduledTime
        );
        setpostloading(false);
        resetState();
        toast.success("Send To Admin Succesfully");
      } else {
        setpostloading(true);
        const formData = new FormData();
        formData.append("message", postdata.text);

        if (postdata.scheduleTime) {
          console.log("AAAAAAAAAAAAAAAAAAAAostdata", postdata.scheduleTime);
          formData.append(
            "scheduled_publish_time",
            Math.floor(postdata.scheduleTime.getTime() / 1000)
          );
          formData.append("published", "false");
        }
        if (postdata.image) {
          formData.append("source", postdata.image);
          console.log("pic");
          const { data } = await axios.post(
            `https://graph.facebook.com/v18.0/me/photos?access_token=${token}`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
          console.log(data, "if");

          if (data) {
            setpostloading(false);
            setpostdata({
              text: "",
            });
            document.getElementById("file").value = "";
            if (postdata.scheduleTime) {
              addCalendarEvent({
                title: `Posting image to Facebook`,
                start: postdata.scheduleTime,
                end: postdata.scheduleTime,
              });
            }
            toast.success("Posted Successfully");
            window.location.reload()
          }
          return;
        } else if (postdata.text.length > 0 && !postdata.video) {
          console.log("not pic", formData);
          const { data } = await axios.post(
            `https://graph.facebook.com/v18.0/me/feed?access_token=${token}`,
            formData
          );
          if (data) {
            console.log(data, "else");

            setpostloading(false);
            setpostdata({
              text: "",
            });
            document.getElementById("file").value = "";
            if (postdata.scheduleTime) {
              addCalendarEvent({
                title: `Posting video to Facebook`,
                start: postdata.scheduleTime,
                end: postdata.scheduleTime,
              });
            }
            toast.success("Posted Successfully");
            window.location.reload()
          }
          return;
        }

        if (postdata.video) {
          formData.append("message", postdata.text);
          formData.append("source", postdata.video);
          formData.append("title", postdata.text);
          formData.append("description", postdata.text);
          console.log("not pic", Array.from(formData));

          const { data } = await axios.post(
            `https://graph.facebook.com/v18.0/me/videos/?access_token=${token}`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
          if (data) {
            console.log(data, "else");

            setpostloading(false);
            setpostdata({
              text: "",
            });
            document.getElementById("file").value = "";
            if (postdata.scheduleTime) {
              addCalendarEvent({
                title: `Posting video to Facebook`,
                start: postdata.scheduleTime,
                end: postdata.scheduleTime,
              });
            }
            toast.success("Posted Successfully");
            window.location.reload()
          }

          return;
        }
      }
    } catch (error) {
      setpostloading(false);
      toast.error(error.message);
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white p-8 rounded-md shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Post To Facebook</h2>
      <div className="mb-4">
        <label
          htmlFor="file"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          Upload File
        </label>
        <input
          type="file"
          id="file"
          name="file"
          ref={fileInputRef}
          onChange={myform}
          className="p-2 border border-gray-300 rounded-md w-full"
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor="message"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          Your Message
        </label>
        <textarea
          id="message"
          name="text"
          rows="4"
          className="resize-none p-2 border border-gray-300 rounded-md w-full"
          placeholder="Type your message here..."
          onChange={myform}
          value={postdata.text}
        ></textarea>
      </div>

      <div className="mb-4">
        <label
          htmlFor="scheduleTime"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          Schedule Time (Min Time: 10 mins and Max Time: 30 days)
        </label>
        <DatePicker
          id="scheduleTime"
          selected={postdata.scheduleTime}
          onChange={handleDateChange}
          showTimeSelect
          timeFormat="h:mm aa"
          timeIntervals={15}
          dateFormat="MMMM d, yyyy h:mm aa"
          className="p-2 border border-gray-300 rounded-md"
          placeholderText="Select date and time"
          minDate={new Date()}
        />
      </div>

      <button
        type="submit"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
        onClick={Posting}
      >
        {isManager ? (
          <>
            Send for Approval
            {"  "}
            {Postloading && <FaSpinner />}
          </>
        ) : (
          <>
            Post
            {"  "}
            {Postloading && <FaSpinner />}
          </>
        )}
      </button>
    </div>
  );
};
