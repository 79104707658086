/* eslint-disable */
import {
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  TableCaption,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Tfoot,
  Button,
  Box,
  Icon,
  Spinner,
  ModalFooter,
  Badge,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { AndroidLogo, AppleLogo, WindowsLogo } from "components/icons/Icons";
import Menu from "components/menu/MainMenu";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FaCaretDown, FaCaretUp} from "react-icons/fa";
import { Tooltip } from 'react-tooltip'
import {
  // useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Search from "antd/es/input/Search";
import axios from "axios";
import { toast } from "react-toastify";
import { AiOutlineEye } from 'react-icons/ai';
import { FcTemplate } from "react-icons/fc";
import useOrderStore from "contexts/Userhook";

const serverURL = process.env.REACT_APP_BACKEND_URL;
export default function ExecutedEmail() {
  const [tableData, settableData] = useState([]);
  const { storedUser } = useOrderStore();
  //  console.log(tableData)
  useEffect(() => {
    const Allsentemaildata = async () => {
      const { data } = await axios.get(
        `${serverURL}/Schedule/all/exe/${storedUser._id}`
      );
      console.log(data, "Schedule");
      if (data.length > 0) {
        let uniqueIds = new Set();
        let filterbasedid=data.filter((x)=>{
          if (!uniqueIds.has(x.id)) {
            uniqueIds.add(x.id)
            return true
            
          }
          return false
        })
        let all = filterbasedid.map((x) => ({
          Template: x?.message,
          Time: new Date(x?.sentTime).toLocaleString("en-us", {
            weekday: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          }),
          Recipients: x?.Recipients,
          data: x,
          ReadRate: x.Timesofopen,
        }));
         console.log(all,"all")
        
        settableData(all); // for the keys
      }
    };
    Allsentemaildata();
  }, [settableData, storedUser._id]);
  const [headers,setheaders]=useState([   
    {
      Header: "Template",
      accessor: "Template",
    },
    {
      Header: "Time",
      accessor: "Time",
    },
    {
      Header: "#Recipients",
      accessor: "Recipients",
    },

    {
      Header: "ReadRate",
      accessor: "ReadRate",
    },

])
  const columns = useMemo(() => headers, [headers]);
  // const data = useMemo(() => [tableData], [tableData]);
const [toolipdata,settoolipdata]=useState("")

  const tableInstance = useTable(
    {
      columns,
      data:tableData,
    },
    // useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 1000;
  // console.log(data,columns)

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("secondaryGray.500", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  // console.log(getTableProps(),"p")
 const [RecipientsModel,setRecipientsModel]=useState(false)
 const [RecipientsData,setRecipientsData]=useState([])
 const [RecipientsDataLoading,setRecipientsDataLoading]=useState(false)
 const [RecipientsDataId,setRecipientsDataId]=useState({})
   
 

 const Content = async(x) => {
  setRecipientsModel(true)
  // console.log(x,"data")
  let mydata=x?.row?.original?.data
  if (mydata) {
    try {
      setRecipientsDataLoading(true)
      const  {data}=await axios.get(`${serverURL}/recipient/RecipientsModel/${mydata._id}`)
      if (data) {
        console.log(data)
        setRecipientsData(data?.targetedAudeince?.team)
        setRecipientsDataId(data)
        setRecipientsDataLoading(false)
        
      }
      
    } catch (error) {
      // console.log(error)
      
      let err=error.response&&error.response.data?error.response.data:error.message
      toast.error(err)
      setRecipientsDataLoading(false)
      
    }
    
  }
  
 }
 const emailtrack=(x)=>{
  // console.log(x,"sh",RecipientsDataId)
  
  let exist=x?.filter((x)=>x.emailtrack===RecipientsDataId?.id).reverse()[0]
  if (exist) {
    return new Date(exist.time).toLocaleString("en-us", {day: 'numeric', hour: 'numeric',minute:"numeric"})
    
  }else{
    return "Pending"
  }



 }
 const emailOpened=(x)=>{
  
  let exist=x?.filter((x)=>x.emailtrack===RecipientsDataId?.id)
 
  if (exist) {
    return exist.length
    
  }else{
    return "Pending"
  }



 }
 const templateRef = useRef(null);

 const handleTemplatePreviewClick = () => {
   // Scroll to the template element
   if (templateRef.current) {
     templateRef.current.scrollIntoView({ behavior: 'smooth' });
   }
 };
 const Handleclose = (x) => {
  setRecipientsModel(false)



 }


 const ReadRate = (d) => {
  let exist = d.data.targetedAudeince?.team;
  console.log(exist);

  let matchingElements = exist?.flatMap((count) => {
    let data = count.member.OpenedTimes?.filter((x) => x.emailtrack === d.data.id);
    return data;
  });

  // You can return matchingElements if needed
  return matchingElements?(<div>{matchingElements.length}</div>):"0"
};

 const Mytoolip=(x)=>{
if(x){


   let exist=x.filter((x)=>x.emailtrack===RecipientsDataId?.id)
   if (exist.length>0) {
    console.log(exist)
     settoolipdata(  <ul>
        {exist?.length > 0?
            exist.map((x) => (
                  <li>
                    time:<li></li>&nbsp;{new Date(x?.time).toLocaleString("en-us",{weekday:"short",day:"numeric",hour:"numeric",second:"numeric"})}&nbsp;
                    
                  </li>
                ))
              : "No Recipients"}
          </ul>
     )
    
   }
  }

 }
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll"}}
      h="500px"
      sx={{
        "&::-webkit-scrollbar": {
          width: "12px",
          height:"10px"
        },
        "&::-webkit-scrollbar-thumb": {
          bg: "#422afb",
          borderRadius: "full",
        },
        "&::-webkit-scrollbar-track": {
          bg: "gray.300",
        },
      }}
    >
    
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Executed Email
        </Text>
        {/* <Menu /> */}
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                  
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                    {column.isSorted ? (column.isSortedDesc ? (<FaCaretDown />) : (<FaCaretUp />)) : (<FaCaretDown />)}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
  {page.map(row => {
   
    prepareRow(row);
    return (
      <Tr {...row.getRowProps()} key={row.id}>
      
        {row.cells.map(cell => (
       
          <Td {...cell.getCellProps()} key={cell.column.id}
       
          >
          

            {cell.column.id === 'Template' ? (
              
              <>
                <div className="htmltag"
              
                  dangerouslySetInnerHTML={{ __html: cell.value }}
                  
          />
            </>
            ):cell.column.id==="Recipients"?(
            <Td  style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100% !important"}} >
              <div data-tooltip-id="my-tooltip-1" onClick={()=>{
                Content(cell)
                
              }}>{cell.value.length}</div>
              
             


            </Td>
            ):cell.column.id==="ReadRate"?(<Td>
              {/* {ReadRate(cell?.row?.original?.data?.targetedAudeince?.team)} */}
              {ReadRate(cell?.row?.original)}
            </Td>) : (
           
              cell.render('Cell')
            )}
          </Td>
        ))}
      </Tr>
    );
  })}
</Tbody>

      </Table>

      <Modal isOpen={RecipientsModel} onClose={Handleclose} >
        <ModalOverlay/>
       
      
      <ModalContent maxW="900px" w="700px" h="500px" maxH="400px" overflow="scroll">
      <Tooltip
                  
                  
                  id="my-tooltip-1"
                  place="top"
                  content={toolipdata}
                 
                    />
      <br/>
      <ModalCloseButton />
      <br/>
      <ModalHeader>
        <Box  display="flex" justifyContent="space-between" width="100%">
          <Box>
     Recipients List    

          </Box>
          <Box display="flex" alignItems="center">
   <Search placeholder="Search Recipients"  style={{marginRight:"30px"}}/>
   <Button bg="blue" color="white">Cancel</Button>

          </Box>

        </Box>
    

    </ModalHeader>
     <ModalBody >
     <TableContainer>
  <Table variant='simple'>
    
    <Thead>
      <Tr>
        <Th>Name </Th>
        <Th>Date & Time </Th>
        <Th>Opened Counts</Th>
        <Th>Templete Preview</Th>
       
      </Tr>
    </Thead>
    <Tbody>
      {/* {RecipientsData.} */}
      {RecipientsDataLoading&&(<Spinner color="red"/>)}
      {RecipientsData?.map((x)=>(
        
     <Tr>
        <Td>{x?.member?.firstName}</Td>
        <Td>{emailtrack(x?.member?.OpenedTimes)}</Td>
        
        <Td data-tooltip-id="my-tooltip-1"  onMouseEnter={()=>Mytoolip(x?.member?.OpenedTimes)}>{emailOpened(x?.member?.OpenedTimes)}</Td>
        <Td onClick={handleTemplatePreviewClick} display="flex" alignItems="center"><FcTemplate fontSize="25px"/>&nbsp;&nbsp;{" "}<Icon as={AiOutlineEye}></Icon></Td>
        
        
        
        
        </Tr>

      ))}
     

      
      
    </Tbody>
   
  </Table>
</TableContainer>

    </ModalBody>
        <ModalFooter w="100%" display="flex" flexDirection="column">
          <Box>
          <Badge>Template</Badge>

          </Box>
          <Box ref={templateRef} className="htmltag" dangerouslySetInnerHTML={{__html:RecipientsDataId.message}}></Box>

        </ModalFooter>
      </ModalContent>
       
       
      </Modal>
      
    </Card>
  );
}
