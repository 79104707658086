import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import useOrderStore from "contexts/Userhook";
const CalendarContext = createContext();

export const CalendarProvider = ({ children }) => {
  const [events, setEvents] = useState([]);
  const { storedUser } = useOrderStore();
  const server = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchEventsFromBackend = async () => {
      try {
        console.log("AHAHAGHAHHAHAH");
        const response = await axios.get(
          `${server}/calendar/events/${storedUser._id}`
        );
        const events = response.data;
        console.log("Events fetched successfully:", events);

        const futureEvents = generateFutureEvents();
        setEvents([...events, ...futureEvents]);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    const generateFutureEvents = () => {
      const today = new Date();
      const futureDates = new Date(today);
      const futureEvents = [];

      while (futureDates.getFullYear() <= today.getFullYear() + 1) {
        futureEvents.push({
          title: "Post",
          start: new Date(futureDates),
          end: new Date(futureDates),
          allDay: true,
        });

        futureDates.setDate(futureDates.getDate() + 1);
      }

      return futureEvents;
    };
    fetchEventsFromBackend();
  }, [server, storedUser._id]);

  const addCalendarEvent = async (event) => {
    try {
      const response = await axios.post(`${server}/calendar/events`, {
        title: event.title,
        start: event.start,
        end: event.end,
        userId: storedUser._id,
      });
      console.log("Event added successfully:", response.data);
      setEvents((prevEvents) => [...prevEvents, response.data]);
    } catch (error) {
      console.error("Error adding event:", error);
    }
  };

  return (
    <CalendarContext.Provider value={{ events, addCalendarEvent }}>
      {children}
    </CalendarContext.Provider>
  );
};

export const useCalendar = () => {
  return useContext(CalendarContext);
};
