import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyARcNZZzaAl9ZGOX5DD5bNMRafNN94anHM",
  authDomain: "socail-a535a.firebaseapp.com",
  projectId: "socail-a535a",
  storageBucket: "socail-a535a.appspot.com",
  messagingSenderId: "64882637445",
  appId: "1:64882637445:web:b24752be97734118a307ec",
  measurementId: "G-8PE1D83ESF",
};
export const AppFire = initializeApp(firebaseConfig);
const analytics = getAnalytics(AppFire);
