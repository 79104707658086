import { Box, Icon, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
// Assets

import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { MdEmail, MdCheck, MdSend, MdLens, MdCancel } from "react-icons/md";

import TotalSpent from "views/admin/default/components/TotalSpent";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useOrderStore from "contexts/Userhook";
import ActiveCampaignTable from "./components/ActiveCampaignTable";
import { PieChartHook } from "contexts/Charthook";
import { toast } from "react-toastify";
import PieChart from "components/charts/PieChart";
import { DefinedRange } from "react-date-range";
const emailUrl = process.env.REACT_APP_BACKEND_URL;
export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const navigate = useNavigate();
  const [targetAudienceCount, setTargetAudienceCount] = useState(0);
  const [recipientTotal, setRecipientTotal] = useState({
    TotalSent: [{ totalAmount: null }],
    TotalOpened: [{ totalAmount: null }],
    TotalNotOpened: [{ totalAmount: null }],
  });
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const { logout, storedUser } = useOrderStore();
  const [mydatefilter, setmydatefilter] = useState([]);
  const { piedatafun } = PieChartHook();
  useEffect(() => {
    const jwtCookie = Cookies.get("jwt");

    console.log("qq", jwtCookie);
    if (jwtCookie === undefined || jwtCookie === null) {
      console.log(jwtCookie);
      // logout();
      // toast.error("Your Session has been expired,Please Login Again");
      // setTimeout(() => {
      //   navigate("/");
      // }, 1000);
    }
  }, []);
  useEffect(() => {
    axios
      .get(`${emailUrl}/api/audiences/count/${storedUser._id}`, {
        withCredentials: true,
      })
      .then((response) => {
        setTargetAudienceCount(response.data.count);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, [storedUser._id]);
  useEffect(() => {
    axios
      .get(
        `${emailUrl}/recipient/total/${storedUser._id}?date=${JSON.stringify(
          mydatefilter
        )}`,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response.data);
        setRecipientTotal({
          TotalSent: response.data.results.TotalSent || [{ totalAmount: 0 }],
          TotalOpened: response.data.results.TotalOpened || [
            { totalAmount: 0 },
          ],
          TotalNotOpened: response.data.results.TotalNotOpened || [
            { totalAmount: 0 },
          ],
        });
        piedatafun({
          TotalSent: response.data.results.TotalSent || [{ totalAmount: 0 }],
          TotalOpened: response.data.results.TotalOpened || [
            { totalAmount: 0 },
          ],
          TotalNotOpened: response.data.results.TotalNotOpened || [
            { totalAmount: 0 },
          ],
        });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, [piedatafun, state, mydatefilter, storedUser._id]);
  // const jwtCookie = Cookies.get('jwt')
  // console.log('JWT cookie value:', jwtCookie);
  console.log(recipientTotal, "toa");
  const myfilter = (e) => {
    console.log(e, "e");
    setmydatefilter(e);
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdEmail} color={brandColor} />}
            />
          }
          name="Email Campaigns"
          value="$350.4"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdCheck} color={brandColor} />}
            />
          }
          name="Email Read"
          value={
            recipientTotal.TotalOpened && recipientTotal.TotalOpened[0]
              ? recipientTotal.TotalOpened[0].totalAmount
              : 0
          }
        />
        <div
          onClick={() => {
            navigate("/Audiences");
          }}
        >
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={<Icon w="32px" h="32px" as={MdLens} color={brandColor} />}
              />
            }
            name="Target Audience"
            value={targetAudienceCount}
          />
        </div>
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
              icon={<Icon w="28px" h="28px" as={MdSend} color="white" />}
            />
          }
          name="Email Send"
          value={
            recipientTotal.TotalSent && recipientTotal.TotalSent[0]
              ? recipientTotal.TotalSent[0].totalAmount
              : 0
          }
        />

        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdCancel} color={brandColor} />}
            />
          }
          name="Email Not Opened"
          value={
            recipientTotal.TotalNotOpened && recipientTotal.TotalNotOpened[0]
              ? recipientTotal.TotalNotOpened[0].totalAmount
              : 0
          }
        />
        <Box>
          <DefinedRange
            ranges={state}
            onChange={(item) => myfilter([item.selection])}
            name="Filter"
          />
        </Box>
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <TotalSpent myfilter={mydatefilter} />
        <PieChart />
      </SimpleGrid>
      <ActiveCampaignTable />
    </Box>
  );
}
