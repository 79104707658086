import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import { useCallback } from "react";
// import Posts from "../../Components/Posts";
import useOrderStore from "contexts/Userhook";
import Layout from "layouts/Layout/Layout";
import Posts from "./Posts";
import { useNavigate } from "react-router-dom";
import { ConnectedFbPgeApi } from "./Connectedpageapi/ConnectedfbpageApi";
import { Spinner } from "@chakra-ui/react";

const ServerUrl = process.env.REACT_APP_BACKEND_URL;

const FacebookHome = () => {




  const { storedUser } = useOrderStore();
  const [dataloading, setdataloading] = useState(false);
  const [pagesloading, setpagesloading] = useState(false);
  const [allpages, setallpages] = useState([]);
  const [allpost, setallpost] = useState({posts:[],next:""});
  const [isposts, setispost] = useState(false);
  const [token, settoken] = useState("");
  const [currentPageDetails, setcurrentPageDetails] = useState({});
  const [scrollloading,setscrollloading]=useState(false)
  const [allpostLoading, setallpostLoading] = useState(false);

// // 
const allpostscrollview=useRef(null)



// const isManager = storedUser && storedUser.role === "user";
const isManager = storedUser && (storedUser.role === "manager"&&!storedUser.socailpermission);




const [ fisrtTimeview,setfisrtTimeview]=useState(false)



  // 

  const gettingpageapi = useCallback(async (userid, token) => {
    try {
      setpagesloading(true);
      const { data } = await axios.get(
        `https://graph.facebook.com/v18.0/${userid}/accounts?access_token=${token}&fields=access_token,id,name,cover,category,likes,fan_count`
      );
      if (data) {
       
        const pagesWithLikesAndFollowers = data.data.map((page) => ({
          ...page,
          likes: page.likes,
          followers: page.fan_count,
        }));
        const mypages = async () => {
          let data = await ConnectedFbPgeApi(
            ServerUrl,
            storedUser,
            setdataloading
          );
          if (data.length > 0) {
            const uIds = data
              .filter((x) => !x.Instagram)
              .map((item) => item.id);
            // const uIds = data.map((item) => item.id);
            const allConnected = pagesWithLikesAndFollowers.filter((x) =>
              uIds.includes(x.id)
            );
            
            setallpages(allConnected);
          } else {
            setallpages([]);
            toast.warn("please connect the page");
          }
        };
        mypages();

        setpagesloading(false);
      }
    } catch (error) {
      
      let err =
        error.message && error.response.data.error.message
          ? error.response.data.error.message
          : error.message;
      toast.error(err);
      setpagesloading(false);
    }
  }, [storedUser]);

  useEffect(() => {
    let url=storedUser.role==="manager"?`${ServerUrl}/fb/FBToken/mydata/${storedUser.roleby}`:`${ServerUrl}/fb/FBToken/mydata/${storedUser._id}`
    const mydata = async () => {
      try {
        const { data } = await axios.get(
          url
          // `${ServerUrl}/fb/FBToken/mydata/${storedUser._id}`
        );
        if (data) {
          
          setdataloading(false);
          toast.success(
            data?.facebookdata?.first_name +
              "wait until we are getting your pages",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          gettingpageapi(data.facebookdata.userID, data.access_token);
        }
      } catch (error) {
        toast.error(error.message);
        setdataloading(false);
      }
    };
    mydata();
  }, [gettingpageapi, storedUser._id, storedUser.role, storedUser.roleby]);



  // page post scroll

  useEffect(()=>{
    // console.log(isManager,"ismanager")
    if ((fisrtTimeview&&allpost?.next?.length>0)) {
      const HandleInfinteScroll=async()=>{
        const height=document.documentElement.scrollHeight
        const innerHeght=window.innerHeight
        const scrolltop=document.documentElement.scrollTop
        
        if(innerHeght+scrolltop+2>=height){
          try {
            setscrollloading(true)
           
          
            
              const { data } = await axios.get(
                `https://graph.facebook.com/v18.0/me/feed?limit=5&after=${allpost.next}&access_token=${token}&fields=posts{permalink_url},id,created_time,message,attachments,from{id,name,picture}`
                // `https://graph.facebook.com/v18.0/${post.id}/feed?limit=2?access_token=${post.access_token}&fields=posts{permalink_url},id,created_time,message,attachments,from{id,name,picture}`
                );
                if (data) {
                  setTimeout(() => {
                    setscrollloading(false)
                    }, 3000);
                 
                  setallpost((prev)=>{
                    return{
                      posts:[...prev.posts,...data?.data],
                      next:data?.paging?.next
                    }
  
                  })
                }
          
        } catch (error) {
          toast.error(error.message)
          
        }
       }
      
      }
      window.addEventListener("scroll",HandleInfinteScroll)
      return (()=>window.removeEventListener("scroll",HandleInfinteScroll))
      
    }
  },[allpost, fisrtTimeview, token])
  

  // 
  const mypagepost = async (post) => {
    try {
      setispost(true);
      const { data } = await axios.get(
        `https://graph.facebook.com/v18.0/${post.id}/feed?limit=3&access_token=${post.access_token}&fields=posts{permalink_url},id,created_time,message,attachments,from{id,name,picture}`
        // `https://graph.facebook.com/v18.0/${post.id}/feed?limit=2?access_token=${post.access_token}&fields=posts{permalink_url},id,created_time,message,attachments,from{id,name,picture}`
      );
      const { data: pageDetails } = await axios.get(
        `https://graph.facebook.com/v18.0/${post.id}?access_token=${post.access_token}&fields=likes,category,fan_count`
      );
      if (pageDetails) {
        setispost(false);
        setcurrentPageDetails({
          ...post,
          likes: pageDetails.likes,
          followers: pageDetails.fan_count,
        });
      }
      if (data) {
        setispost(false);
        
        setallpost({
          posts:data?.data,
          next:data?.paging?.next
});
setfisrtTimeview(true)
        settoken(post.access_token);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const naviagte = useNavigate();
  const AllinOne = () => {
    naviagte("/AllinOne");
  };



  
  return (
    <Layout>
      {dataloading && <FaSpinner className="mx-auto mt-4 text-4xl" />}
      <div className="text-center text-4xl font-bold mb-4 text-blue-800">
        <button onClick={AllinOne} className="text-blue-800">Your Facebook</button>
        <br></br>
        Pages
      </div>
      {pagesloading && <FaSpinner className="mx-auto mt-4 text-4xl" />}
      <div className="flex flex-wrap mx-5"  ref={allpostscrollview}>
        {allpages?.map((x) => (
          <div
            key={x.id}
            onClick={() => mypagepost(x)}
            className="mr-10 my-5 p-5 w-72 border-2 border-black"
          >
            <img src={x?.cover?.source} alt="coverphoto" className="mb-4" />
            <ul>
              <li className="mb-2">category: {x.category}</li>
              <li className="mb-2">name: {x.name}</li>
              {/* <li className="flex items-center">
                <FaThumbsUp className="mr-2" /> Likes: {x.likes}
              </li> */}
              <li>Followers: {x.followers}</li>
            </ul>
          </div>
        ))}
      </div>
      {isposts ? (
        <FaSpinner />
      ) : token.length > 0 ? (
        <Posts
          allpost={allpost}
          token={token}
          currentPageClicked={currentPageDetails}
          setallpost={setallpost}
        />
      ) : (
        <div className="text-center mt-5">
          
          please click on a page to see posts if exists
        </div>
      )}
  <div className="text-center mt-5">
    {scrollloading&&(<Spinner size="lg" color="blue"/>)}
  </div>
    </Layout>
  );
};

export default FacebookHome;
