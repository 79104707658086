import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import { Box, Image, Text, Spinner, Button } from "@chakra-ui/react";
import Layout from "layouts/Layout/Layout";
import { PublishPost } from "./PublishPost";
import InstaPosts from "./InstaPosts";
import useOrderStore from "contexts/Userhook";
import { ConnectedFbPgeApi } from "components/Facebook/Connectedpageapi/ConnectedfbpageApi";
import ApprovePosts from "components/ApprovePosts/ApprovePosts";
const ServerUrl = process.env.REACT_APP_BACKEND_URL;

const InstaHome = () => {
  const { storedUser } = useOrderStore();
  const [dataloading, setdataloading] = useState(false);
  const [pagesloading, setpagesloading] = useState(false);
  const [allpages, setallpages] = useState([]);
  // const [instaPost, setInstaPost] = useState([]);
  const [instaPost, setInstaPost] = useState({posts:[],next:""});
  // const [allpost, setallpost] = useState({posts:[],next:""});
  const [instaPostLoading, setInstaPostLoading] = useState(false);
  const [selectedPageData, setSelectedPageData] = useState({});
  const [showPublishPost, setShowPublishPost] = useState(false);
  const [scrollloading,setscrollloading]=useState(false)
  const [publishData, setPublishData] = useState({
    token: null,
    businessAccountId: null,
    pageID: null,

  });
  const [currentpagePosts, setcurrentpagePosts] = useState({});
  const [showInformation, setShowInformation] = useState(false);
  const [ fisrtTimeview,setfisrtTimeview]=useState(false)
 

  const gettingpageapi = useCallback(async (userid, token) => {
    try {
      setpagesloading(true);

      const { data } = await axios.get(
        `https://graph.facebook.com/v18.0/${userid}/accounts?access_token=${token}&fields=access_token,id,name,cover,category,instagram_business_account`
      );

      if (data) {
        setpagesloading(false);
        const mypages = async () => {
          let mydata = await ConnectedFbPgeApi(
            ServerUrl,
            storedUser,
            setdataloading
          );
          if (mydata.length > 0) {
            console.log(mydata, "yes me andar");
            // const uIds = data.map((item) => item.id);
            const uIds = mydata
              .filter((x) => x.Instagram)
              .map((item) => item.id);
            const allConnected = data?.data.filter((x) => uIds.includes(x?.instagram_business_account?.id));
            console.log(allConnected,"allconnn")
            setallpages(allConnected);
          } else {
            setallpages([]);
            toast.warn("please connect the page");
          }
        };
        mypages();
        // const uIds = data.filter((x)=>!x.Instagram).map((item) => item.id);
        // setallpages(data.data);
      }
    } catch (error) {
      let err =
        error.message && error.response.data.error.message
          ? error.response.data.error.message
          : error.message;
      toast.error(err);
      setpagesloading(false);
    }
  }, [storedUser]);

  useEffect(() => {
    let url=storedUser.role==="manager"?`${ServerUrl}/fb/FBToken/mydata/${storedUser.roleby}`:`${ServerUrl}/fb/FBToken/mydata/${storedUser._id}`
    const mydata = async () => {
      // const isLoggedIn = localStorage.getItem("LoggedIn")
      //   ? JSON.parse(localStorage.getItem("LoggedIn"))
      //   : null;
      try {
        setdataloading(true);
        const { data } = await axios.get(
          url
          // `${ServerUrl}/fb/FBToken/mydata/${storedUser._id}`
        );
        if (data) {
          setdataloading(false);
          gettingpageapi(data.facebookdata.userID, data.access_token);
        }
      } catch (error) {
        toast.error(error.message);
        setdataloading(false);
      }
    };
    mydata();
  }, [gettingpageapi, storedUser._id]);

  const Instaposts = useCallback(async (x, token) => {
    try {
      setInstaPostLoading(true);
      const { data } = await axios.get(
        `https://graph.facebook.com/v18.0/${x?.id}/media?limit=3&fields=id,permalink,username,media_type,media_url,thumbnail_url,caption,timestamp,like_count,comments_count,comments{from,text,like_count,timestamp,username,user,replies{text}},children{media_type,media_url,thumbnail_url}&access_token=${token}`
      );

      if (data) {
        setInstaPostLoading(false);
        setfisrtTimeview(true)
        setInstaPost({
          posts:data?.data,
          next:data?.paging?.next
        });
      }
    } catch (error) {
      setInstaPostLoading(false);
      toast.error(error.message);
    }
  }, []);


  useEffect(()=>{
    if (fisrtTimeview&&instaPost?.next?.length>0) {
      const HandleInfinteScroll=async()=>{
        const height=document.documentElement.scrollHeight
        const innerHeght=window.innerHeight
        const scrolltop=document.documentElement.scrollTop
        if(innerHeght+scrolltop+2>=height){
          try {
            setscrollloading(true)
            // console.log(allpost.next,"ye next link h")
            const { data } = await axios.get(
              `https://graph.facebook.com/v18.0/${publishData.businessAccountId}/media?limit=3&after=${instaPost.next}&fields=id,permalink,username,media_type,media_url,thumbnail_url,caption,timestamp,like_count,comments_count,comments{from,text,like_count,timestamp,username,user,replies{text}},children{media_type,media_url,thumbnail_url}&access_token=${publishData.token}`
            );
                if (data) {
                  setTimeout(() => {
                    setscrollloading(false)
                    }, 3000);
                  console.log("data h ye",data)
                  setInstaPost((prev)=>{
                    return{
                      posts:[...prev.posts,...data?.data],
                      next:data?.paging?.next
                    }
  
                  })
                }
          
        } catch (error) {
          toast.error(error.message)
          
        }
       }
      
      }
      window.addEventListener("scroll",HandleInfinteScroll)
      return (()=>window.removeEventListener("scroll",HandleInfinteScroll))
      
    }
  },[fisrtTimeview, instaPost.next, instaPost.next?.length, publishData.businessAccountId, publishData.token])
  const GetInstaMediaWithConnectedPage = async (x) => {
    console.log(x, "insta");
    setcurrentpagePosts(x);
    setInstaPost([]);

    try {
      const { data } = await axios.get(
        `https://graph.facebook.com/v18.0/${x?.id}?fields=instagram_business_account,name&access_token=${x?.access_token}`
      );

      if (data.instagram_business_account) {
        console.log(data, "ye raha insta");
        setPublishData({
          token: x?.access_token,
          businessAccountId: data.instagram_business_account.id,
          pageID: x?.id,
        });
        toast.success("Found Connected! Please Wait,We are getting Your Posts");
        setShowPublishPost(true);
        
        Instaposts(data?.instagram_business_account, x?.access_token);

        const instaAccountData = await axios.get(
          `https://graph.facebook.com/v18.0/${x?.id}/instagram_accounts?fields=follow_count,followed_by_count,username,profile_pic,media_count,is_private&access_token=${x?.access_token}`
        );
        setSelectedPageData({
          followCount: instaAccountData.data.data[0]?.follow_count,
          followedByCount: instaAccountData.data.data[0]?.followed_by_count,
          username: instaAccountData.data.data[0]?.username,
          posts: instaAccountData.data.data[0]?.media_count,
          status: instaAccountData.data.data[0]?.is_private,
          ...x,
        });
      } else {
        toast.error(
          "Sorry your this page is not connected with instagram business Account"
        );
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Layout>
      {dataloading && <Spinner size="xl" />}

      <Box
        textAlign="center"
        fontSize="3xl"
        color="black"
        fontWeight="bold"
        mt="5"
      >
        Pages
      </Box>
      {pagesloading && <Spinner size="xl" />}
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
      >
        {allpages?.map((x) => (
          <Box
            key={x.id}
            onClick={() => {
              GetInstaMediaWithConnectedPage(x);
            }}
            m="4"
            p="4"
            minH="250px"
            width="250px"
            borderWidth="2px"
            borderRadius="md"
            cursor="pointer"
            _hover={{
              borderColor: "blue.500",
            }}
          >
            <Image
              src={x?.cover?.source}
              alt="coverphoto"
              boxSize="100%"
              borderRadius="md"
              mb="2"
              style={{ height: "150px" }}
            />
            <Text fontSize="lg" fontWeight="bold" mb="2">
              {x.name}
            </Text>

            {showInformation && selectedPageData.id === x.id && (
              <>
                <Text fontSize="md">Category: {x.category}</Text>
                <Text fontSize="md">
                  Followers Count: {selectedPageData.followCount}
                </Text>
                <Text fontSize="md">
                  Followed By Count: {selectedPageData.followedByCount}
                </Text>
                <Text fontSize="md">Username: {selectedPageData.username}</Text>
                <Text fontSize="md">
                  No. of Posts: {selectedPageData.posts}
                </Text>
                <Text fontSize="md">
                  Account Status:{" "}
                  {selectedPageData.is_private ? "Private" : "Open"}
                </Text>
              </>
            )}
            <Button
              mt="2"
              color="white"
              backgroundColor="teal"
              onClick={() => setShowInformation(!showInformation)}
            >
              {showInformation ? "Hide Information" : "Show Information"}
            </Button>
          </Box>
        ))}
      </Box>

      {showPublishPost && (
        <Box my="8">
          <PublishPost publishData={publishData} />
        </Box>
      )}

      <InstaPosts
        currentpagePosts={currentpagePosts}
        instaPost={instaPost}
        instaPostLoading={instaPostLoading}
      />
       <div className="text-center mt-5">
    {scrollloading&&(<Spinner size="lg" color="blue"/>)}
  </div>
    </Layout>
  );
};

export default InstaHome;
