import { Switch, Td } from '@chakra-ui/react'
import React from 'react'

export const SwitchButton = ({editdata,manager, handleChange}) => {
 


console.log(editdata?.isEmailAllowd,"ppppppp1p",editdata?.email)


  let myarray=[
    "Profile",
    "AllManager",
    "media",
    "EmailCampaigns",
    "Audiences",
    "Manager",
    "Calendar",
    "ApprovePost",
    "Editors",
    "Emails",
    
    

  ]
  return (
    <>
    {myarray.map((x)=>(
      
      
      <Td >
      
                {editdata.id === manager._id ? (
                  <Switch
                    name={x}
                    isChecked={editdata[x]}
                    value={""}
                    onChange={handleChange}
                  />
              ) : editdata?.PagesPermission[0][x] ? (
                  "Yes"
                ) : (
                  "No"
                )}
              </Td>

    ))}
      <Td >
  {editdata.id === manager._id ? (
                  <Switch
                    name={"isEmailAllowd"}
                    isChecked={editdata?.isEmailAllowd}
                    value={""}
                    onChange={handleChange}
                  />
              ) : editdata?.isEmailAllowd ? (
                  "Yes"
                ) : (
                  "No"
                )}
              </Td>
    </>
  )
}
