import React, { useRef, useState, useEffect, useContext, useMemo } from "react";

import sample from "./savefile.json";
import {
  Select,
  Checkbox,
  Input,
  Textarea,
  Button,
  Flex,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  CircularProgress,
  Spinner,
  FormLabel,
} from "@chakra-ui/react";
import EmailEditor from "react-email-editor";
import Layout from "layouts/Layout/Layout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import useOrderStore from "contexts/Userhook";
import { toast } from "react-toastify";
import { useCalendar } from "../../components/context/CalendarContext";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { handleReset } from "./Component/HandleReset";
import { exportHtml } from "./Component/DownloadHtml";
import { handleTemplateChange } from "./Component/SelectTemplate";
import { openPopup } from "./Component/OpenPopup";
import { ChevronDownIcon } from "@chakra-ui/icons";
const serverURL = process.env.REACT_APP_BACKEND_URL;
const EmailID = process.env.REACT_APP_EMAIL_ID;

const Editor = (props) => {
  const { storedUser } = useOrderStore();
  const [htmlsavingloading, sethtmlsavingloading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [TempsaveLoding, setTempsaveLoding] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [audienceTitle, setaudienceTitle] = useState("");

  const [selectedService, setSelectedService] = useState("");

  const [password, setPassword] = useState("");
  const [autosave, setautosave] = useState(false);
  const { addCalendarEvent } = useCalendar();
  const [emailData, setEmailData] = useState({
    from: "",
    atOnce: false,
    to: "",
    cc: "",
    bcc: "",
    subject: "",
    message: "",
    id: null,
  });
  const [loading, setLoading] = useState(true);
  const [sentLoading, setSentLoading] = useState(false);
  const [error, setError] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [SelectedTemplateIdForDELETE, setSelectedTemplateIdForDELETE] =
    useState(null);
  const location = useLocation();
  const [audienceid, setaudienceid] = useState("");
  const [ParamsIDtempl, setParamsIDtempl] = useState(null);
  const [scheduledTime, setScheduledTime] = useState(null);
  const [isSchedule, setIsSchedule] = useState(false);
  const [DesginExsits, setDesginExsits] = useState(false);
  const [immediate, setImmediate] = useState(false);
  const [defaultid, setdefaultid] = useState(false);
  const [providers, setProviders] = useState([]);
  const [audiences, setAudiences] = useState([]);




  // All the audience of the user
  useEffect(() => {
    async function fetchAudiences() {
      let Url=storedUser.role==="manager"?`${serverURL}/api/MygetAudience/${storedUser.roleby}`:`${serverURL}/api/MygetAudience/${storedUser._id}`
      try {
        const { data } = await axios.get(
          Url
        );
        console.log("ppapapap=====>", data);
        if (data) {
          setAudiences(data);
        } else {
          toast.warning(data.message);
        }
      } catch (error) {
        console.log(error);
        let err =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        toast.error(err);
      }
    }

    fetchAudiences();
  }, []);


  console.log(audiences,"audiences")
  useEffect(() => {
    console.log("==Stored User==>", storedUser._id);
    const fetchServiceProvider = async () => {
      try {
        const response = await axios.get(
          `${serverURL}/email/nodemailerService/${storedUser._id}`
        );
        setProviders(response?.data?.data?.Providers);
        console.log("Response=====>", response);
        console.log("Providers stored in state:", providers);
      } catch (error) {
        console.log("Error====>", error);
      }
    };
    fetchServiceProvider();
  }, [storedUser._id]);

  // mond
  const [fromaudi, setfromaudi] = useState("");
  //
  const navigate = useNavigate();
  const handleModalOpen = () => {
    setIsOpen(true);
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleTemplateNameChange = (event) => {
    setTemplateName(event.target.value);
  };

  const handleDraft = async () => {
    await SaveTemplate();
    navigate("/data-tables");
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const dateParam = urlParams.get("date");

    if (dateParam) {
      const selectedDate = new Date(dateParam);
      setScheduledTime(selectedDate);
      setIsSchedule(true);
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const id = queryParams.get("audienceid");

    if (id) {
      const gettingteam = async () => {
        try {
          const { data } = await axios.get(
            `${serverURL}/api/select/allmember/${id}`
          );
          if (data) {
            const emails = data
              .map((item) => item.member.emailAddress)
              .join(",");

            if (emails.length > 0) {
              // mo
              const emailwithout = data.map((item) => item.member.emailAddress);

              setfromaudi(emailwithout);
              //
              setEmailData((prev) => ({
                ...prev,
                to: emails,
              }));
            }
          }
        } catch (error) {
          toast.error(error.message);
        }
      };
      gettingteam();
    }
    setaudienceid(id);
  }, [location.search]);
  // useEffect(() => {
  //   const getAllRecipients = async () => {
  //     try {
  //       const response = await axios.get(`${serverURL}/recipient`);
  //       setRecipients(response.data);
  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //     }
  //   };
  //   getAllRecipients();
  // }, []);

  const getHtmlContent = async () => {
    return new Promise((resolve, reject) => {
      emailEditorRef.current.editor.exportHtml((data) => {
        resolve(data.html);
      });
    });
  };
  const handleChange = (event) => {
    const userInput = event.target;
    if (userInput.name === "atOnce") {
      setEmailData({
        ...emailData,
        [userInput.name]: userInput.checked,
      });
    } else {
      setEmailData({
        ...emailData,
        [userInput.name]: userInput.value,
      });
    }
  };

  // fetching templ api
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get(
          `${serverURL}/recipient/GetTemplates/${storedUser._id}`
        );
        setTemplates(response.data.templates);
      } catch (error) {
        toast.error(error.message);
      }
    };

    fetchTemplates();
  }, [storedUser._id]);

  // sending email api
  const handleSubmit = async (event) => {
    if (emailData.to.trim() === "") {
      toast.error("Kindly fill the Email Addresses");
      return;
    }
    const emailAddresses = emailData.to.split(",");

    for (const emailAddress of emailAddresses) {
      if (!emailAddress.trim().includes("@")) {
        toast.error(`Kindly fill in the right email for ${emailAddress}`);
        setEmailData((prev) => ({ ...prev, to: "" }));
        return;
      }
    }

    if (emailData.subject.trim() === "") {
      toast.error("Kindly fill the Right Email");
      return;
    }
    // mo

    //
    event.preventDefault();
    try {
      setError(false);
      setSentLoading(true);
      const htmlContent = await getHtmlContent();
      const requestData = {
        adminEmail: selectedEmail,
        to: emailData.to,
        cc: emailData.cc,
        bcc: emailData.bcc,
        subject: emailData.subject,
        message: `${emailData.message}\n\n${htmlContent}`,
        atOnce: emailData.atOnce,
        user: storedUser._id,
        audienceid: audienceid,
        isSchedule: isSchedule,
        scheduledTime: scheduledTime,
        // mon
        isEmailAllowd: storedUser.isEmailAllowd,
        Adminid: storedUser.roleby,
        fromaudi,
        selectedService: selectedService,
        password: password,
        //
      };

      const response = await axios.post(
        `${serverURL}/recipient/send-email`,
        requestData
      );

      setRecipients((prev) => {
        return [...prev, response.data];
      });
      setSentLoading(false);
      if (isSchedule && scheduledTime) {
        addCalendarEvent({
          title: `Email to ${emailData.to} at ${scheduledTime}`,
          start: scheduledTime,
          end: scheduledTime,
        });
      }

      closePopup();
      if (immediate) {
        toast.success("Email Scheduled Successfully");
      } else {
        toast.success("Email Sent Successfully");
      }

      navigate("/data-tables");
    } catch (error) {
      setError(true);
      setSentLoading(false);
      toast.error(error.message);
    }
  };

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      const selectedTemplate = templates.find(
        (template) => template._id === id
      );

      setSelectedTemplateId(id);
      setTemplateName(selectedTemplate?.templateName);

      if (selectedTemplate) {
        const parsedDesign = JSON.parse(selectedTemplate.template);
        if (parsedDesign) {
          emailEditorRef?.current?.editor?.loadDesign(parsedDesign);
        }
      }
    }
  }, [id, templates]);
  // 198

  // handleReset(setEmailData)

  const closePopup = () => {
    setIsModalOpen(false);
    setSelectedTemplateId("");
  };
  const emailEditorRef = useRef(null);

  ////save file html to json

  const saveDesign = async () => {
    let alldesign = "";
    await emailEditorRef.current.editor.saveDesign((design) => {
      alldesign = JSON.stringify(design, null, 4);
    });

    const designsaving = async () => {
      try {
        sethtmlsavingloading(true);
        const { data } = await axios.post(
          `${serverURL}/recipient/SavingTemplate`,
          {
            template: alldesign,
            datatype: "design",
            user: storedUser._id,
            selectedTemplateId: selectedTemplateId || null,
            templateName: templateName,
          }
        );
        if (
          data.message === "your template has been saved" ||
          data.message === "your template has been updated"
        ) {
          sethtmlsavingloading(false);
          toast.success("Desgin template has been saved");
          window.location.reload();
        }
      } catch (error) {
        sethtmlsavingloading(false);
        const err =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            background: "lightcoral",
          },
        });
      }
    };
    setTimeout(async () => {
      await designsaving();
    }, 1000);
    handleModalClose();
  };

  const deleteTemplate = async () => {
    try {
      const response = await axios.delete(
        `${serverURL}/recipient/DeleteTemplates/${selectedTemplateId}`
      );

      if (response.data.message === "Template deleted successfully") {
        toast.success("Template deleted successfully");
        setSelectedTemplateId(null);
        setTemplateName("");
        window.location.reload();
      }
    } catch (error) {
      const err =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      toast.error(err, {
        position: toast.POSITION.TOP_RIGHT,
        style: {
          background: "lightcoral",
        },
      });
    }
  };

  ////////////////////////////////////////////////////////

  // save temp api
  const SaveTemplate = async () => {
    const htmlContent = await getHtmlContent();

    try {
      emailData.userid = storedUser._id;
      // emailData.id = emailData.id ? emailData.id : null;
      setTempsaveLoding(true);
      let newmsg = `${emailData.message}\n\n${htmlContent}`;
      const { data } = await axios.post(
        `${serverURL}/draft/Draftmail/${emailData.id}`,

        { ...emailData, message: newmsg }
      );

      if (data) {
        toast.success(data.message);
        setTempsaveLoding(false);
        setIsModalOpen(false);
        return data.data;
      }
    } catch (error) {
      let err =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      toast.error(err);
      setTempsaveLoding(false);
    }
  };
  // api for fetching design with id
  const params = useParams();
  useMemo(() => {
    const mydraft = async () => {
      if (params.id) {
        try {
          const { data } = await axios.get(
            `${serverURL}/draft/draft/${params.id}`
          );
          if (data) {
            setEmailData({
              from: data.from || "",
              to: data.to || "",
              cc: data.cc || "",
              bcc: data.bcc || "",
              subject: data.subject || "",
              message: data.message || "",
              atOnce: data.atOnce || "",
              id: data._id,
            });
            setIsModalOpen(true);
          }
        } catch (error) {
          let err =
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message;
          toast.error(err);
        }
      }
    };
    mydraft();
  }, [params]);

  const ImportHtml = (e) => {
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      let data = e.target.result;

      if (emailEditorRef.current && emailEditorRef.current.editor && data) {
        const parsedDesign = JSON.parse(data);
        emailEditorRef.current.editor.loadDesign(parsedDesign);
        setSelectedTemplateId(null);
        setTemplateName("");
      } else {
        toast.error(
          "emailEditorRef or its properties are not properly initialized."
        );
        // console.error(
        //   "emailEditorRef or its properties are not properly initialized."
        // );
      }
    };
    reader.readAsText(file);
  };

  useEffect(() => {
    setTimeout(() => {
      if (emailEditorRef.current && emailEditorRef.current?.editor) {
        emailEditorRef.current?.editor.addEventListener(
          "design:updated",
          async function (updates) {
            setTimeout(() => {
              emailEditorRef?.current?.editor?.exportHtml(async function (
                data
              ) {
                var json = data.design; // design json

                let alldesign = JSON.stringify(json, null, 4);

                if (alldesign.trim().length > 0 && defaultid) {
                  const designsaving = async () => {
                    setdefaultid(false);
                    try {
                      setautosave(true);
                      const { data } = await axios.post(
                        `${serverURL}/recipient/SavingTemplate`,
                        {
                          template: alldesign,
                          datatype: "design",
                          user: storedUser._id,
                          selectedTemplateId: selectedTemplateId || null,
                          templateName:
                            templateName.trim().length > 0
                              ? templateName
                              : null,
                        }
                      );
                      if (
                        data.message === "your template has been saved" ||
                        data.message === "your template has been updated"
                      ) {
                        //  sethtmlsavingloading(false);

                        //  window.location.reload();
                        setdefaultid(true);

                        setSelectedTemplateId(data.StoringTemp._id);
                        setTemplateName(data.StoringTemp.templateName);
                        setautosave(false);
                      }
                    } catch (error) {
                      sethtmlsavingloading(false);
                      const err =
                        error.response && error.response.data.message
                          ? error.response.data.message
                          : error.message;
                      toast.error(err, {
                        position: toast.POSITION.TOP_RIGHT,
                        style: {
                          background: "lightcoral",
                        },
                      });
                      setautosave(false);
                    }
                  };
                  designsaving();
                } else {
                  setdefaultid(true);
                }
              });
            }, 2000);
          }
        );
      }
    }, 5000);
  }, [defaultid, emailData, selectedTemplateId, storedUser._id, templateName]);

  const AudienceFunction=(x)=>{
    if(x?.team?.length>0){
      const MyTeam=x.team.map((email)=>email?.member?.emailAddress).filter((r)=>r!=="?")
       if(MyTeam.length>0){
        emailData.to=MyTeam
         console.log("1")
         
        }

    }else{
      emailData.to=""
    }


  }
  return (
    <Layout>
      <div className="container" style={{ position: "relative" }}>
        <text>
          <p></p>
        </text>
        <div>
          {/* <button class="btn btn-primary" onClick={saveDesign}>
          Save Design
        </button> */}
          {!isModalOpen && selectedTemplateId?.trim().length > 0 ? (
            <Button
              mr={800}
              mb={10}
              colorScheme="green"
              rounded={"full"}
              onClick={handleModalOpen}
              // onClick={saveDesign}
            >
              Update
            </Button>
          ) : (
            <Button
              mr={800}
              mb={10}
              colorScheme="green"
              rounded={"full"}
              onClick={handleModalOpen}
            >
              Save Design
            </Button>
          )}
          <Modal isOpen={isOpen} onClose={handleModalClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Enter Template Name</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Input
                  placeholder="Enter template name"
                  value={templateName}
                  onChange={handleTemplateNameChange}
                />
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={saveDesign}>
                  Save
                </Button>
                <Button onClick={handleModalClose}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <div>
            <Select
              onChange={(e) =>
                handleTemplateChange(
                  e,
                  setSelectedTemplateId,
                  templates,
                  setTemplateName,
                  setSelectedTemplateIdForDELETE,
                  emailEditorRef
                )
              }
            >
              <option value={""}>Select a template</option>

              {templates.map((template) => (
                <>
                  <option
                    key={template._id}
                    value={template._id}
                    selected={selectedTemplateId === template._id}
                    style={
                      template?.user?.role === "manager"
                        ? { color: "red", font: "15px" }
                        : { color: "blue", font: "15px" }
                    }
                  >
                    {template.templateName}
                    &nbsp; &nbsp; ({template?.user?.role})
                  </option>
                </>
              ))}
            </Select>
          </div>
          <text> </text>
          <Button
            colorScheme="blue"
            mb={10}
            onClick={() => exportHtml(emailEditorRef)}
            rounded={"full"}
            mt={5}
          >
            Export Desgin
          </Button>
          &nbsp;
          {autosave && <Spinner />}
          {autosave && "Saving..."}
          <FormLabel>Import Design</FormLabel>
          <Input
            colorScheme="blue"
            mb={0}
            onChange={ImportHtml}
            rounded={"full"}
            type="file"
            accept="text/plain"
            mt={0}
          />
          {selectedTemplateId && (
            <Button
              colorScheme="red"
              mb={10}
              ml={50}
              onClick={deleteTemplate}
              rounded={"full"}
              mt={5}
            >
              Delete Template
            </Button>
          )}
          <text>
            <p></p>
          </text>
        </div>
        <EmailEditor ref={emailEditorRef} minHeight="92vh" minWidth="300vh" />
      </div>
      <div
        style={{
          position: "absolute",
          top: "83%",
          left: "85%",
          transform: "translate(-50%, -50%)",
          width: "425px",
          height: "70px",
          backgroundColor: "#0B1EFF",
          zIndex: 999,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <p style={{ color: "white" }}>
          © 2023 A Go Market Product By JP Operations
        </p>
      </div>
      <Box p="20px" borderRadius="md">
        <Flex className="email-form-container" p="20px" gridGap="30px">
          <Button
            onClick={() =>
              openPopup(emailEditorRef, setDesginExsits, setIsModalOpen)
            }
            color="white"
            backgroundColor="#373DFE"
          >
            Send Email
          </Button>
          <Button onClick={handleDraft} color="white" backgroundColor="#373DFE">
            Draft Emails
          </Button>
          <Modal isOpen={isModalOpen} onClose={closePopup}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Email Form</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <form
                  onSubmit={handleSubmit}
                  onReset={() => handleReset(setEmailData)}
                >
                  <FormLabel htmlFor="email">From:</FormLabel>
                  <br></br>

                  <Menu style={{ backgroungColor: "black" }}>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      style={{
                        width: "400px",
                        color: "black",
                        fontWeight: "bold",
                        marginBottom: "30px",
                        borderColor: "black",
                        backgroungColor: "black",
                        margin: "0 auto",
                      }}
                    >
                      {selectedEmail || "Select an email"}
                    </MenuButton>
                    <MenuList>
                      {providers?.map((provider, index) => (
                        <MenuItem
                          style={{ backgroungColor: "black" }}
                          key={index}
                          value={provider?.email}
                          onClick={() => {
                            setSelectedEmail(provider?.email);
                            setSelectedService(provider?.service);
                            setPassword(provider?.password);
                          }}
                        >
                          {provider?.email}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                  <FormLabel htmlFor="email">Select Audience</FormLabel>
                  <br></br>

                  
                  <FormLabel>Service Provider:</FormLabel>
                  <Input
                    type="text"
                    name="to"
                    placeholder=""
                    value={selectedService}
                    borderColor="black"
                    mb={7}
                    mt={3}
                    isReadOnly
                  />
                  {/* <Input
                    type="password"
                    name="to"
                    placeholder=""
                    value={password}
                    borderColor="black"
                    mb={7}
                    isReadOnly
                  /> */}
                  <Menu style={{ backgroungColor: "black" }}>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      style={{
                        width: "400px",
                        color: "black",
                        fontWeight: "bold",
                        marginBottom: "30px",
                        borderColor: "black",
                        backgroungColor: "black",
                        margin: "0 auto",
                      }}
                    >
                      {audienceTitle || "Select an email"}
                    </MenuButton>
                    <MenuList>
                      {audiences?.map((audi, index) => (
                        <MenuItem
                          style={{ backgroungColor: "black" }}
                          key={index}
                          value={audi?.audienceTitle}
                          onClick={() => {
                            setaudienceTitle(audi?.audienceTitle);
                            AudienceFunction(audi)
                            // setSelectedService(provider?.service);
                            // setPassword(provider?.password);
                          }}
                        >
                          {audi?.audienceTitle} &nbsp;&nbsp;&nbsp;{audi?.team?.length}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                  <br/>

                  <Flex
                    id="at-once-sec"
                    align="center"
                    fontSize="17px"
                    fontWeight="600"
                  >
                    <Checkbox
                      name="atOnce"
                      onChange={handleChange}
                      isChecked={emailData.atOnce}
                      size="lg"
                      borderColor="black"
                      mr={5}
                    />
                    Send at once.
                  </Flex>
                  <Input
                    type="email"
                    name="to"
                    placeholder="To"
                    value={emailData.to}
                    onChange={handleChange}
                    borderColor="black"
                    isRequired
                    multiple
                    mb={7}
                    mt={7}
                  />
                  <Input
                    type="email"
                    name="cc"
                    placeholder="CC"
                    value={emailData.cc}
                    onChange={handleChange}
                    borderColor="black"
                    multiple
                    mb={7}
                  />
                  <Input
                    type="email"
                    name="bcc"
                    placeholder="BCC"
                    value={emailData.bcc}
                    onChange={handleChange}
                    borderColor="black"
                    multiple
                    mb={7}
                  />
                  <Input
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    value={emailData.subject}
                    onChange={handleChange}
                    borderColor="black"
                    isRequired
                    mb={7}
                  />
                  <Checkbox
                    isChecked={immediate}
                    onChange={(e) => setImmediate(e.target.checked)}
                  >
                    Schedule
                  </Checkbox>
                  <br />
                  {immediate && (
                    <>
                      <label htmlFor="scheduledTime">Scheduled Time:</label>
                      <br />
                      <Box
                        mb={7}
                        style={{
                          width: "1000px",
                          color: "black",
                          fontWeight: "bold",
                          marginBottom: "30px",
                          borderColor: "black",
                        }}
                      >
                        <DatePicker
                          selected={scheduledTime}
                          onChange={(date) => {
                            setScheduledTime(date);
                            setIsSchedule(true);
                            setImmediate(true);
                          }}
                          showTimeSelect
                          timeFormat="h:mm aa"
                          timeIntervals={15}
                          dateFormat="MMMM d, yyyy h:mm aa"
                          placeholderText="Select date and time"
                          minDate={new Date()}
                        />
                      </Box>
                    </>
                  )}
                  <Box>
                    {DesginExsits
                      ? "You have Already Selected your Template"
                      : "You are making Template in message"}
                  </Box>
                  <Textarea
                    placeholder="Message"
                    name="message"
                    value={emailData.message}
                    onChange={handleChange}
                    isRequired
                    h="200px"
                    resize="none"
                    overflowY="auto"
                    borderColor="black"
                    mb={7}
                  />
                </form>
              </ModalBody>
              <ModalFooter>
                <Button variant="outline" mr={3} onClick={closePopup}>
                  Close
                </Button>
                <Button
                  type="submit"
                  variant="solid"
                  bg={immediate ? "#0B1EFF" : "green"}
                  color="white"
                  _hover={{ bg: "green.700" }}
                  isLoading={sentLoading}
                  loadingText="Sending"
                  onClick={handleSubmit}
                >
                  {storedUser.isEmailAllowd &&
                    (immediate ? "Schedule Email" : "Send Immediately")}
                  {!storedUser.isEmailAllowd && "Send Approval"}
                </Button>

                <Button variant="outline" mr={3} onClick={SaveTemplate}>
                  Save Template {TempsaveLoding && <Spinner color="red.500" />}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Flex>
      </Box>
    </Layout>
  );
};

export default Editor;
