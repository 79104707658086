import { AppFire } from "components/InstagramComp/Firebase/Firebase";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { toast } from "react-toastify";
import axios from "axios";

export const handlePostSubmission = async (
  setPostLoading,
  postData,
  post,
  setPostData,
  server,
  setDeletedSucess,
  posts,
  scheduleTime,
  addCalendarEvent
) => {
  try {
    setPostLoading(true);

    // Check if a schedule time is provided
    if (scheduleTime) {
      // Get the current time
      const currentTime = new Date();

      // Get the scheduled time
      const scheduledTime = new Date(scheduleTime);

      // Calculate the time difference in milliseconds
      const timeDifference = scheduledTime - currentTime;

      // If the scheduled time is in the future, wait until then
      if (timeDifference > 0) {
        await new Promise((resolve) => setTimeout(resolve, timeDifference));
      }
    }

    // Continue with the rest of your existing code for posting...

    let mediaTypeValue = "";
    let urlEndpoint = "";
    switch (postData.contentType) {
      case "image":
        mediaTypeValue = undefined;
        urlEndpoint = `https://graph.facebook.com/v18.0/${post.businessAccountId}/media?image_url=${post.imageUrl}`;
        break;
      case "reels":
        mediaTypeValue = "REELS";
        urlEndpoint = `https://graph.facebook.com/v18.0/${post.businessAccountId}/media?video_url=${post.imageUrl}`;
        break;
      case "stories":
        mediaTypeValue = "STORIES";
        urlEndpoint = `https://graph.facebook.com/v18.0/${post.businessAccountId}/media?image_url=${post.imageUrl}`;
        break;
      default:
        break;
    }

    const formData = new FormData();
    formData.append("image", postData.image);
    formData.append("caption", postData.caption);

    const createContainerResponse = await axios.post(urlEndpoint, formData, {
      params: {
        caption: postData.caption,
        access_token: post.token,
        media_type: mediaTypeValue,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const creationId = createContainerResponse.data.id;
    if (postData.contentType === "reels") {
      await new Promise((resolve) => setTimeout(resolve, 20000));
    }

    const response = await axios.get(
      `https://graph.facebook.com/v18.0/${creationId}?fields=status_code`,
      {
        params: {
          access_token: post.token,
        },
      }
    );

    await axios.post(
      `https://graph.facebook.com/v18.0/${post.businessAccountId}/media_publish?creation_id=${creationId}&access_token=${post.token}`
    );

    setPostLoading(false);
    setPostData({
      image: null,
      caption: "",
      imageUrl: "",
      contentType: "image",
    });
    toast.success("Posted Successfully!");
  } catch (error) {
    console.log(error);
    setPostLoading(false);
    toast.error(error.message);
  } finally {
    // Continue with the rest of your existing code for cleanup...

    const exist = posts.filter((x) => x.imagename === post.imagename) || [];
    if (exist.length > 1) {
      setTimeout(async () => {
        await DeletePostFromMongodb(
          post,
          server,
          setPostLoading,
          setDeletedSucess
        );
      }, 1000);
    } else {
      setTimeout(async () => {
        const Storagedb = getStorage(AppFire);
        const storageRef = ref(Storagedb, post.imagename);
        // await deleteObject(storageRef);
        await DeletePostFromMongodb(
          post,
          server,
          setPostLoading,
          setDeletedSucess
        );
      }, 1000);
    }
  }
};

const DeletePostFromMongodb = async (
  p,
  server,
  setPostLoading,
  setDeletedSucess
) => {
  try {
    setPostLoading(true);
    const { data } = await axios.delete(
      `${server}/api/scheduledposts/delete/${p._id}`
    );
    if (data) {
      toast.success("Post has been removed from Approved");
      setDeletedSucess(true);
      setPostLoading(false);
    }
  } catch (error) {
    toast.error(error.message);
  }
};
