import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// chakra imports
import {
  Box,
  Flex,
  HStack,
  Icon,
  Text,
  useColorModeValue,
  Collapse,
} from "@chakra-ui/react";
import useOrderStore from "contexts/Userhook";
import { toast } from "react-toastify";
import { Scrollbars } from "react-custom-scrollbars-2";
import { MdEmail } from "react-icons/md";
import { IoShareSocialOutline } from "react-icons/io5";
import { CaretDownFilled } from "@ant-design/icons";
import { FaCaretDown } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
export function SidebarLinks(props) {
  const [mymenu, setmymenu] = useState(false);
  //   Chakra color mode
  // const { storedUser } = useOrderStore();
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  const { routes,containerRef } = props;
  const { logout, storedUser } = useOrderStore();
  // verifies if routeName is the one active (in browser input)
  const navigate = useNavigate();
  const logoutfunc = () => {
    logout();
    toast.success("You have logged out successfully");
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };
  const activeRoute = (routeName) => {
      // console.log(routeName,location.pathname.toLowerCase())
    return location.pathname.toLowerCase().includes(routeName);
  };
  const mymenuFunct = () => {
    console.log("clic");
    setmymenu((pre) => !pre);
    if(containerRef.current){
         const scrollTimeout = setTimeout(() => {
      // console.log(containerRef.current.scrollTop)
      const scrollHeight = containerRef.current?.scrollHeight;
      containerRef.current?.scrollTo({
        top: scrollHeight,
        behavior: "smooth" // This enables smooth scrolling behavior
      });
         },1000)
    }
  };

//  logic
const permissions = 
  {
    "media": true,
    "EmailCampaigns": true,
    "Audiences": true,
    "Emails": true,
    "Editors": true,
    "ApprovePost": true,
    "Calendar": true,
    "Manager":true ,
    "Profile":true ,
    "AllManager":true 
  }
const permissiondata=storedUser.role!=="Admin"?storedUser?.PagesPermission[0]:permissions
const PermissionBased=routes.filter((x)=>{
  if (storedUser.role==="Admin") {
    return true
    
  }
  if (permissiondata[x.layout]||x.layout==="admin") {
       return true
   }else{
    return false
   }

})
// console.log(PermissionBased,"ppi",routes)

  return (
    <>
   
    <Box>
      {PermissionBased&&PermissionBased?.map((route, index) => (
        <NavLink key={index} to={route.path}>
        <HStack>
          <Flex w="100%" alignItems="center" justifyContent="center">
            <Box
              me="18px"
              color={
                activeRoute(route.path.toLowerCase()) ? activeIcon : textColor
              }
            >
              {route.icon}
            </Box>

            <Box
              me="auto"
              py="5px"
              ps="10px"
              color={
                activeRoute(route.path.toLowerCase())
                  ? activeColor
                  : inactiveColor
              }
              fontWeight={
                activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
              }
            >
              {route.name}
            </Box>
          </Flex>
          <Box
            h="36px"
            w="4px"
            bg={
              activeRoute(route.path.toLowerCase())
                ? brandColor
                : "transparent"
            }
            borderRadius="5px"
          />
        </HStack>
      </NavLink>
      ))}
      {
        permissiondata["media"]&&(

      <Box>
        <HStack display="flex" flexDirection={"column"}>
          <Flex w="100%" alignItems="center" justifyContent="center">
            <Box
              cursor="pointer"
              me="18px"
              color={activeRoute("/socail") ? activeIcon : textColor}
            >
              <Icon
                as={IoShareSocialOutline}
                width="20px"
                height="20px"
                color="inherit"
              />
            </Box>

            <Box
              me="auto"
              py="5px"
              ps="10px"
              color={activeRoute("/Social") ? activeColor : inactiveColor}
              fontWeight={activeRoute("/Social") ? "bold" : "normal"}
              cursor={"pointer"}
              onClick={() =>{
                if(storedUser.role!=="manager") navigate("/Social")
              }}
            >
              Social &nbsp; &nbsp;
              <Icon
                as={FaCaretDown}
                width="20px"
                height="20px"
                color="inherit"
                onClick={mymenuFunct}
              />
            </Box>
          </Flex>
          {/* <Box h="36px" w="4px" borderRadius="5px" /> */}

          <Collapse
            in={mymenu}
            style={{ maxHeight: "200px", overflow: "scroll" }}
          >
            <Box
              bg="blue.100"
              p="2"
              mt="2"
              borderRadius="5px"
              onClick={() => navigate("/tweet")}
            >
            Twitter <Icon as={FaXTwitter} width="20px" height="20px" color="#8F9BBA" /></Box>
            <Box
              bg="blue.100"
              p="2"
              mt="2"
              borderRadius="5px"
              onClick={() => navigate("/connectedPages")}
            >
              Connected Pages
            </Box>
            <Box
              bg="blue.100"
              p="2"
              mt="2"
              borderRadius="5px"
              onClick={() => navigate("/connectfbpage")}
            >
              Connect Fb pages
            </Box>
            <Box
              bg="blue.100"
              p="2"
              mt="2"
              borderRadius="5px"
              onClick={() => navigate("/connectInstapage")}
            >
              Connect Insta pages
            </Box>
            <Box
              bg="blue.100"
              p="2"
              mt="2"
              borderRadius="5px"
              onClick={() => navigate("/Instapages")}
            >
              instagram posts
            </Box>
            <Box
              bg="blue.100"
              p="2"
              mt="2"
              borderRadius="5px"
              onClick={() => navigate("/Facebookpages")}
            >
              facebook posts
            </Box>
            <Box
              bg="blue.100"
              p="2"
              mt="2"
              borderRadius="5px"
              onClick={() => navigate("/AllinOne")}
            >
              All media
            </Box>
          </Collapse>
        </HStack>
        {/* <Box bg={"blue"}>
        <HStack>
          <Flex w="100%" alignItems="center" justifyContent="center">
            <Box onClick={logoutfunc} cursor="pointer" me="18px">
              <Icon as={MdEmail} width="20px" height="20px" color="inherit" />
            </Box>

            <Box me="auto" py="5px" ps="10px">
              Logout &nbsp;&nbsp;{storedUser.name}
            </Box>
          </Flex>
          <Box h="36px" w="4px" borderRadius="5px" />
        </HStack>

        </Box> */}
      </Box>
        )

      }
    </Box>
     
        </>
  );
}

export default SidebarLinks;