import axios from "axios";
import useOrderStore from "contexts/Userhook";
import React, { useEffect, useState } from "react";
import { FaThumbsUp } from "react-icons/fa";
import { toast } from "react-toastify";

const Likes = ({ post, token }) => {
  const [likecount, setlikecount] = useState(0);
  const [isLiked, setisLiked] = useState(false);

  const Serverurl = process.env.REACT_APP_BACKEND_URL;
  //  "https://graph.facebook.com/v18.0/id/likes?access_token="
  const { storedUser } = useOrderStore();

  useEffect(() => {
    const opencomment = async () => {
      let url=storedUser.role==="manager"?`${Serverurl}/fb/FBToken/mydata/${storedUser.roleby}`:`${Serverurl}/fb/FBToken/mydata/${storedUser._id}`
      const { data: mydata } = await axios.get(
        url
        // `${Serverurl}/fb/FBToken/mydata/${storedUser._id}`
      );
      const { data } = await axios(
        `https://graph.facebook.com/v18.0/${post.id}/reactions?access_token=${token}`
      );
      if (data) {
        let islikeuser = data?.data?.some((x) => x.name === mydata.name);
        // console.log(islikeuser, mydata, data);

        setisLiked(islikeuser);
        setlikecount(data?.data?.length);
      }
    };
    opencomment();
  }, [Serverurl, post.id, token]);

  const mylikeFunction = async () => {
    if (!isLiked) {
      console.log("liked");
      try {
        const { data } = await axios.post(
          `https://graph.facebook.com/v18.0/${post.id}/likes?access_token=${token}`
        );
        if (data) {
          setlikecount((prev) => prev + 1);
          setisLiked(true);
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      setisLiked(false);
      // console.log("disliked");
      try {
        const { data } = await axios.delete(
          `https://graph.facebook.com/v18.0/${post.id}/likes?access_token=${token}`
        );
        if (data) {
          setlikecount((prev) => prev - 1);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span
        style={{ marginRight: "5px", cursor: "pointer" }}
        onClick={mylikeFunction}
      >
        <FaThumbsUp className={isLiked ? "liked" : "disliked"} />
      </span>
      {"  "}
      {likecount}
    </div>
  );
};

export default Likes;
