import axios from "axios";
import { toast } from "react-toastify";
import { useCalendar } from "../../context/CalendarContext";

import { AppFire } from "../../InstagramComp/Firebase/Firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const server = process.env.REACT_APP_BACKEND_URL;
export const Fbpage = async (
  ServerUrl,
  setdataloading,
  storedUser,
  gettingpageapi
) => {
  let url=storedUser.role==="manager"?`${ServerUrl}/fb/FBToken/mydata/${storedUser.roleby}`:`${ServerUrl}/fb/FBToken/mydata/${storedUser._id}`
  try {
    const { data } = await axios.get(
      url
      // `${ServerUrl}/fb/FBToken/mydata/${storedUser._id}`
    );
    if (data) {
      //   console.log(data);
      setdataloading(false);
      toast.success(
        data?.name + "  " + "     wait until we are getting your pages",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      gettingpageapi(data.facebookdata.userID, data.access_token);
    }
  } catch (error) {
    toast.error(error.message);
    setdataloading(false);
  }
};
const uploadFileToFirebase = async (file, folder) => {
  const storage = getStorage(AppFire);
  const storageRef = ref(storage, `${file.name}`);
  await uploadBytes(storageRef, file);
  const downloadURL = await getDownloadURL(storageRef);
  return downloadURL;
};
const saveToMongoDB = async (
  imageDownloadURL,
  videoDownloadURL,
  imageName,
  videoName,
  contentName,
  message,
  FbPageIds,
  scheduledTime,
  userdata
) => {
  try {
    for (const FbPageId of FbPageIds) {
      console.log(FbPageId);
      const response = await axios.post(`${server}/api/scheduledpost`, {
        imageUrl: imageDownloadURL || videoDownloadURL,
        caption: message,
        socialPlatform: "Facebook",
        token: FbPageId.access_token,
        imagename: imageName || videoName,
        contentType: contentName,
        scheduledTime: scheduledTime,
        user:userdata._id,
        Admin:userdata.roleby
      });
      console.log("Data saved to MongoDB:", response.data);
    }
  } catch (error) {
    console.error("Error saving data to MongoDB:", error);
  }
};
// Posting api
export const Posting = async (
  setpostloading,
  postdata,
  FbPageIds,
  setpostdata,
  addCalendarEvent,
  isManager,
  userdata,
) => {
  try {
    console.log("AAAAAAAAAAAA", isManager);
    console.log("AAAAAAAAAAAA", FbPageIds);
    if (isManager) {
      let imageName = postdata?.image?.name;
      const imageDownloadURL = postdata.image
        ? await uploadFileToFirebase(postdata.image, "images")
        : null;
      const videoDownloadURL = postdata.video
        ? await uploadFileToFirebase(postdata.video, "videos")
        : null;

      console.log("AAAAAAAAAAAAAAAAA", imageName);
      console.log("AAAAAAAAAAAAAAAAAA", postdata?.image);
      let videoName = postdata?.video?.name;

      const contentName = postdata.contentType;
      const message = postdata.text;
      const scheduledTime = postdata.scheduleTime;
      saveToMongoDB(
        imageDownloadURL,
        videoDownloadURL,
        imageName,
        videoName,
        contentName,
        message,
        FbPageIds,
        scheduledTime,
        userdata
      );
    } else {
      setpostloading(true);
      const formData = new FormData();
      formData.append("message", postdata.text);

      if (postdata.scheduleTime) {
        formData.append(
          "scheduled_publish_time",
          Math.floor(postdata.scheduleTime.getTime() / 1000)
        );
        formData.append("published", "false");

        addCalendarEvent({
          title: `Posting Scheduled to Facebook`,
          start: postdata.scheduleTime,
          end: postdata.scheduleTime,
        });
      }
      if (postdata.image) {
        if (FbPageIds.length === 0)
          return toast.error("kindly Select a your Desire Posting Place");
        formData.append("source", postdata.image);

        for (const i of FbPageIds) {
          const { data } = await axios.post(
            `https://graph.facebook.com/v18.0/me/photos?access_token=${i.access_token}`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
        }

        // if (data) {
        setpostloading(false);
        setpostdata({
          text: "",
        });
        document.getElementById("file").value = "";
        toast.success("Posted Successfully");
        // }

        return;
      } else if (postdata.text.length > 0 && !postdata.video) {
        if (FbPageIds.length === 0)
          return toast.error("kindly Select a your Desire Posting Place");
        console.log("not pic", formData);
        for (const i of FbPageIds) {
          const { data } = await axios.post(
            `https://graph.facebook.com/v18.0/me/feed?access_token=${i.access_token}`,
            formData
          );
        }

        setpostloading(false);
        setpostdata({
          text: "",
        });
        document.getElementById("file").value = "";
        toast.success("Posted Successfully");
        // }
        return;
      }

      if (postdata.video) {
        formData.append("message", postdata.text);
        formData.append("source", postdata.video);
        formData.append("title", postdata.text);
        formData.append("description", postdata.text);
        console.log("not pic", Array.from(formData));
        if (FbPageIds.length === 0)
          return toast.error("kindly Select a your Desire Posting Place");
        console.log("not pic", formData);
        for (const i of FbPageIds) {
          const { data } = await axios.post(
            `https://graph.facebook.com/v18.0/me/videos/?access_token=${i.access_token}`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
        }

        setpostloading(false);
        setpostdata({
          text: "",
        });
        document.getElementById("file").value = "";
        toast.success("Posted Successfully");

        return;
      }
    }
  } catch (error) {
    setpostloading(false);
    toast.error(error.message);
  }
};
