import axios from "axios";
import React, { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { useCalendar } from "../context/CalendarContext";
import "react-datepicker/dist/react-datepicker.css";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { AppFire } from "./Firebase/Firebase";
import useOrderStore from "../../contexts/Userhook";
const server = process.env.REACT_APP_BACKEND_URL;
export const PublishPost = ({ publishData }) => {
  const { token, businessAccountId, pageID } = publishData;

  const { storedUser } = useOrderStore();
  console.log(storedUser, "user");
  // const isManager = storedUser && storedUser.role === "manager";
  const isManager = storedUser && (storedUser.role === "manager"&&!storedUser.socailpermission);
  const [postData, setPostData] = useState({
    image: null,
    caption: "",
    imageUrl: "",
    contentType: "image",
  });

  const [scheduleDate, setScheduleDate] = useState(null);

  const [postLoading, setPostLoading] = useState(false);
  const { addCalendarEvent } = useCalendar();
  const resetState = () => {
    setPostData({
      image: null,
      caption: "",
      imageUrl: "",
      contentType: "image",
    });
    setScheduleDate(null);
    setPostLoading(false);
    const formElement = document.getElementById("postForm");
    formElement.reset();
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (
      e.target.value === "stories" ||
      e.target.value === "reels" ||
      e.target.value === "image"
    ) {
      setPostData({ ...postData, contentType: e.target.value });
    }

    if (e.target.files && e.target.files.length > 0) {
      let videoexist = e.target.files[0]["type"].includes("video/mp4");

      if (videoexist) {
        if (postData.contentType === "reels") {
          setPostData({
            ...postData,
            image: e.target.files[0],
            contentType: "reels",
          });
        }
      }

      if (postData.contentType === "image") {
        setPostData({
          ...postData,
          image: e.target.files[0],
          contentType: "image",
        });
      }

      if (postData.contentType === "stories") {
        setPostData({
          ...postData,
          image: e.target.files[0],
          contentType: "stories",
        });
      }
    } else {
      setPostData({ ...postData, [name]: value });
    }
  };

  const handleDateChange = (date) => {
    setScheduleDate(date);
  };

  const handleSubmitUrl = async (e) => {
    e.preventDefault();

    if (!isManager && scheduleDate && scheduleDate > new Date()) {
      resetState();
      if (scheduleDate) {
        console.log("Schedule Post======", scheduleDate);
        addCalendarEvent({
          title: `Posting to Instagram`,
          start: scheduleDate,
          end: scheduleDate,
        });
      }
      setTimeout(() => {
        handlePostSubmission();
      }, scheduleDate - new Date());
      toast.success("Post scheduled successfully!");
    } else {
      await handlePostSubmission();
      resetState();
    }
  };
  const saveToMongoDB = async (downloadURL, contentType, name) => {
    try {
      const response = await axios.post(`${server}/api/scheduledpost`, {
        imageUrl: downloadURL,
        caption: postData.caption,
        userid:storedUser._id,
        Admin:storedUser.roleby,
        contentType: contentType,
        businessAccountId: businessAccountId,
        pageID: pageID,
        socialPlatform: "Instagram",
        token: token,
        imagename: name,
        scheduledTime: scheduleDate,
      });
      console.log("Data saved to MongoDB:", response.data);
    } catch (error) {
      console.error("Error saving data to MongoDB:", error);
    }
  };

  const handlePostSubmission = async () => {
    const Storagedb = getStorage(AppFire);
    const storageRef = ref(Storagedb);

    const fileRef = ref(storageRef, `${postData.image.name}`);
    await uploadBytes(fileRef, postData.image);
    const downloadURL = await getDownloadURL(fileRef);
    if (downloadURL) {
      const contentType = postData.contentType;
      if (isManager) {
        setPostLoading(true);
        saveToMongoDB(downloadURL, contentType, postData.image.name);
        setPostLoading(false);
        toast.success("Send To Admin Succesfully");
        return;
      }

      try {
        setPostLoading(true);
        let mediaTypeValue = "";
        let urlEndpoint = "";
        switch (postData.contentType) {
          case "image":
            mediaTypeValue = undefined;
            urlEndpoint = `https://graph.facebook.com/v18.0/${businessAccountId}/media?image_url=${downloadURL}`;
            break;
          case "reels":
            mediaTypeValue = "REELS";
            urlEndpoint = `https://graph.facebook.com/v18.0/${businessAccountId}/media?video_url=${downloadURL}`;
            break;
          case "stories":
            mediaTypeValue = "STORIES";
            urlEndpoint = `https://graph.facebook.com/v18.0/${businessAccountId}/media?image_url=${downloadURL}`;
            break;
          default:
            break;
        }

        const formData = new FormData();
        formData.append("image", postData.image);
        formData.append("caption", postData.caption);

        const createContainerResponse = await axios.post(
          urlEndpoint,
          formData,
          {
            params: {
              caption: postData.caption,
              access_token: token,
              media_type: mediaTypeValue,
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const creationId = createContainerResponse.data.id;
        if (postData.contentType === "reels") {
          await new Promise((resolve) => setTimeout(resolve, 20000));
        }

        const response = await axios.get(
          `https://graph.facebook.com/v18.0/${creationId}?fields=status_code`,
          {
            params: {
              access_token: token,
            },
          }
        );

        await axios.post(
          `https://graph.facebook.com/v18.0/${businessAccountId}/media_publish?creation_id=${creationId}&access_token=${token}`
        );

        setPostLoading(false);
        setPostData({
          image: null,
          caption: "",
          imageUrl: "",
          contentType: "image",
        });
        toast.success("Posted Successfully!");
        window.location.reload()
      } catch (error) {
        console.log(error);
        setPostLoading(false);
        toast.error(error.message);
      } finally {
        console.log(fileRef, "ref");
        setTimeout(async () => {
          await deleteObject(fileRef);
        }, 1000);
      }
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white p-8 rounded-md shadow-md">
      <form id="postForm">
        <h2 className="text-2xl font-semibold mb-4">Post to Instagram</h2>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Select Content Type
          </label>
          <select
            id="contentType"
            name="contentType"
            onChange={handleInputChange}
            value={postData.contentType}
            className="p-2 border border-gray-300 rounded-md w-full"
          >
            <option value="image">Image</option>
            <option value="reels">Reels</option>
            <option value="stories">Stories</option>
          </select>
        </div>

        {postData.image && (
          <div className="mb-4">
            <img
              src={URL.createObjectURL(postData.image)}
              alt="Uploaded"
              className="mb-2 rounded-md"
              style={{ maxWidth: "100%", maxHeight: "400px" }}
            />
          </div>
        )}

        <div className="mb-4">
          <label
            htmlFor="imageUrl"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Select the file
          </label>

          <input
            type="file"
            id="image"
            name="image"
            onChange={handleInputChange}
            className="p-2 border border-gray-300 rounded-md w-full"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="caption"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Caption
          </label>
          <input
            type="text"
            id="caption"
            name="caption"
            onChange={handleInputChange}
            value={postData.caption}
            placeholder="Enter caption..."
            className="p-2 border border-gray-300 rounded-md w-full"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="scheduleDate"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Schedule Date and Time
          </label>
          <DatePicker
            id="scheduleDate"
            selected={scheduleDate}
            onChange={handleDateChange}
            showTimeSelect
            timeFormat="h:mm aa"
            timeIntervals={15}
            dateFormat="MMMM d, yyyy h:mm aa"
            className="p-2 border border-gray-300 rounded-md w-full"
            placeholderText="Select date and time"
            minDate={new Date()}
          />
        </div>

        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
          onClick={handleSubmitUrl}
        >
          {isManager ? (
            <>
              Send for Approval
              {"  "}
              {postLoading && <FaSpinner />}
            </>
          ) : (
            <>
              Post
              {"  "}
              {postLoading && <FaSpinner />}
            </>
          )}
        </button>
      </form>
    </div>
  );
};
