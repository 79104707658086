import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  VStack,
  Spinner,
  Icon,
  Heading,
} from "@chakra-ui/react";
import Layout from "layouts/Layout/Layout";
import axios from "axios";
import { toast } from "react-toastify";
import useOrderStore from "contexts/Userhook";
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";

const serverURL = process.env.REACT_APP_BACKEND_URL;
const UserProfileSettings = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [Changeloading, setChangeloading] = useState(false);
  const { storedUser, login } = useOrderStore();
  const [changesucss, setchangesucss] = useState(false);
  const [show, setshow] = useState(false);

  const handleClick = () => {
    setshow(!show);
  };
  useEffect(() => {
    const profileData = async () => {
      try {
        const { data } = await axios.get(
          `${serverURL}/auth/profiledata/${storedUser._id}`
        );
        if (data) {
          setUsername(data.name);
          setEmail(data.email);
          setchangesucss(false);
        }
        console.log("DATA==========>", data);
        // login({ userdata: { data } });
      } catch (error) {
        let err =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        toast.error(err);
      }
    };
    profileData();
  }, [storedUser._id, changesucss]);

  const handleSave = async () => {
    try {
      setChangeloading(true);
      if (!currentPassword.trim()) {
        toast.error("Please enter current password");
        setChangeloading(false);
        return;
      }
      if (newPassword !== confirmPassword) {
        toast.error("New password and confirm password do not match");
        setChangeloading(false);
        return;
      }

      const { data } = await axios.post(`${serverURL}/auth/profilesetting`, {
        username,
        email,
        currentPassword,
        newPassword,
        userid: storedUser._id,
      });

      if (data.userData) {
        toast.success(data.message);
        setChangeloading(false);
        setchangesucss(true);
        console.log(data, "kk");
        login({ userdata: data.userData });
      }
    } catch (error) {
      let err =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      setChangeloading(false);
      toast.error(err);
    }
  };

  return (
    <Layout>
      <Heading
        fontWeight="bold"
        fontSize={30}
        textAlign="center"
        mb={7}
        color="#0B1EFE"
      >
        PROFILE SETTINGS
      </Heading>
      <VStack spacing={4} align="flex-start" maxW="400px" m="auto">
        <FormControl>
          <FormLabel color="#0B1EFE" fontWeight="bold" fontSize={20}>
            Username
          </FormLabel>
          <Input
            type="text"
            placeholder="Enter your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <FormHelperText>Choose a unique username.</FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel color="#0B1EFE" fontWeight="bold" fontSize={20}>
            Email
          </FormLabel>
          <Input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <FormHelperText>
            We'll never share your email with anyone else.
          </FormHelperText>
        </FormControl>

        <FormControl position={"relative"}>
          <FormLabel color="#0B1EFE" fontWeight="bold" fontSize={20}>
            Current Password
          </FormLabel>
          <Input
            type={show ? "text" : "password"}
            placeholder="Enter your password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <Icon
            position="absolute"
            right="10px"
            top="50%"
            transform="translateY(-50%)"
            cursor="pointer"
            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
            onClick={handleClick}
          />

          <FormHelperText>Choose a strong password.</FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel color="#0B1EFE" fontWeight="bold" fontSize={20}>
            New Password
          </FormLabel>
          <Input
            type="password"
            placeholder="Enter new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <Icon
            position="absolute"
            right="10px"
            top="70%"
            transform="translateY(-50%)"
            cursor="pointer"
            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
            onClick={handleClick}
          />
        </FormControl>

        <FormControl>
          <FormLabel color="#0B1EFE" fontWeight="bold" fontSize={20}>
            Confirm Password
          </FormLabel>
          <Input
            type="password"
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Icon
            position="absolute"
            right="10px"
            top="70%"
            transform="translateY(-50%)"
            cursor="pointer"
            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
            onClick={handleClick}
          />
        </FormControl>

        <Button colorScheme="blue" onClick={handleSave}>
          Save Changes
          {Changeloading && <Spinner color="red" />}
        </Button>
      </VStack>
    </Layout>
  );
};

export default UserProfileSettings;
