
import { Button } from '@chakra-ui/react';
import axios from 'axios';
import { Fbpage } from 'components/AllScocail/FbAllcomponenet/FbAll';
import { ConnectedFbPgeApi } from 'components/Facebook/Connectedpageapi/ConnectedfbpageApi';
import useOrderStore from 'contexts/Userhook';
import Layout from 'layouts/Layout/Layout'
import React, { useCallback, useEffect, useState } from 'react'
import { FaInstagram, FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';
const ServerUrl = process.env.REACT_APP_BACKEND_URL;
const AllconnectedPagesHome = () => {
    const { storedUser } = useOrderStore();
    const [dataloading, setdataloading] = useState(false);
    const [pagesloading, setpagesloading] = useState(false);
    const [allpages, setallpages] = useState([]);
    const [allConnectedpages, setallConnectedpages] = useState([]);
    const [ConnectingPageLoading, setConnectingPageLoading] = useState(false);
     const [FbPageIds, setFbPageIds] = useState([]);

 

    

      useEffect(() => {
        
        const mypages=async()=>{
            let data= await ConnectedFbPgeApi(ServerUrl,storedUser,setdataloading)
            if (data.length>0) {
           
                   setallpages(data);
                
            }
            }
    
             mypages()
     

      }, [storedUser]);
    const CheckBox = (x) => {
      
        const Exist = FbPageIds?.find((y) => y.id === x.id);
        if (Exist) {
          setFbPageIds((p) => {
            return p.filter((z) => z.id !== x.id);
          });
        } else {
          setFbPageIds((p) => {
            return [...p, x];
          });
        }
      
     
    };

console.log(FbPageIds,"st")
const Connect=async()=>{
  try {
      setConnectingPageLoading(true)
      const data=await axios.post(
          `${ServerUrl}/fb/pageremove`,{ConnectedPage:FbPageIds,user:storedUser._id}
        );
        if (data) {
          toast.success("deleted")
        }
      
  } catch (error) {
    toast.error(error.message)
      
  }
    
 

    }
  return (
    <Layout>
    {dataloading && <FaSpinner className="mx-auto mt-4 text-4xl" />}
    <div className="text-center text-4xl font-bold mb-4">Connect the Pages</div>
    {pagesloading && <FaSpinner className="mx-auto mt-4 text-4xl" />}
    <Button onClick={Connect} disabled={FbPageIds.length>0?false:false} bg={"blue.500"}>Delete</Button>
    <div className="flex flex-wrap mx-5">
      {allpages.length > 0
        ? allpages?.map((x) => (
            <div
              key={x.id}
              className="mr-10 my-5 p-5 w-72 border-2 border-black h-[80px] flex  "
            >
              {/* <Input type='checkbox'></Input> */}
              <input
                type="checkbox"
                className="mr-[10px]"
                onClick={() => CheckBox(x)}
              ></input>
              <ul className="flex">
                  {x?.Instagram ?(
                    <FaInstagram className="mb-2 mr-[15px]"></FaInstagram>
                  ) : (
                    <li className="mb-2 mr-[15px]">
                      <img
                        src={x?.cover?.source}
                        alt="coverphoto"
                        className="mb-4 h-[50px] w-[50px] "
                      />
                    </li>
                  )}
                  {/* <li className="mb-2"> {x.instagram_business_account&&<CgInstagram/>}</li> */}
                  <li className="mb-2"> {x.name}</li>
                </ul>
            </div>
          ))
        : "No Pages are Available"}
    </div>

    
  </Layout>
  )
}

export default AllconnectedPagesHome