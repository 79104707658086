import React, { useState } from "react";
import { FaSpinner, FaThumbsUp } from "react-icons/fa";
import InstaInbox from "./InstaInbox";
import { Box, Flex, Image, Text, Button, Icon } from "@chakra-ui/react";
import { MdMessage } from "react-icons/md";
import useOrderStore from "../../contexts/Userhook";

const InstaPosts = ({ currentpagePosts, instaPost, instaPostLoading }) => {
  const { storedUser } = useOrderStore();
  console.log(storedUser, "in post",instaPost.posts);
  // const isManager = storedUser && storedUser.role === "manager";
  const isManager = storedUser && (storedUser.role === "manager"&&!storedUser.socailpermission);

  const [showCommentsMap, setShowCommentsMap] = useState({});
  const [showInbox, setShowInbox] = useState(false);
  const openPost = (x) => {
    window.open(x, "_blank");
  };
  const handleToggleInbox = () => {
    setShowInbox(!showInbox);
  };
  const MediaData = (x) => {
    if (x?.media_type === "IMAGE") {
      return (
        <Image
          borderRadius="10px"
          margin="10px"
          width="100%"
          maxHeight="200px"
          objectFit="cover"
          src={x?.media_url}
          alt="profile"
        />
      );
    } else if (x?.media_type === "VIDEO") {
      return (
        <video
          src={x?.media_url}
          controls
          style={{
            borderRadius: "10px",
            margin: "10px",
            width: "100%",
            maxHeight: "200px",
            objectFit: "cover",
          }}
        />
      );
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const handleToggleComments = (postId) => {
    setShowCommentsMap((prevMap) => ({
      ...prevMap,
      [postId]: !prevMap[postId],
    }));
  };

  // if (isManager) {
  //   return (
  //     <div
  //       style={{
  //         textAlign: "center",
  //         fontSize: "30px",
  //         color: "black",
  //         fontWeight: "bolder",
  //       }}
  //     >
  //       You Are A Manager. Insatgram Posts Are Not Visible.
  //     </div>
  //   );
  // }

  return (
    <>
      {instaPostLoading && <FaSpinner />}

      <Box
        display="flex"
        flexDirection="column"
        overflowX="auto"
        padding="20px"
      >
        <Flex flexDirection="column" marginBottom="20px">
          {/* Button with message icon */}
          <Button
            size="sm"
            variant="solid"
            colorScheme="teal" // Set your desired color
            leftIcon={<Icon as={MdMessage} />}
            onClick={handleToggleInbox}
            marginBottom="10px"
            alignSelf="flex-start"
          >
            {showInbox ? "Hide Messages" : "Show Messages"}
          </Button>

          {/* InstaInbox component */}
          {showInbox && <InstaInbox currentpagePosts={currentpagePosts} />}
        </Flex>
        {instaPost?.posts?.length >= 0 ? (
          <>
            <Flex
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-between"
            >
              {instaPost?.posts?.map((x) => (
                <Box
                  key={x.id}
                  width="300px"
                  minHeight="400px"
                  borderRadius="10px"
                  border="2px solid #ddd"
                  overflow="hidden"
                  marginBottom="20px"
                  backgroundColor="white"
                  transition="transform 0.2s"
                  _hover={{ transform: "scale(1.05)" }}
                >
                  <Box padding="15px">
                    <Text fontWeight="bold" fontSize="lg">
                      {x?.username}
                    </Text>
                    <Text
                      color="blue.500"
                      fontSize="sm"
                      cursor="pointer"
                      onClick={() => openPost(x.permalink)}
                    >
                      View Post
                    </Text>
                    {MediaData(x)}
                    <Text fontSize="md" marginTop="10px">
                      {x?.caption}
                    </Text>
                    <Flex alignItems="center" marginTop="5px">
                      <FaThumbsUp color="blue" />
                      <Text marginLeft="2px" fontSize="sm">
                        {x?.like_count}
                      </Text>
                    </Flex>
                    <Text fontSize="sm" marginTop="5px">
                      Total Comments: {x?.comments_count}
                    </Text>
                    <Button
                      mt="3"
                      size="sm"
                      onClick={() => handleToggleComments(x.id)}
                      colorScheme="teal"
                    >
                      {showCommentsMap[x.id]
                        ? "Hide Comments"
                        : "View Comments"}
                    </Button>
                    {showCommentsMap[x.id] && (
                      <Box marginTop="10px">
                        {x?.comments?.data?.map((comment) => (
                          <Box
                            key={comment.id}
                            marginBottom="5px"
                            border="1px solid #ddd"
                            padding="10px"
                            borderRadius="5px"
                          >
                            <Text fontWeight="bold">{comment.username}:</Text>{" "}
                            {comment.text}
                            <Flex alignItems="center" marginTop="3px">
                              <FaThumbsUp />
                              <Text marginLeft="2px">{comment.like_count}</Text>
                            </Flex>
                            <Text fontWeight="bold" marginTop="3px">
                              Time:
                            </Text>{" "}
                            {formatTimestamp(comment.timestamp)}
                            <Box marginTop="5px" marginLeft="20px">
                              {comment?.replies?.data?.map((reply) => (
                                <Box
                                  key={reply.id}
                                  marginBottom="5px"
                                  border="1px solid #ddd"
                                  padding="10px"
                                  borderRadius="5px"
                                  marginLeft="20px"
                                >
                                  <Text fontWeight="bold">Replies:</Text>{" "}
                                  <Text fontWeight="bold">
                                    {reply.username}
                                  </Text>{" "}
                                  {reply.text}
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}
            </Flex>
          </>
        ) : (
          <Text fontSize="lg" textAlign="center">
            Click on Page to see Instagram posts connected to your business
            account
          </Text>
        )}
      </Box>
    </>
  );
};

export default InstaPosts;
