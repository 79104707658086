import { toast } from "react-toastify";

export const exportHtml = async (emailEditorRef) => {
    // update auto

    //

    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      let alldesign = JSON.stringify(design, null, 4);
      const element = document.createElement("a");
      const file = new Blob([alldesign], {
        type: "text/plain",
      });
      element.href = URL.createObjectURL(file);
      element.download = `myFile.txt`;
      document.body.appendChild(element);
      element.click();

      

      toast.success("Your html Code has downloaded");
    });
  };