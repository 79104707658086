import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  Text,
  Flex,
  VStack,
  Button,
  ChakraProvider,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import Layout from "layouts/Layout/Layout";
import { handlePostSubmission } from "./Instaposting";
import { handlePostSubmissionFb } from "./Facebookposting";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useCalendar } from "../context/CalendarContext";
import useOrderStore from "contexts/Userhook";
import { set } from "js-cookie";
import { Badge } from "antd";
import { toast } from "react-toastify";
const server = process.env.REACT_APP_BACKEND_URL;

const ApprovePosts = () => {
  const [posts, setPosts] = useState([]);
  const [imageData, setImageData] = useState(null);

  const [postLoading, setPostLoading] = useState(false);
  const [DeletedSucess, setDeletedSucess] = useState(false);
  const [emailposted, setEmailposted] = useState(true);
  const [emailLoading, setemailLoading] = useState(false);

  const { addCalendarEvent } = useCalendar();
  const [postData, setPostData] = useState({
    image: null,
    caption: "",
    imageUrl: "",
    contentType: "image",
  });

  const [scheduleTimes, setScheduleTimes] = useState([]);
  const [EmailApporve, setEmailApporve] = useState([]);
  const { storedUser } = useOrderStore();
  useEffect(() => {
    axios
      .get(`${server}/api/scheduledposts/${storedUser._id}`)
      .then((response) => {
        console.log("aaaaaaaaaaaaaaaa", response.data);
        setPosts(response.data);

        setScheduleTimes(response.data.map(() => null));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [DeletedSucess, storedUser._id]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${server}/twitter/Tweetermanageposts/${storedUser._id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch image data");
        }
        const data = await response.json();
        console.log("Data=====>", data);
        setImageData(data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [storedUser._id]);

  // email fetch api

  console.log(EmailApporve, "ye");

  const handleButtonClick = (post, index) => {
    setDeletedSucess(false);
    console.log(post, scheduleTimes[index]);

    if (post.socialPlatform === "Instagram") {
      handlePostSubmission(
        setPostLoading,
        postData,
        post,
        setPostData,
        server,
        setDeletedSucess,
        posts,
        scheduleTimes[index],
        addCalendarEvent
      );
    } else if (post.socialPlatform === "Facebook") {
      handlePostSubmissionFb(
        setPostLoading,
        postData,
        post,
        setPostData,
        server,
        setDeletedSucess,
        posts,
        scheduleTimes[index],
        addCalendarEvent
      );
    }
  };
  const handlePostTwitter = async (tweetText, tweetImage, image) => {
    try {
      console.log("Tweet image=========>", tweetImage);
      const formData = new FormData();
      if (tweetImage) {
        const blob = new Blob([tweetImage.buffer], {
          type: tweetImage.mimetype,
        });
        const file = new File([blob], tweetImage.originalname, {
          type: tweetImage.mimetype,
        });
        formData.append("image", file);
      }
      formData.append("tweetText", tweetText);
      formData.append("token", image.token);
      formData.append("tokenSecret", image.tokenSecret);
      formData.append("userid", storedUser._id);

      if (storedUser.role === "manager") {
        formData.append("Admin", storedUser.roleby);
        formData.append("isManager", true);
      } else {
        formData.append("isManager", false);
      }
      console.log("chl raha");
      const { data } = await axios.post(
        `${server}/twitter/post-tweet`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          // ...TweetData,
        }
      );
      if (data) {
        // setTweetText("");
        // setTweetImage(null);
        // toast.success(data);
      }
    } catch (error) {
      // toast.error(error.message);
    }
  };

  const handleEmailApprove = async (x) => {
    let to = x.Recipients.join(",");

    try {
      setemailLoading(true);
      const response = await axios.post(`${server}/recipient/send-email`, {
        ...x,
        audienceid: x.targetedAudeince,
        user: x.user._id,
        isEmailAllowd: storedUser.isEmailAllowd,
        to: to,
        fromaudi: x.fromaudimember,
        fromApprove: "yes",
      });
      if (response) {
        setTimeout(() => {
          toast.success("Email Sent Successfully");
          setEmailposted(true);
          setemailLoading(false);
        }, 2000);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    console.log(emailposted, "fetch");
    if (emailposted) {
      async function fetchData() {
        try {
          const response = await fetch(
            `${server}/recipient/ApproveEmail/${storedUser._id}`
          );
          console.log("===>", response);
          if (!response.ok) {
            throw new Error("Failed to fetch image data");
          }

          const data = await response.json();

          console.log(data, "appro9ve");
          setEmailposted(false);
          const unique = new Set();
          const mydata = data?.filter((x) => {
            if (x?.targetedAudeince !== null && !unique.has(x.id)) {
              unique.add(x.id);
              return true;
            } else {
              return false;
            }
          });
          console.log(mydata, "x?.targetedAudeince!==null&&");
          const filterNonaudi = data.filter((x) => !x.fromaudi);

          setEmailApporve([...filterNonaudi, ...mydata]);
        } catch (error) {
          console.error(error);
        }
      }
      fetchData();
    }
  }, [emailposted, storedUser._id]);
  return (
    <Layout>
      <ChakraProvider>
        <h1>
          Approved Page
          {postLoading && <Spinner />}
        </h1>
        <Flex flexWrap="wrap">
          {imageData &&
            imageData.map((image, index) => (
              <Box
                key="fetchedImage"
                borderWidth="3px"
                borderRadius="lg"
                borderColor={"teal"}
                overflow="hidden"
                m="90"
              >
                {image.TwitterImage && (
                  <Image
                    src={`data:${image.TwitterImage.mimetype};base64,${image.TwitterImage.buffer}`}
                    alt="Fetched Image"
                    width="300px"
                    height="200px"
                    objectFit="cover"
                  />
                )}
                <VStack p="4" align="start">
                  <Text fontSize="lg" fontWeight="bold">
                    Caption: {image.tweetText}
                  </Text>
                  <Text fontSize="lg" fontWeight="bold">
                    Platform: {image.socialPlatform}
                  </Text>
                  <Text>
                    {console.log("Aaaaaaaaaaaaaaaa", image.createdAt)}
                    {image.createdAt
                      ? `Created At: ${new Date(
                          image.createdAt
                        ).toLocaleString()}`
                      : "Time of post creation is not available"}
                  </Text>
                  <Text>Post No: {index + 1}</Text>
                  <Button
                    onClick={() =>
                      handlePostTwitter(
                        image.tweetText,
                        image.TwitterImage,
                        image,
                        storedUser._id,
                        index
                      )
                    }
                    colorScheme="teal"
                  >
                    Post
                  </Button>
                </VStack>
              </Box>
            ))}
        </Flex>
        <Flex flexWrap="wrap">
          {posts.map((post, index) => (
            <Box
              key={post._id}
              borderWidth="3px"
              borderRadius="lg"
              borderColor={"teal"}
              overflow="hidden"
              m="90"
            >
              <Image
                src={post.imageUrl}
                alt="Post Image"
                width="300px"
                height="200px"
                objectFit="cover"
              />
              <VStack p="4" align="start">
                <Text fontSize="lg" fontWeight="bold">
                  Caption: {post.caption}
                </Text>
                <Text fontSize="lg" fontWeight="bold">
                  Platform: {post.socialPlatform}
                </Text>
                <Text>
                  {console.log("Aaaaaaaaaaaaaaaa", post.createdAt)}
                  {post.createdAt
                    ? `Created At: ${new Date(post.createdAt).toLocaleString()}`
                    : "Time of post creation is not available"}
                </Text>
                <Text>Post No: {index + 1}</Text>
                <Text>Schedule Time to Set :</Text>
                <DatePicker
                  selected={
                    scheduleTimes[index] || new Date(post.scheduledTime)
                  }
                  onChange={(date) => {
                    const updatedScheduleTimes = [...scheduleTimes];
                    updatedScheduleTimes[index] = date;
                    setScheduleTimes(updatedScheduleTimes);
                  }}
                  showTimeSelect
                  timeFormat="h:mm aa"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  placeholderText="Select date and time"
                  minDate={new Date()}
                />
                <Button
                  onClick={() => handleButtonClick(post, index)}
                  colorScheme="teal"
                >
                  Post
                </Button>
              </VStack>
            </Box>
          ))}
        </Flex>
        <Flex flexWrap="wrap">
          <Badge>{emailLoading && <Spinner />}</Badge>
          {EmailApporve.map((x, index) => (
            <Box
              key={x._id}
              borderWidth="3px"
              borderRadius="lg"
              borderColor={"teal"}
              overflow="hidden"
              m="90"
            >
              <VStack p="4" align="start">
                <Text fontSize="lg" fontWeight="bold">
                  Email made by: {x?.user?.name}
                </Text>
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  style={{ wordWrap: "break-word" }}
                >
                  to send :
                  {!x.fromaudi
                    ? x.RecipientOne
                    : x?.Recipients.map((x) => <li>{x}</li>)}
                </Text>
                <Text>
                  {x.createdAt
                    ? `Created At: ${new Date(x.createdAt).toLocaleString()}`
                    : "Time of post creation is not available"}
                </Text>
                <br />
                <Text>Email</Text>
                <Text>Subject:{x.subject}</Text>
                <Box dangerouslySetInnerHTML={{ __html: x?.message }}></Box>

                <Button
                  onClick={() => handleEmailApprove(x, index)}
                  colorScheme="teal"
                >
                  Post
                </Button>
              </VStack>
            </Box>
          ))}
        </Flex>
      </ChakraProvider>
    </Layout>
  );
};

export default ApprovePosts;
