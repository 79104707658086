import axios from "axios";
import React, { useState, useEffect } from "react";
import { FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Posting } from "./FbAllcomponenet/FbAll";
import { handleSubmitUrl } from "./InstaAllComponent/InstaAll";
import { useCalendar } from "../context/CalendarContext";
import useOrderStore from "../../contexts/Userhook";
import { Spinner } from "@chakra-ui/react";
export const CreatePostSocail = ({
  token,
  currentPageClicked,
  FbPageIds,
  InstaPageIds,
}) => {
  const [postdata, setpostdata] = useState({
    image: null,
    text: "",
    video: null,
    scheduleTime: null,
  });
  // insta
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const dateParam = urlParams.get("date");

    if (dateParam) {
      const selectedDate = new Date(dateParam);
      setpostdata((prev) => ({ ...prev, scheduleTime: selectedDate }));
    }
  }, []);
  const [postLoading, setPostLoading] = useState(false);
  const [postData, setPostData] = useState({
    image: null,
    caption: "",
    imageUrl: "",
    contentType: "image",
    scheduleTime: null,
  });

  // end insta
  const [Postloading, setpostloading] = useState(false);
  const [AllLoading,setAllLoading]=useState(false)
  const { storedUser } = useOrderStore();
  console.log(storedUser, "user");
  // const isManager = storedUser && storedUser.role === "manager";
  const isManager = storedUser && (storedUser.role === "manager"&&!storedUser.socailpermission);
  const { addCalendarEvent } = useCalendar();
  const handleDateChange = (date) => {
    const selectedDate = date || new Date();
    setpostdata((prev) => ({ ...prev, scheduleTime: selectedDate }));
  };
  const myform = (e) => {
    let verifyvideo;
    let datatype = e.target.name;
    if (datatype !== "text") {
      verifyvideo = ["video/mp4", "audio/mp3"].includes(
        e.target?.files[0].type
      );
    }

    if (datatype === "file" && !verifyvideo) {
      setpostdata((prev) => ({ ...prev, image: e.target.files[0] }));
      setPostData({
        ...postData,
        image: e.target.files[0],
        contentType: "image",
      });
    } else if (datatype === "text") {
      console.log(e.target.value, "data");
      setpostdata((prev) => ({ ...prev, text: e.target.value }));
      setPostData((prev) => ({ ...prev, caption: e.target.value }));
    } else if (verifyvideo) {
      setpostdata((prev) => ({ ...prev, video: e.target.files[0] }));
      setPostData({
        ...postData,
        image: e.target.files[0],
        contentType: "reels",
      });
    }
  };

  const handleSubmit = async () => {
    setAllLoading(true)
    if (FbPageIds.length === 0 && InstaPageIds.length === 0) {
      return toast.error("Kindly select any platform");
    }
    if (FbPageIds.length > 0) {
  try {
    await Posting(
      setpostloading,
      postdata,
      FbPageIds,
      setpostdata,
      addCalendarEvent,
      isManager,
      storedUser
    );
    
  } catch (error) {
    setAllLoading(false)
    toast.error(error.message)
    
  }

    }
    if (InstaPageIds.length > 0 && postData.image) {
     try {
       await handleSubmitUrl(
         postData,
         InstaPageIds,
         setPostLoading,
         setPostData,
         postdata.scheduleTime,
         addCalendarEvent,
         isManager,
         setAllLoading,
         storedUser
       );
       
      } catch (error) {
        
        setAllLoading(false)
        toast.error(error.message)
     }
    }
    if (isManager) {
      toast.success("Send To Approved Succesfully");
      
    }
    setAllLoading(false)
    // window.location.reload();
  };

  return (
    <div className="max-w-md mx-auto bg-white p-8 rounded-md shadow-md">
      <h2 className="text-2xl font-semibold mb-4">All In One Posting</h2>
      <div className="mb-4">
        <label
          htmlFor="file"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          Upload File
        </label>
        <input
          type="file"
          id="file"
          name="file"
          onChange={myform}
          className="p-2 border border-gray-300 rounded-md w-full"
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="message"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          Your Message
        </label>
        <textarea
          id="message"
          name="text"
          rows="4"
          className="resize-none p-2 border border-gray-300 rounded-md w-full"
          placeholder="Type your message here..."
          onChange={myform}
          value={postdata.text}
        ></textarea>
      </div>

      <div className="mb-4">
        <label
          htmlFor="scheduleTime"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          Schedule Time (Min Time: 10 mins and Max Time: 30 days)
        </label>
        <DatePicker
          id="scheduleTime"
          selected={postdata.scheduleTime}
          onChange={handleDateChange}
          showTimeSelect
          timeFormat="h:mm aa"
          timeIntervals={15}
          dateFormat="MMMM d, yyyy h:mm aa"
          className="p-2 border border-gray-300 rounded-md"
          placeholderText="Select date and time"
          minDate={new Date()}
        />
      
      </div>

      <button
        type="submit"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
        onClick={handleSubmit}
      >
        {isManager ? (
          <>
            Send for Approval
            {"  "}
            
          </>
        ) : (
          <>
            Post
            {"  "}
            
          </>
        )}
          &nbsp;
          &nbsp;
          {AllLoading&&(<Spinner color="red"/>)}
        
      </button>
    </div>
  );
};
