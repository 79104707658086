import {  Flex, Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Status from "./components/Status";
import Layout from "layouts/Layout/Layout";
import axios from "axios";
import useOrderStore from "contexts/Userhook";



const serverURL = process.env.REACT_APP_BACKEND_URL;

const EmailForm = () => {
  const [emailData, setEmailData] = useState({
    from: "",
    atOnce: false,
    to: "",
    cc: "",
    bcc: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(true);
  const [sentLoading, setSentLoading] = useState(false);
  const [error, setError] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const { storedUser } = useOrderStore();

  useEffect(() => {
    const getAllRecipients = async () => {
      try {
        const response = await axios.get(`${serverURL}/recipient/${storedUser._id}`);
        setRecipients(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    getAllRecipients();
  }, []);

  const handleChange = (event) => {
    const userInput = event.target;
    if (userInput.name === "atOnce") {
      setEmailData({
        ...emailData,
        [userInput.name]: userInput.checked,
      });
    } else {
      setEmailData({
        ...emailData,
        [userInput.name]: userInput.value,
      });
    }
  };

 

  const deleteEmail = async (id) => {
    setRecipients((prevRecipients) => {
      const updatedRecipients = prevRecipients.filter(
        (recipient) => recipient.id !== id
      );
      return updatedRecipients;
    });
    const response = await axios.delete(
      `${serverURL}/recipient/delete-email/${id}`
    );
    console.log(response.data);
  };

  // const handleReset = () => {
  //   setEmailData({
  //     from: "",
  //     to: "",
  //     cc: "",
  //     bcc: "",
  //     subject: "",
  //     message: "",
  //     atOnce: false,
  //   });
  // };

  return (
    <Layout>
      <Box border="1px solid black" p="20px" borderRadius="md">
        <Flex
          className="email-form-container"
          p="20px"
          gridGap="30px"
          justifyContent="center"
          alignItems="center"
        >
      
          <Status
            recipients={recipients}
            deleteEmail={deleteEmail}
            loading={loading}
            sentLoading={sentLoading}
            error={error}
          />
        </Flex>
      </Box>
    </Layout>
  );
};

export default EmailForm;
