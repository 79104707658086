import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
} from "@chakra-ui/react";
import Layout from "layouts/Layout/Layout";
import { useParams } from "react-router-dom";

const serverURL = process.env.REACT_APP_BACKEND_URL;

const AudienceMembers = () => {
  const { id } = useParams();
  const [audienceMembers, setAudienceMembers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchAudienceMembers() {
      try {
        setLoading(true);
        const { data } = await axios.get(`${serverURL}/api/allmember/${id}`);

        setAudienceMembers(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching audience members:", error);
        setLoading(false);
      }
    }

    fetchAudienceMembers();
  }, [id]);

  return (
    <Layout>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Box p={4} overflowX="auto">
          {loading ? (
            <Spinner />
          ) : (
            <Table variant="simple" size="md" colorScheme="gray">
              <Thead>
                <Tr bg="#6AD2FF">
                  <Th>#</Th>
                  <Th>First Name</Th>
                  <Th>Last Name</Th>
                  <Th>Email Address</Th>
                  <Th>Phone Number</Th>
                  <Th>Created At</Th>
                </Tr>
              </Thead>
              <Tbody>
                {audienceMembers.map((member, index) => (
                  <Tr key={member._id}>
                    <Td>{index + 1}</Td>
                    <Td>{member.member.firstName}</Td>
                    <Td>{member.member.lastName}</Td>
                    <Td>{member.member.emailAddress}</Td>
                    <Td>{member.member.phoneNumber}</Td>
                    <Td>{member.member.createdAt}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </Box>
      </Box>
    </Layout>
  );
};

export default AudienceMembers;
