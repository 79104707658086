import React, { useEffect, useState } from "react";
import { Avatar, Box, Text, Spinner, Textarea, Button } from "@chakra-ui/react";
import { useParams } from "react-router";
import axios from "axios";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";

const InstaInbox = ({ currentpagePosts }) => {
  const [gettingConloading, setgettingConloading] = useState(false);
  const [allconversationids, setallconversationids] = useState([]);
  const [Coversation, setCoversation] = useState([]);
  const [gettinguserConloading, setgettinguserConloading] = useState(false);
  const [dataloading, setdataloading] = useState(false);
  const [currentuser, setcurrentuser] = useState({});
  const [participatnts, setparticipatnts] = useState([]);
  const [sendmessgage, setsendmessgage] = useState("");

  useEffect(() => {
    const mydata = async () => {
      try {
        setdataloading(true);
        const { data } = await axios.get(
          `https://graph.facebook.com/v18.0/${currentpagePosts.id}?fields=connected_instagram_account&access_token=${currentpagePosts.access_token}`
        );

        if (data) {
          setdataloading(false);
          setcurrentuser(data?.connected_instagram_account);
        }
      } catch (error) {
        toast.error(error.message);
        setdataloading(false);
      }
    };
    mydata();
  }, [currentpagePosts.access_token, currentpagePosts.id]);

  useEffect(() => {
    const Allconversationsid = async () => {
        try {
          setgettingConloading(true);
        const { data } = await axios.get(
          `https://graph.facebook.com/v18.0/${currentpagePosts.id}/conversations?fields=name%2Cupdated_time&platform=instagram&access_token=${currentpagePosts.access_token}`
        );
        if (data) {
          setgettingConloading(false);
          setallconversationids(data);
        }
      } catch (error) {
        let err=error.response.data?error?.response?.data?.error?.message:error.message
        console.log(err,"err")
        toast.error(err);
        setgettingConloading(false);
      }
      };
      Allconversationsid();
  }, [currentpagePosts.access_token, currentpagePosts.id]);

  const colors = ["#3498db", "#e74c3c", "#2ecc71", "#f39c12", "#9b59b6"];

  const myconver = async (x) => {
    try {
      setgettinguserConloading(true);
      const { data } = await axios.get(
        `https://graph.facebook.com/v18.0/${x.id}?fields=name,participants,updated_time,messages{message,to,from}&platform=INSTAGRAM&access_token=${currentpagePosts.access_token}`
      );
      if (data) {
        setgettinguserConloading(false);
        let reversdata = data?.messages?.data.reverse();
        setCoversation(reversdata);
        setparticipatnts(data?.participants);
      }
    } catch (error) {
      toast.error(error.message);
      setgettinguserConloading(false);
    }
  };

  const sendmsg = async (e) => {
    e.preventDefault();
    const recipient = participatnts?.data?.filter(
      (x) => x.id !== currentuser.id
    )[0];
    const sender = participatnts?.data?.filter(
      (x) => x.id === currentuser.id
    )[0];

    if (recipient.id) {
      let newmessage = {
        from: sender,
        message: sendmessgage,
      };
      setCoversation((prev) => [...prev, newmessage]);
      setsendmessgage("");

      try {
        await axios.post(
          `https://graph.facebook.com/v18.0/me/messages?access_token=${currentpagePosts.access_token}`,
          { recipient: { id: recipient.id }, message: { text: sendmessgage } }
        );
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("recipient id does not exist");
    }
  };

  return (
    <Box display="flex">
      <Box flex="30%" maxH="300px" h="300px" overflowY="scroll">
        <ul>
          {gettingConloading && <Spinner />}
          {dataloading && <Spinner />}
          {allconversationids?.data?.map((x, index) => (
            <li
              key={x.id}
              className="hover:bg-gray-200 h-16 rounded-md flex items-center cursor-pointer"
              onClick={() => myconver(x)}
            >
              <Avatar bg={colors[index % colors.length]}></Avatar>
              <Text ml="2">{x.name}</Text>
            </li>
          ))}
        </ul>
      </Box>
      <Box flex="70%" maxH="300px" h="300px" overflowY="scroll">
        {gettinguserConloading && <Spinner />}
        {Object.keys(Coversation).length === 0 && (
          <div>Please select any Conversation</div>
        )}
        <ul
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          className="h-[100%]"
        >
          <li>
            {Coversation?.map((x) => (
              <li
                style={{ fontSize: "13px" }}
                className={x?.from.id === currentuser?.id ? "me" : "other"}
              >
                <li>
                  {x?.from.username}
                  <br />
                  {x?.message}
                </li>
              </li>
            ))}
          </li>
          <li>
            {Object.keys(Coversation).length !== 0 && (
              <form onSubmit={sendmsg}>
                <Textarea
                  value={sendmessgage}
                  onChange={(e) => setsendmessgage(e.target.value)}
                />
                <Button
                  colorScheme="blue"
                  borderRadius="20px"
                  mt="2"
                  type="submit"
                >
                  Send
                </Button>
              </form>
            )}
          </li>
        </ul>
      </Box>
    </Box>
  );
};

export default InstaInbox;
