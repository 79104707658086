export const columnsDataDevelopment = [
  {
    Header: "Template Preview",
    accessor: "template",
  },

  {
    Header: "Date & Time",
    accessor: "date",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "TITLE",
    accessor: "title",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "Last Update",
    accessor: "update",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];
