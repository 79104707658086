import {
  Flex,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Progress,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import axios from "axios";
import useOrderStore from "contexts/Userhook";
import { useNavigate } from "react-router-dom";
const ServerUrl=process.env.REACT_APP_BACKEND_URL
export default function CheckTable(props) {
  const { storedUser } = useOrderStore();
  // const { columnsData, tableData } = props;

const [headers,setheaders]=useState([   {
    "Header": "Template",
    "accessor": "Template"
},
{
    "Header": "Time",
    "accessor": "Time"
},
{
    "Header": "subject",
    "accessor": "subject"
},
{
    "Header": "recipient",
    "accessor": "recipient"
},

])

const [tableData ,settableData]=useState([])
useEffect(()=>{
   
 const Allsentemaildata=async()=>{
  console.log("called")
    const {data}= await axios.get(`${ServerUrl}/draft/DraftEmails/${storedUser._id}`)
    console.log(data,"verdrfat")
    if (data.length>0) {
     let all=data.map((x)=>({Template:x?.message,Time:new Date(x?.draftTime).toLocaleString("en-us", {weekday: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'}),subject:x?.subject,recipient:x?.recipient?.length,tableid:x._id}))
    //  console.log(all)
  settableData(all)  // for the keys 
     }}
  Allsentemaildata()


},[settableData, storedUser._id])

  const columns = useMemo(() => headers, [headers]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 200;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");


  const navigate=useNavigate()
  const GotosavedTemplate=(x)=>{
    navigate(`/editor/${x}`)
    

  }
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll"}}
      h="400px"
      sx={{
        "&::-webkit-scrollbar": {
          width: "12px",
          height:"10px"
        },
        "&::-webkit-scrollbar-thumb": {
          bg: "#422afb",
          borderRadius: "full",
        },
        "&::-webkit-scrollbar-track": {
          bg: "gray.300",
        },
      }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Drafted Emails
        </Text>
        {/* <Menu /> */}
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                    {column.isSorted ? (column.isSortedDesc ? (<FaCaretDown />) : (<FaCaretUp />)) : (<FaCaretDown />)}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
  {page.map(row => {
    prepareRow(row);
    return (
      <Tr {...row.getRowProps()} key={row.id}
      onClick={()=>{
        GotosavedTemplate(row.original.tableid)
      }}
      >
        {row.cells.map(cell => (
          <Td {...cell.getCellProps()} key={cell.column.id}
          
          >
            {cell.column.id === 'Template' ? (
              // Conditionally render progress bar
              <>
                <div className="htmltag"
              
                  dangerouslySetInnerHTML={{ __html: cell.value }}
                  
          />
            </>
            ) : (
              // Render other cells as usual
              cell.render('Cell')
            )}
          </Td>
        ))}
      </Tr>
    );
  })}
</Tbody>
      </Table>
    </Card>
  );
}
