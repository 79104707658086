import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Box,
  Button,

  CircularProgress,
  Flex,
  FormControl,
  FormLabel,
  Heading,
 
  Icon,
 
  Input,
 

  InputGroup,
 

  InputRightElement,
 

  Text,
  useColorModeValue,
} from "@chakra-ui/react";


import DefaultAuth from "layouts/auth/Default";

import illustration from "assets/img/auth/auth.png";

import useOrderStore from "contexts/Userhook";
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
const serverURL = process.env.REACT_APP_BACKEND_URL;
function ForgetPassword() {
  const { loginLoadingFunction, loginLoading, login, storedUser } =
    useOrderStore();
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  
  const brandStars = useColorModeValue("brand.500", "brand.400");
const [SentEmailStatus,setSentEmailStatus]=useState(false)
const [TokenStatus,setTokenStatus]=useState(false)
const [Password,setPassword]=useState("")
const [ConPassword,setConPassword]=useState("")
const [show ,setshow ]=useState(false)
const [Token,setToken]=useState({
    Token:"",
    userid:""
})
const handleClick=()=>{
    setshow(!show)
}
const [TokenLoading,setTokenLoading]=useState(false)
const [PasschangingLoading,setPasschangingLoading]=useState(false)


  const [email, setEmail] = useState("");
 

  const navigate = useNavigate();
  // const {storedUser}=useOrderStore()
//   useEffect(() => {
//     if (storedUser.email) {
//       navigate("/admin");
//     }
//   }, [navigate, storedUser]);
  const handleSignIn = async () => {
    if (!email.trim()) {
        return toast.error("Cant send empty email")
    }
    if (!email.includes("@")) {
        return toast.error("Your email formate is wrong ")
        
    }
  try {
    loginLoadingFunction(true);
    const {data} = await axios.post(`${serverURL}/auth/forgetPassword`,
    {
        email,
        //   password,
    },
    )
    if (data.message==="Sucessfully sent Email,you have 2 minutes") {
        toast.warn(data.message)
        loginLoadingFunction(false);
        setToken({
            Token:"",
            userid:data.userid
        })
        setSentEmailStatus(true)
        
    }
} catch (error) {
    let err=error.response&& error.response.data.message? error.response.data.message:error.message
    loginLoadingFunction(false);
    toast.error(err)
    
  }
  };
console.log(Token)
  const MyTokenHandle=async()=>{
     
    try {
        setTokenLoading(true);
        const {data} = await axios.post(`${serverURL}/auth/resettoken`,
         Token
        )
        console.log(data)
        if (data.message==="Token Matched") {
              
            setTokenLoading(false);
            setSentEmailStatus(true)
            setTokenStatus(true)
            

            
          }
      } catch (error) {
        setTokenLoading(false);
        let err=error.response&& error.response.data.message? error.response.data.message:error.message
        toast.error(err)
        
      }
  }


  const HandleChangePassword=async()=>{
    try {
        setPasschangingLoading(true)
        const {data}=await axios.post(`${serverURL}/auth/changepass`,{
            ...Token,
            Password
        })
        if(data.message==="Password has been Changed"){
          navigate("/")
        }
    } catch (error) {
        setPasschangingLoading(false)
        let err=error.response&& error.response.data.message? error.response.data.message:error.message
        toast.error(err)  
    }
  }

  return (
    <>
      <DefaultAuth illustrationBackground={illustration} image={illustration}>
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection="column"
        >
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="10px">
              Reset Password
            </Heading>
           
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            
               
        {
            SentEmailStatus&&TokenStatus?(
                <FormControl>
                <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Min. 6 characters"
                  mb="24px"
                  size="lg"
                //   type={show ? "text" : "password"}
                  variant="auth"
                  value={Password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  {/* <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  /> */}
                </InputRightElement>
              </InputGroup>
              {/* co */}
                <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Min. 6 characters"
                  mb="24px"
                  size="lg"
                //   type={show ? "text" : "password"}
                  variant="auth"
                  value={ConPassword}
                  onChange={(e) => setConPassword(e.target.value)}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
                
              
               
                <Button
                  onClick={HandleChangePassword}
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                >
                  Reset Password
                  {PasschangingLoading && (
                    <CircularProgress isIndeterminate color="green.300" />
                  )}
                </Button>
  
                  
                
              </FormControl>  
             
            ):SentEmailStatus?(
                <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Token<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="Enetr Your 6 Digit Code here"
                mb="24px"
                fontWeight="500"
                size="lg"
                value={Token.Token}
                onChange={(e) => setToken((prev)=>({
                    ...prev,
                    Token:e.target.value


                }))}
              />
              
            
            
                    <Button
                    onClick={MyTokenHandle}
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="100%"
                    h="50"
                    mb="24px"
                  >
                    Enter your Code
                    {TokenLoading && (
                      <CircularProgress isIndeterminate color="green.300" />
                    )}
                  </Button>

                
            </FormControl>
            ):(
                <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Email<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="email"
                  placeholder="enetr your email"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                
              
               
                <Button
                  onClick={handleSignIn}
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                >
                  Send Email
                  {loginLoading && (
                    <CircularProgress isIndeterminate color="green.300" />
                  )}
                </Button>
  
                  
                
              </FormControl>
                
            )
        }
            
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="start"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              <NavLink to="/">
                Remember password?

              </NavLink>
                
              
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </DefaultAuth>
    </>
  );
}

export default ForgetPassword;
