import { create } from "zustand";


export const PieChartHook=create((set)=>{
    return{
     piedata:{},
     LineChartdata:[],
    piedatafun:(data)=>{
        set(()=>{
            return{
                piedata:data,
               
            }
        })

     },
    Linedatafun:(data)=>{
        set(()=>{
            return{
                LineChartdata:data,
               
            }
        })

     }

    }

})


