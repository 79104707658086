import axios from "axios";
import Layout from "layouts/Layout/Layout";
import React from "react";
import { handleFacebookLogin } from "./Logins/FacebookLogin";
import { handleInstaLogin } from "./Logins/InstaLogin";
import { useNavigate } from "react-router-dom";
import useOrderStore from "contexts/Userhook";
import { toast } from "react-toastify";
const server = process.env.REACT_APP_BACKEND_URL;
const Social = () => {
  const navigate = useNavigate();
  const { storedUser } = useOrderStore();
  const handleFacebookClick = () => {
    if (storedUser.role === "manager")
      return toast.error("Sorry You As Manager you Dont have Rights");
    handleFacebookLogin(navigate, storedUser);
  };
  const handleinstaClick = () => {
    if (storedUser.role === "manager")
      return toast.error("Sorry You As Manager you Dont have Rights");
    handleInstaLogin(navigate, storedUser);
  };
  function handleTwitterAuth() {
    if (storedUser.role === "manager")
      return toast.error("Sorry You As Manager you Dont have Rights");
    // Redirect the user to the Twitter authentication route
    window.location.href = `${server}/twitter/auth/twitter?id=${storedUser._id}`;
  }
  async function google() {
    if (storedUser.role === "manager")
    return toast.error("Sorry You As Manager you Dont have Rights");
  
  window.location.href = `${server}/google/auth/youtube?id=${storedUser._id}`;
 
    // const {data}=await axios.get(`${server}/google/auth/youtube?id=${storedUser._id}`);
    // console.log(data,"data")
  }
  
  // window.location.href = `${server}/google/auth/youtube?id=${storedUser._id}`;
  return (
    <Layout>
      <div className="card" onClick={handleFacebookClick}>
        Facebook
      </div>
      <div className="card" onClick={handleinstaClick}>
        Instagram
      </div>
      <div className="card" onClick={handleTwitterAuth}>
        Twitter
      </div>
      <div
        className="card "
        style={{ backgroundColor: "red", color: "white" }}
        onClick={google}
      >
        Youtube
      </div>
    </Layout>
  );
};

export default Social;
