export const handleTemplateChange = (event,setSelectedTemplateId,templates,setTemplateName,setSelectedTemplateIdForDELETE,emailEditorRef) => {
    const selectedTemplateId = event.target.value;
    setSelectedTemplateId(selectedTemplateId);
    const selectedTemplate = templates.find(
      (template) => template._id === selectedTemplateId
    );
    // setSelectedTemplateId(selectedTemplate);
    setTemplateName(selectedTemplate?.templateName);
    setSelectedTemplateIdForDELETE(selectedTemplateId);

    if (selectedTemplate) {

      const parsedDesign = JSON.parse(selectedTemplate.template);
      emailEditorRef.current.editor.loadDesign(parsedDesign);
    }
  };
