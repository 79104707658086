// import React, { useEffect, useState } from "react";
// import { Box, Button, Textarea, Heading } from "@chakra-ui/react";
// import Layout from "layouts/Layout/Layout";
// import axios from "axios";
// import useOrderStore from "contexts/Userhook";
// import { toast } from "react-toastify";
// import { FaSpinner } from "react-icons/fa";
// import { useCalendar } from "../context/CalendarContext";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// const Tweet = () => {
//   const server = process.env.REACT_APP_BACKEND_URL;
//   const [tweetText, setTweetText] = useState("");
//   const [tweetImage, setTweetImage] = useState(null);
//   const { storedUser } = useOrderStore();
//   const [TweetData, setTweetData] = useState();
//   const [Postloading, setPostLoading] = useState(false);
//   const [scheduleDate, setScheduleDate] = useState(null);
//   const isManager =
//     storedUser && storedUser.role === "manager" && !storedUser.socailpermission;
//   const { addCalendarEvent } = useCalendar();
//   const handleDateChange = (date) => {
//     setScheduleDate(date);
//   };

//   useEffect(() => {
//     const TweetToken = async () => {
//       try {
//         setTweetText("");
//         const { data } = await axios.get(
//           `${server}/twitter/MytweetToken/${storedUser._id}`
//         );
//         if (data) {
//           setTweetData(data);
//           toast.success(`Welcome ${data?.Twitterdata?.username}`);
//         } else {
//           toast.error("No account is connected");
//         }
//       } catch (error) {
//         toast.error(error.message);
//       }
//     };
//     TweetToken();
//   }, [server, storedUser._id]);

//   const saveToMongoDB = async () => {
//     try {
//       const response = await axios.post(`${server}/api/scheduledpost`, {});
//       console.log("Data saved to MongoDB:", response.data);
//     } catch (error) {
//       console.error("Error saving data to MongoDB:", error);
//     }
//   };
//   const handlePost = async () => {
//     console.log("Tweet posted:", tweetText);
//     try {
//       setPostLoading(true);
//       if (!isManager && scheduleDate && scheduleDate > new Date()) {
//         console.log("Schedule Post======", scheduleDate);
//         addCalendarEvent({
//           title: `Posting to Twitter`,
//           start: scheduleDate,
//           end: scheduleDate,
//         });
//         setTimeout(() => {
//           handlePostSubmission();
//           setPostLoading(false);
//         }, scheduleDate - new Date());
//         toast.success("Post scheduled successfully!");
//       } else {
//         if (isManager) {
//           await saveToMongoDB();
//           return;
//         } else {
//           await handlePostSubmission();

//           setPostLoading(false);
//         }
//       }
//     } catch (error) {
//       toast.error(error.message);
//       setPostLoading(false);
//     }
//   };

//   const handlePostSubmission = async () => {
//     try {
//       const formData = new FormData();
//       formData.append("tweetText", tweetText);
//       formData.append("image", tweetImage);
//       const { data } = await axios.post(
//         `${server}/twitter/post-tweet`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );
//       if (data) {
//         setTweetText("");
//         setTweetImage(null);
//         toast.success(data);
//       }
//     } catch (error) {
//       toast.error(error.message);
//     }
//   };

//   return (
//     <Layout>
//       <Box p={4}>
//         <Heading as="h2" size="md" mb={4} textAlign="center" color="#0B1EFF">
//           Do Your Tweet
//         </Heading>
//         <Textarea
//           value={tweetText}
//           onChange={(e) => setTweetText(e.target.value)}
//           placeholder="Write your tweet..."
//           resize="none"
//         />
//         <input type="file" onChange={(e) => setTweetImage(e.target.files[0])} />
//         {!isManager && (
//           <div className="mt-4">
//             <label
//               htmlFor="scheduleDate"
//               className="block text-gray-700 text-sm font-bold mb-2"
//             >
//               Schedule Date and Time
//             </label>
//             <DatePicker
//               id="scheduleDate"
//               selected={scheduleDate}
//               onChange={handleDateChange}
//               showTimeSelect
//               timeFormat="h:mm aa"
//               timeIntervals={15}
//               dateFormat="MMMM d, yyyy h:mm aa"
//               className="p-2 border border-gray-300 rounded-md w-full"
//               placeholderText="Select date and time"
//               minDate={new Date()}
//             />
//           </div>
//         )}
//         <Button
//           mt={4}
//           color="white"
//           backgroundColor="#0B1EFF"
//           onClick={handlePost}
//         >
//           {isManager ? (
//             <>
//               Send for Approval
//               {"  "}
//               {Postloading && <FaSpinner />}
//             </>
//           ) : (
//             <>
//               Post
//               {"  "}
//               {Postloading && <FaSpinner />}
//             </>
//           )}
//         </Button>
//       </Box>
//     </Layout>
//   );
// };

// export default Tweet;

import React, { useEffect, useState } from "react";
import { Box, Button, Textarea, Heading } from "@chakra-ui/react";
import Layout from "layouts/Layout/Layout";
import axios from "axios";
import useOrderStore from "contexts/Userhook";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import { useCalendar } from "../context/CalendarContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Tweet = () => {
  const server = process.env.REACT_APP_BACKEND_URL;
  const [tweetText, setTweetText] = useState("");
  const [tweetImage, setTweetImage] = useState(null);
  const { storedUser } = useOrderStore();
  const [TweetData, setTweetData] = useState();
  const [Postloading, setPostLoading] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(null);
  const isManager =
    storedUser && storedUser.role === "manager" && !storedUser.socailpermission;
  const { addCalendarEvent } = useCalendar();
  const handleDateChange = (date) => {
    setScheduleDate(date);
  };

  useEffect(() => {
    let url =
      storedUser.role === "manager"
        ? `${server}/twitter/MytweetToken/${storedUser.roleby}`
        : `${server}/twitter/MytweetToken/${storedUser._id}`;
    const TweetToken = async () => {
      try {
        setTweetText("");
        const { data } = await axios.get(
          url
          // `${server}/twitter/MytweetToken/${storedUser._id}`
        );
        if (data) {
          console.log(data, "ff");
          setTweetData(data);
          if (`${data?.Twitterdata?.username}` === "undefined") {
            toast.error("No account is connected");
          } else {
            toast.success(`Welcome ${data?.Twitterdata?.username}`);
          }
        } else {
          toast.error("No account is connected");
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    TweetToken();
  }, [server, storedUser._id, storedUser.role, storedUser.roleby]);

  const handlePost = async () => {
    console.log("Tweet posted:", tweetText);
    try {
      setPostLoading(true);
      if (!isManager && scheduleDate && scheduleDate > new Date()) {
        console.log("Schedule Post======", scheduleDate);
        addCalendarEvent({
          title: `Posting to Twitter`,
          start: scheduleDate,
          end: scheduleDate,
        });
        setTimeout(() => {
          handlePostSubmission();
          setPostLoading(false);
        }, scheduleDate - new Date());
        toast.success("Post Scheduled Successfully!");
      } else {
        await handlePostSubmission();
        toast.success("Posted Successfully!");
        setPostLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setPostLoading(false);
    }
  };

  const handlePostSubmission = async () => {
    try {
      const formData = new FormData();
      formData.append("tweetText", tweetText);
      formData.append("image", tweetImage);
      formData.append("token", TweetData.token);
      formData.append("tokenSecret", TweetData.tokenSecret);
      formData.append("userid", storedUser._id);

      if (storedUser.role === "manager") {
        formData.append("Admin", storedUser.roleby);
        formData.append("isManager", true);
      } else {
        formData.append("isManager", false);
      }
      const { data } = await axios.post(
        `${server}/twitter/post-tweet`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          // ...TweetData,
        }
      );
      if (data) {
        setTweetText("");
        setTweetImage(null);
        toast.success(data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Layout>
      <Box p={4}>
        <Heading as="h2" size="md" mb={4} textAlign="center" color="#0B1EFF">
          Do Your Tweet
        </Heading>
        <Textarea
          value={tweetText}
          onChange={(e) => setTweetText(e.target.value)}
          placeholder="Write your tweet..."
          resize="none"
        />
        <input type="file" onChange={(e) => setTweetImage(e.target.files[0])} />
        <div className="mt-4">
          <label
            htmlFor="scheduleDate"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Schedule Date and Time
          </label>
          <DatePicker
            id="scheduleDate"
            selected={scheduleDate}
            onChange={handleDateChange}
            showTimeSelect
            timeFormat="h:mm aa"
            timeIntervals={15}
            dateFormat="MMMM d, yyyy h:mm aa"
            className="p-2 border border-gray-300 rounded-md w-full"
            placeholderText="Select date and time"
            minDate={new Date()}
          />
        </div>
        <Button
          mt={4}
          color="white"
          backgroundColor="#0B1EFF"
          onClick={handlePost}
        >
          {isManager ? (
            <>
              Send for Approval
              {"  "}
              {Postloading && <FaSpinner />}
            </>
          ) : (
            <>
              Post
              {"  "}
              {Postloading && <FaSpinner />}
            </>
          )}
        </Button>
      </Box>
    </Layout>
  );
};

export default Tweet;
