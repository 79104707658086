import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";


import { ToastContainer } from "react-toastify";

import Dashboard from "layouts/admin/index";

import Auth from "./views/auth/signIn/index";
import Register from "./views/auth/Registration/index";
import useOrderStore from "contexts/Userhook";

import Editor from "views/Editor/Editor";
import Mymembers from "views/admin/Members.js/Mymembers";
import Social from "views/Socail/SocialHome";
import FacebookHome from "components/Facebook/FacebookHome";
import Instagram from "components/InstagramComp/Instagram";

import AllSocail from "components/AllScocail/AllSocail";
import Connectfbpage from "components/Facebook/Connectfbpage";
import AllconnectedPagesHome from "components/AllconnectedPages/AllconnectedPagesHome";
import ConnectIntsapage from "components/InstagramComp/ConnectIntsapage";

import Tweet from "components/Twitter/Tweet";
import ForgetPassword from "views/auth/ForgetPassword";
import AudienceMembers from "views/admin/profile/components/AudienceMembers";

import Routess from "routes";
import { Nodemailer } from "components/Nodemailer/Nodemailer";
// import ResetPassword from "views/auth/signIn/ResetPassword";
function App() {
  const { storedUser } = useOrderStore();
  console.log(storedUser, "storedUser");
  
  // permission for al pages
  const permissions = {
    media: true,
    EmailCampaigns: true,
    Audiences: true,
    Emails: true,
    Editors: true,
    ApprovePost: true,
    Calendar: true,
    Manager: true,
    Profile: true,
    AllManager: true,
  };
  const permissiondata =
    storedUser.email && storedUser.role !== "Admin"
      ? storedUser?.PagesPermission[0]
      : permissions;
  const PermissionBased = Routess.filter((x) => {
    if (storedUser.role === "Admin") {
      return true;
    }
    if (permissiondata[x.layout]) {
      return true;
    } else {
      return false;
    }
  });
  // permission for  pages with ids
  const permissionwithpageid =
    storedUser.email && storedUser.role !== "Admin"
      ? storedUser?.PagesPermission[0]
      : permissions;

  let routewithid = [
    {
      name: "Audiences",
      layout: "Audiences",

      path: "/mymember/:id",
      component: <Mymembers />,
    },
    {
      name: "Nodemailer",
      layout: "Nodemailer",

      path: "/nodemailer",
      component: <Nodemailer />,
    },

    {
      name: "Editors",
      layout: "Editors",
      path: "/editor/:id",
      component: <Editor />,
    },
    {
      name: "Editors",
      layout: "media",
      path: "/connectedPages",
      component: <AllconnectedPagesHome />,
    },
    {
      name: "",
      layout: "media",
      path: "/connectfbpage",
      component: <Connectfbpage />,
    },
    {
      name: "Editors",
      layout: "media",
      path: "/connectInstapage",
      component: <ConnectIntsapage />,
    },
    {
      name: "Editors",
      layout: "media",
      path: "/Instapages",
      component: <Instagram />,
    },
    {
      name: "Editors",
      layout: "media",
      path: "/Facebookpages",
      component: <FacebookHome />,
    },
    {
      name: "Editors",
      layout: "media",
      path: "/AllinOne",
      component: <AllSocail />,
    },
    {
      name: "Editors",
      layout: "media",
      path: "/tweet",
      component: <Tweet />,
    },
  ];
  const PermissionIDBased = routewithid.filter((x) => {
    if (storedUser.role === "Admin") {
      return true;
    }
    if (permissionwithpageid[x.layout]) {
      return true;
    } else {
      return false;
    }
  });
  console.log(PermissionIDBased, "ye dekh", permissionwithpageid);

  return (
    <Router>
      <ToastContainer position="top-center" autoClose={2000} />
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/forget/password" element={<ForgetPassword />} />
        <Route path="/audience-members/:id" element={<AudienceMembers />} />
        {PermissionBased.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={storedUser.email ? route.component : <Navigate to="/" />}
          />
        ))}
        <Route
          path="/admin"
          element={storedUser.email ? <Dashboard /> : <Navigate to="/" />}
        />
        <Route
          path="/Social"
          element={storedUser.email ? <Social /> : <Navigate to="/" />}
        />

        {PermissionIDBased.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={storedUser.email ? route.component : <Navigate to="/" />}
          />
        ))}
        {/* <Route
              path="/mymember/:id"
              element={storedUser.email ? <Mymembers /> : <Navigate to="/" />}
            /> */}

        {/* <Route
              path="/UserProfileSetting"
              element={storedUser.email ? <UserProfileSettings/> : <Navigate to="/" />}
            />
            <Route
              path="/audiences"
              element={storedUser.email ? <Profile /> : <Navigate to="/" />}
            />
            <Route
              path="/emails"
              element={storedUser.email ? <EmailForm /> : <Navigate to="/" />}
            />
            <Route
              path="/rtl-default"
              element={storedUser.email ? <RtlLayout /> : <Navigate to="/" />}
            />
            <Route path="/nft-marketplace" element={<Marketplace />} />
            <Route
              path="/data-tables"
              element={storedUser.email ? <DataTables /> : <Navigate to="/" />}
            />
            <Route
              path="/editor"
              element={storedUser.email ? <Editor /> : <Navigate to="/" />}
            />
            <Route
              path="/calendar"
              element={
                storedUser.email ? <CalendarPage /> : <Navigate to="/" />
              }
            />
            <Route
              path="/approvePosts"
              element={
                storedUser.email ? <ApprovePosts /> : <Navigate to="/" />
              }
            />
            <Route
              path="/mymember/:id"
              element={storedUser.email ? <Mymembers /> : <Navigate to="/" />}
            />
            <Route
              path="/editor/:id"
              element={storedUser.email ? <Editor /> : <Navigate to="/" />}
            />
            <Route
              path="/Social"
              element={storedUser.email ? <Social /> : <Navigate to="/" />}
            />
            <Route
              path="/Instapages"
              element={storedUser.email ? <Instagram /> : <Navigate to="/" />}
            />
            <Route
              path="/Facebookpages"
              element={
                storedUser.email ? <FacebookHome /> : <Navigate to="/" />
              }
            />
            <Route
              path="/AllinOne"
              element={storedUser.email ? <AllSocail /> : <Navigate to="/" />}
            />
            <Route
              path="/AddManager"
              element={storedUser.email ? <Manager /> : <Navigate to="/" />}
            />
            <Route
              path="/AllManagers"
              element={storedUser.email ? <AllManagers /> : <Navigate to="/" />}
            />
            <Route
              path="/tweet"
              element={storedUser.email ? <Tweet /> : <Navigate to="/" />}
            />
            <Route
              path="*"
              element={
                storedUser.email ? <AllSocail /> : <Navigate to="/"></Navigate>
              }
            />
            <Route
              path="/connectfbpage"
              element={
                storedUser.email ? (
                  <Connectfbpage />
                ) : (
                  <Navigate to="/"></Navigate>
                )
              }
            />
            <Route
              path="/connectInstapage"
              element={
                storedUser.email ? (
                  <ConnectIntsapage />
                ) : (
                  <Navigate to="/"></Navigate>
                )
              }
            />

            <Route
              path="/connectedPages"
              element={
                storedUser.email ? (
                  <AllconnectedPagesHome />
                ) : (
                  <Navigate to="/"></Navigate>
                )
              }
            /> */}

        {/* } */}

        <Route
          path="*"
          element={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <h1
                style={{
                  marginLeft: "10px",
                  fontSize: "50px",
                  fontWeight: "bold",
                }}
              >
                You Are Not Authenticated For This Page
              </h1>
            </div>
          }
        ></Route>
      </Routes>
    </Router>
  );
}

export default App;
