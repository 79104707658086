import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Comment from "./Comment";
import { FaSpinner } from "react-icons/fa";

const Comments = ({ post, token, currentPageClicked }) => {
  //  "https://graph.facebook.com/v18.0/107866298396762_130458426137549/comments?access_token=EAAQzqZCVg9yIBO71NTEZCJhlB3s72zmWgAxNl3ZB6hZCZB4TJTFRxhA1CM0RiDCYkvKwvSiuHpfZAx0aAxSRKdITw28JGzPdjZCQkkQfyWfAxzYvDAZCfUCHDFZB8c02CAjYJNs7K8iwbdmj54S63MM3CZAqZCNKZCZBTUXbumX7Pq787f1hOHm5djG1uRSJ7kQJHqFfmgywmRx1Mc70fJ3bYxhM9CMCzK9pEvB4iF9h4BAPR"
  const [showcomm, setshowcom] = useState(false);
  const [comments, setcomments] = useState([]);
  const [message, setmessage] = useState("");
  const [messageloading, setmessagloading] = useState("");
  // console.log(post,showcomm,"new")
  // useEffect(() => {
  //   if (currentPageClicked ) {
  //     setshowcom(false)
  //     setcomments([])
  //     setmessage("")
  //   }
  // }, [currentPageClicked])
  
  const opencomment = async () => {
    setcomments([])
    if (showcomm) {
      // console.log("if");
      setshowcom(false);
    } else {
      console.log("else");
      setshowcom(true);
      // api to fetch comments
      try {
        const { data } = await axios.get(
          `https://graph.facebook.com/${post.id}/comments?fields=message,pic_small,from,created_time,id,comments{message,id,pic_small,from,created_time,parent.fields(id)}&access_token=${token}`
        );
        console.log(data.data, "ye data h");
        if (data.data) {
          setcomments(data?.data);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const handlesubmitcomment = async () => {
    try {
      setmessagloading(true);
      const data = await axios.post(
        `https://graph.facebook.com/v18.0/${post.id}/comments`,
        {
          message: message,
        },
        {
          params: {
            access_token: token,
          },
        }
      );
      console.log(data, "c");
      if (data) {
        console.log(data, "c");
        setmessagloading(false);
        let newcom = {
          message: message,
          from: {
            name: currentPageClicked?.name,
          },
          created_time: new Date(),
        };
        setcomments((prev) => [newcom, ...prev]);
        setmessage("");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div>
      <div onClick={opencomment} style={{ cursor: "pointer" }}>
        {" "}
        comments
      </div>
      <br />
      <div>
        {messageloading && <FaSpinner />}
        <input
        value={message}
          type="text"
          placeholder="write comment"
          onChange={(e) => setmessage(e.target.value)}
        />
        <button
          type="button"
          style={{
            background: "black",
            color: "white",
            margin: "10px",
            width: "100px",
            borderRadius: "10px",
          }}
          onClick={handlesubmitcomment}
        >
          submit
        </button>
      </div>

      {showcomm && (
        <ul>
          {comments.map((x) => (
            <Comment x={x} />
          ))}
        </ul>
      )}
    </div>
  );
};

export default Comments;
