import { CloseIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Box,
  
} from "@chakra-ui/react";
import axios from "axios";
import Layout from "layouts/Layout/Layout";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Editor from "views/Editor/Editor";
import useOrderStore from "contexts/Userhook";
const serverURL = process.env.REACT_APP_BACKEND_URL;

const Mymembers = () => {
  const { storedUser } = useOrderStore();
  const [rows, setrows] = useState([]);
  const params = useParams();
  const [deletaudiencesLoading, setdeletaudiencesLoading] = useState(false);
  const [SavingaudiencesLoading, setSavingaudiencesLoading] = useState(false);
  const [deleted, setdeleted] = useState(false);
  const [isEditing, setisEditing] = useState("");
  const navigate = useNavigate();
  const [editingdata, seteditingdata] = useState({
    firstName: "",
    lastName: "",
    address: "",
    emailAddress: "",
    phoneNumber: "",
    createdAt: "",
  });
  // const
  const [deleteaudiIndex, setdeleteaudiIndex] = useState("");
  const [getAudinceloading,setgetAudinceloading]=useState(false)
  const columns = [
    { id: "firstName", label: "firstName" },
    { id: "lastName", label: "lastName" },
    { id: "address", label: "address" },
    { id: "emailAddress", label: "emailAddress" },
    { id: "phoneNumber", label: "phoneNumber" },
    { id: "createdAt", label: "createdAt" },
    { id: "Actions", label: "Actions" },
  ];

  useEffect(() => {

    if (params.id) {
      async function fetchAudiences() {
      let Url= `${serverURL}/api/allmember/${params.id}`
        setgetAudinceloading(true)
        try {
          
          const { data } = await axios.get(
            `${Url}`
          );
          if (data) {
            setrows([]);
            console.log("DATA============>", data, "<===============DATA");
            // console.log()
            setrows([...data])
            // for (const i of data) {
            //   let mem = i.member;
            //   setrows((r) => [...r, mem]);
            //   console.log(mem);
            // }
            setgetAudinceloading(false)
          }
          
          //   setAudiences(response.data);
        } catch (error) {
          toast.error(error.message)
          setgetAudinceloading(false)
        }
      }
   
      fetchAudiences();
      return () => {
            setdeleted(false);
          };
    }
  }, [params.id,deleted]);
  // useEffect(() => {
  //   console.log("fected");
  //   if (deleted) {
  //     async function fetchAudiences() {
  //       try {
  //         const { data } = await axios.get(
  //           `${serverURL}/api/allmember/${params.id}`
  //         );
  //         if (data) {
  //           setrows([]);
  //           console.log(data, "rr");
  //           for (const i of data) {
  //             let mem = i.member;
  //             setrows((r) => [...r, mem]);
  //             console.log(mem);
  //           }
  //         }

  //         //   setAudiences(response.data);
  //       } catch (error) {
  //         console.error("Error fetching audiences:", error);
  //       }
  //     }

  //     fetchAudiences();
  //   }
  //   return () => {
  //     setdeleted(false);
  //   };
  // }, [deleted, params.id]);
  console.log(rows, "row");
  const handleSelectAudience =() => {
    
    new Promise((resolve,reject)=>{
      axios.get(`${serverURL}/api/select/allmember/${params.id}`).then((response)=>{
        resolve(response.data);
        navigate(`/editor?audienceid=${params.id}`);
      }).catch((error)=>{
        reject(error);
        toast.error(error.message)
      })
          
    })
    // try {
    //   const { data } = await axios.get(
    //     `${serverURL}/api/select/allmember/${params.id}`
    //   );
    //   if (data) {
    //     const emails = data.map((item) => item.member.emailAddress).join(",");
    //     // const name = data.map((item) => item.member.firstName).join(",");
    //     console.log(emails);

    //     navigate(`/editor?audienceid=${params.id}`);
    //   }
    // } catch (error) {
    //   console.error("Error fetching audiences:", error);
    // }
  };
  // api to delete user
  const DeleteAudience = async (x, i) => {
    setdeleteaudiIndex(i);
    try {
      setdeletaudiencesLoading(true);
      const { data } = await axios.delete(
        `${serverURL}/api/deletemembers/${x}?group=${params.id}`
      );
      if (data.message === "Member deleted successfully") {
        setdeleted(true);
        setdeletaudiencesLoading(false);
        toast.success(data.message);
        setdeleteaudiIndex("");
      }
    } catch (error) {
      let err =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      toast.error(err);
      setdeletaudiencesLoading(false);
      setdeleteaudiIndex("");
    }
  };

  const ADDAudiencefunc = (x, index) => {
    setisEditing("");
    console.log(x);
    seteditingdata(x);

    setisEditing(index);
  };
  const Cancel = () => {
    setisEditing("");
  };

  const myeditingchange = (e) => {
    console.log(e);
    seteditingdata((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const SaveEditing = async (id) => {
    try {
      setSavingaudiencesLoading(true);

      const { data } = await axios.put(
        `${serverURL}/api/updatingmember/${id}`,
        { ...editingdata, Audienceid: params.id }
      );
      console.log(data);
      if (data.updated._id) {
        setrows((x) => {
          let newarr = x.map((x) => (x._id === id ? { ...editingdata } : x));
          return newarr;
        });
        setisEditing("");
        setSavingaudiencesLoading(false);
        toast.success(data.message);
      }
    } catch (error) {
      let err =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      setSavingaudiencesLoading(false);
      toast.error(err);
    }
  };
  return (
    <Layout>
      <Box display="flex" justifyContent="flex-end">
        <Button onClick={handleSelectAudience} colorScheme="green">
          Select This audience
        </Button>
      </Box>
      <Table
        size="sm"
        variant="simple"
        borderWidth="2px"
        borderColor="gray.300"
        borderRadius="md"
      >
        <TableCaption placement="top" fontWeight="bold" fontSize="md">
          My Members &nbsp; {getAudinceloading&&<Spinner/>}
        </TableCaption>

        <Thead>
          <Tr>
            {columns.map((column) => (
              <Th
                key={column.id}
                bg="#6AD2FF"
                color="white"
                fontWeight="bold"
                fontSize="sm"
                p={2}
              >
                {column.label}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rows.map((x, index) => (
            <>
              {isEditing === index ? (
                <Tr key={index}>
                  <Td>
                    <FormControl>
                      <FormLabel>First Name</FormLabel>
                      <Input
                        placeholder="First Name"
                        size="sm"
                        width="80%"
                        value={editingdata?.firstName}
                        onChange={myeditingchange}
                        name="firstName"
                      />
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl>
                      <FormLabel>Last Name</FormLabel>
                      <Input
                        placeholder="Last Name"
                        size="sm"
                        width="80%"
                        value={editingdata?.lastName}
                        name="lastName"
                        onChange={myeditingchange}
                      />
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl>
                      <FormLabel>Address</FormLabel>
                      <Input
                        placeholder="Address"
                        size="sm"
                        width="80%"
                        value={editingdata?.address}
                        name="address"
                        onChange={myeditingchange}
                      />
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl>
                      <FormLabel> Email Address</FormLabel>
                      <Input
                        placeholder="Email Address"
                        size="sm"
                        width="80%"
                        value={editingdata?.emailAddress}
                        name="emailAddress"
                        onChange={myeditingchange}
                      />
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl>
                      <FormLabel>Phone Number</FormLabel>
                      <Input
                        placeholder="Phone Number"
                        size="sm"
                        width="80%"
                        value={editingdata?.phoneNumber}
                        name="phoneNumber"
                        onChange={myeditingchange}
                      />
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl>
                      <FormLabel>Created At</FormLabel>
                      <Input
                        placeholder="Created At"
                        size="sm"
                        width="80%"
                        value={editingdata?.createdAt}
                        name="createdAt"
                        onChange={myeditingchange}
                      />
                    </FormControl>
                  </Td>
                  <Td>
                    <Td
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        Cancel(x._id, index);
                      }}
                    >
                      Cancel
                    </Td>
                    <Td
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        SaveEditing(x._id, index);
                      }}
                    >
                      Save
                    </Td>
                    {SavingaudiencesLoading && isEditing === index && (
                      <Td>
                        <Spinner color="red.500" />
                      </Td>
                    )}
                  </Td>
                </Tr>
              ) : (
                <Tr key={index}>
                  <Td>{x?.firstName || "?"}</Td>
                  <Td>{x?.lastName || "?"}</Td>
                  <Td>{x?.address || "?"}</Td>
                  <Td>{x?.emailAddress || "?"}</Td>
                  <Td>{x?.phoneNumber || "?"}</Td>
                  <Td>{new Date(x?.createdAt).toLocaleString() || "?"}</Td>
                  <Td>
                    <Td
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        ADDAudiencefunc(x, index);
                      }}
                    >
                      <EditIcon />
                    </Td>
                    <Td></Td>
                    <Td
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        DeleteAudience(x._id, index);
                      }}
                    >
                      <DeleteIcon />
                    </Td>
                    {deletaudiencesLoading && deleteaudiIndex === index && (
                      <Td>
                        <Spinner color="red.500" />
                      </Td>
                    )}
                  </Td>
                </Tr>
              )}
            </>
          ))}
        </Tbody>
      </Table>
    </Layout>
  );
};

export default Mymembers;
