import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdOutlineShoppingCart,
  MdEmail,
  MdToday,
  MdAccessTime,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import CalendarPage from "./components/calendar/CalendarPage";
import DataTables from "views/admin/dataTables";
import ApprovePosts from "components/ApprovePosts/ApprovePosts";
import { EditIcon } from "@chakra-ui/icons";
import { FaFacebook } from "react-icons/fa";
import { GrUserManager } from "react-icons/gr";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import Manager from "components/AddManager/Manager";
import AllManagers from "components/AddManager/AllManagers";
import Tweet from "components/Twitter/Tweet";
import { ProfileIcon } from "components/icons/Icons";
import UserProfileSettings from "views/auth/UserProfileSettings";
import Profile from "./views/admin/profile/index";
import EmailForm from "views/Emails/EmailForm";
import Editor from "views/Editor/Editor";
import Marketplace from "views/admin/marketplace";
import Dashboard from "layouts/admin";
import Mymembers from "views/admin/Members.js/Mymembers";
import { Nodemailer } from "components/Nodemailer/Nodemailer";
const Routess = [
  {
    name: "Main Dashboard",
    layout: "admin",
    path: "/admin",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <Dashboard />,
  },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   component: <Marketplace />,
  //   secondary: true,
  // },
  {
    name: "Email Campaigns",
    layout: "EmailCampaigns",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/data-tables",
    component: <DataTables />,
  },
  {
    name: "Audiences",
    layout: "Audiences",
    path: "/audiences",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <Profile />,
  },

  // {
  //   name: "Log out",
  //   layout: "/logout",
  //   path: "/Logout",
  //   icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   component: Profile,

  // },

  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   component: RTL,
  // },
  {
    // category:"logout",
    name: "EMAILS",
    layout: "Emails",
    icon: <Icon as={MdEmail} width="20px" height="20px" color="inherit" />,
    path: "/emails",
    component: <EmailForm />,
  },
  {
    // category:"logout",
    name: "Editor",
    layout: "Editors",
    icon: <Icon as={EditIcon} width="20px" height="20px" color="inherit" />,
    path: "/editor",
    component: <Editor />,
  },
  // {
  //   // category:"logout",
  //   name: "Social",
  //   layout: "/admin",
  //   icon: (
  // <Icon
  //   as={IoShareSocialOutline}
  //   width="20px"
  //   height="20px"
  //   color="inherit"
  // />
  //   ),
  //   path: "/Social",
  // },
  {
    name: "Calendar",
    layout: "Calendar",
    icon: <Icon as={MdToday} width="20px" height="20px" color="#8F9BBA" />,
    path: "/calendar",
    component: <CalendarPage />,
  },
  {
    name: "ApprovePost",
    layout: "ApprovePost",
    icon: <Icon as={MdAccessTime} width="20px" height="20px" color="#8F9BBA" />,
    path: "/approvePosts",
    component: <ApprovePosts />,
  },
  {
    name: "Add Manager",
    layout: "Manager",
    icon: (
      <Icon as={GrUserManager} width="20px" height="20px" color="#8F9BBA" />
    ),
    path: "/AddManager",
    component: <Manager />,
  },
  {
    name: "Service Provider",
    layout: "Nodemailer",
    icon: (
      <Icon as={GrUserManager} width="20px" height="20px" color="#8F9BBA" />
    ),
    path: "/nodemailer",
    component: <Nodemailer />,
  },
  {
    name: "Profile",
    layout: "Profile",
    icon: <Icon as={ProfileIcon} width="20px" height="20px" color="#8F9BBA" />,
    component: <UserProfileSettings />,
    path: "/UserProfileSetting",
  },
  {
    name: "All Managers",
    layout: "AllManager",
    icon: (
      <Icon as={FaPeopleGroup} width="20px" height="20px" color="#8F9BBA" />
    ),
    path: "/AllManagers",
    component: <AllManagers />,
  },

  // {
  //   name: "AllSocail",
  //   layout: "/admin",
  //   icon: <Icon as={MdToday} width="20px" height="20px" color="#8F9BBA" />,
  //   path: "/AllSocail",
  //   component: CalendarPage,
  // },

  // {
  //   name: "Log out",
  //   layout: "/",
  //   icon: <Icon as={MdEmail} width="20px" height="20px" color="inherit" />,
  //   path: "/logout",
  //   component: DataTables,
  // },
];

export default Routess;
