import React, { useState } from "react";
import Layout from "layouts/Layout/Layout";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Radio,
  Switch,
  Select,
} from "@chakra-ui/react";

import { toast } from "react-toastify";
import { FcManager } from "react-icons/fc";
import axios from "axios";
import useOrderStore from "contexts/Userhook";
import { useNavigate } from "react-router-dom";

const server = process.env.REACT_APP_BACKEND_URL;
const serverFront = process.env.REACT_APP_FRONTEND_URL;
const Manager = () => {
  const { storedUser } = useOrderStore();
  const [userData, setUserData] = useState({
    media: false,
    EmailCampaigns: false,
    Audiences: false,
    Emails: false,
    Editors: false,
    ApprovePost: false,
    Calendar: false,
    Manager: false,
    Profile: false,
    AllManager: false,
    name: "",
    email: "",
    password: "",
    isEmailAllowd: false,
    role:"manager"
  });

  let permission = [
    "Profile",
    "AllManager",
    "media",
    "EmailCampaigns",
    "Audiences",
    "Emails",
    "Editors",
    "Calendar",
    "ApprovePost",
    "Manager",
    "isEmailAllowd",
  ];

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    console.log(name, name === "media", "name", value, checked, typeof checked);
    if (permission.includes(name)) {
      console.log("truejh");
      setUserData((prevData) => ({ ...prevData, [name]: checked }));
      return;
    }

    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    let { name, email, password, ...other } = userData;
    console.log(userData);
    console.log(e);
    if (!userData.name || !userData.email || !userData.password) {
      console.log(userData);
      toast.error("All fields are required");
      return;
    }

    if (!isEmailValid(userData.email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(`${server}/auth/Registration`, {
        name: userData.name,
        email: userData.email,
        password: userData.password,
        role: userData.role,
        roleby: storedUser._id,
        permissions: other,
        isEmailAllowd: userData.isEmailAllowd,
        link: `${serverFront}`,
      });

      console.log(response.data);

      if (response.status === 201) {
        toast.success("Manager created successfully");
        navigate("/AllManagers");
      }
    } catch (error) {
      let err =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      toast.error(err);
      console.error("Error during registration:", error);
    } finally {
      setLoading(false);
    }
  };
const handleTemplateChange=(e)=>{
  console.log(e.target.value)
  setUserData((prev)=>({...prev,role:e.target.value}))

}
  return (
    <Layout>
      <VStack align="center">
        <FcManager fontSize={200} />
      </VStack>
      <VStack spacing={4} align="center">
        <FormControl id="name" w="80%">
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            name="name"
            value={userData.name}
            onChange={handleInputChange}
            required
          />
        </FormControl>

        <FormControl id="email" w="80%">
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            name="email"
            value={userData.email}
            onChange={handleInputChange}
            required
          />
        </FormControl>

        <FormControl id="password" w="80%">
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            name="password"
            value={userData.password}
            onChange={handleInputChange}
            required
          />
        </FormControl>
        <FormControl id="select" w="80%">
        
        <Select onChange={handleTemplateChange}>
              <option value={""}>Select a Role</option>

              {["Admin","manager"].map((template) => (
                <>
                  <option
                    key={template}
                    value={template}
                    
                    style={{color:"black"}}
                    >
                    {template}
                    
                  </option>
                  
                 
                </>
              ))}
            </Select>
          
        </FormControl>
        <FormControl w="80%">
          <FormLabel htmlFor="email-alerts" mb="0">
            Enable Email Campaigns
          </FormLabel>
          <Switch
            id="email-alerts"
            name="EmailCampaigns"
            isChecked={userData.EmailCampaigns}
            value={userData.EmailCampaigns}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl w="80%">
          <FormLabel htmlFor="Audiences" mb="0">
            Enable Audiences
          </FormLabel>
          <Switch
            id="Audiences"
            name="Audiences"
            isChecked={userData.Audiences}
            value={userData.Audiences}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl w="80%">
          <FormLabel htmlFor="Emails" mb="0">
            Enable Emails
          </FormLabel>
          <Switch
            id="Emails"
            name="Emails"
            isChecked={userData.Emails}
            value={userData.Emails}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl w="80%">
          <FormLabel htmlFor="Editors" mb="0">
            Enable Editors
          </FormLabel>
          <Switch
            id="Editors"
            name="Editors"
            isChecked={userData.Editors}
            value={userData.Editors}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl w="80%">
          <FormLabel htmlFor="Calendar" mb="0">
            Enable Calendar
          </FormLabel>
          <Switch
            id="Calendar"
            name="Calendar"
            isChecked={userData.Calendar}
            value={userData.Calendar}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl w="80%">
          <FormLabel htmlFor="ApprovePost" mb="0">
            Enable Approve Post
          </FormLabel>
          <Switch
            id="ApprovePost"
            name="ApprovePost"
            isChecked={userData.ApprovePost}
            value={userData.ApprovePost}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl w="80%">
          <FormLabel htmlFor="Manager" mb="0">
            Add Manager
          </FormLabel>
          <Switch
            id="Manager"
            name="Manager"
            isChecked={userData.Manager}
            value={userData.Manager}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl w="80%">
          <FormLabel htmlFor="Profile" mb="0">
            Enable Profile
          </FormLabel>
        </FormControl>
        <FormControl w="80%">
          <Switch
            id="Profile"
            name="Profile"
            isChecked={userData.Profile}
            value={userData.Profile}
            onChange={handleInputChange}
          />
          <FormLabel htmlFor="AllManager" mb="0">
            Enable All Manager
          </FormLabel>
        </FormControl>
        <FormControl w="80%">
          <Switch
            id="AllManager"
            name="AllManager"
            isChecked={userData.AllManager}
            value={userData.AllManager}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl w="80%">
          <FormLabel htmlFor="Social" mb="0">
            Enable Social
          </FormLabel>
          <Switch
            id="Social"
            name="media" // Corrected name attribute
            isChecked={userData.Socail}
            value={userData.Socail}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl w="80%">
          <FormLabel htmlFor="Social" mb="0">
            Is email Allow
          </FormLabel>
          <Switch
            id="Social"
            name="isEmailAllowd" // Corrected name attribute
            isChecked={userData.isEmailAllowd}
            value={userData.isEmailAllowd}
            onChange={handleInputChange}
          />
        </FormControl>

        <Button colorScheme="teal" onClick={handleSubmit} w="80%">
          Create Manager
        </Button>
        <Modal isOpen={loading} onClose={() => setLoading(false)} isCentered>
          <ModalOverlay />
          <ModalContent>
            {/* <ModalCloseButton /> */}
            <ModalBody textAlign="center">
              <Spinner size="xl" />
            </ModalBody>
          </ModalContent>
        </Modal>
      </VStack>
    </Layout>
  );
};

export default Manager;
