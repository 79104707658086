import { Input } from "@chakra-ui/react";
import axios, { all } from "axios";

import useOrderStore from "contexts/Userhook";
import Layout from "layouts/Layout/Layout";
import React, { useCallback, useEffect, useState } from "react";
import { FaInstagram, FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";
import { CreatePostSocail } from "./CreatePostSocail";
import { Fbpage } from "./FbAllcomponenet/FbAll";
import { CgInstagram } from "react-icons/cg";
import { ConnectedFbPgeApi } from "components/Facebook/Connectedpageapi/ConnectedfbpageApi";

const ServerUrl = process.env.REACT_APP_BACKEND_URL;
const AllSocail = () => {
  const { storedUser } = useOrderStore();
  const [dataloading, setdataloading] = useState(false);
  const [pagesloading, setpagesloading] = useState(false);
  const [allpages, setallpages] = useState([]);
  const [allpost, setallpost] = useState([]);
  const [isposts, setispost] = useState(false);
  const [token, settoken] = useState("");
  const [currentPageDetails, setcurrentPageDetails] = useState({});

  const [FbPageIds, setFbPageIds] = useState([]);
  const [InstaPageIds, setInstaPageIds] = useState([]);
  const [PageIdsInsta, setPageIdsInsta] = useState([]);
  const gettingpageapi = useCallback(async (userid, token) => {
    try {
      setpagesloading(true);
      const { data } = await axios.get(
        `https://graph.facebook.com/v18.0/${userid}/accounts?access_token=${token}&fields=access_token,id,name,cover,category,likes,fan_count,instagram_business_account`
      );
      
      if (data) {
        setpagesloading(false);
        const mypages = async () => {
          let mydata = await ConnectedFbPgeApi(
            ServerUrl,
            storedUser,
            setdataloading
          );
          if (mydata.length > 0) {
            console.log(mydata, "yes e andar");
            // const uIds = data.map((item) => item.id);
            const uIds = mydata
            .filter((x) => x.Instagram)
            .map((item) => item.id);
            console.log(mydata,"bata kiya ata h")
            const allConnected = data?.data.filter((x) => uIds.includes(x?.instagram_business_account?.id)).map((x)=>({...x,instagram_account:"insta"}));
            // console.log(allConnected, "yes me andar");
            // fb down
            
            const uIdfb = mydata
            .filter((x) => !x.Instagram)
            .map((item) => item.id);
            const allConnectedfb = data?.data.filter((x) => uIdfb.includes(x?.id));
            console.log(allConnectedfb, "yes me andar fb ky");

            setallpages([...allConnected,...allConnectedfb]);
          } else {
            setallpages([]);
            toast.warn("please connect the page");
          }
        };
        mypages();
        // const uIds = data.filter((x)=>!x.Instagram).map((item) => item.id);
        // setallpages(data.data);
      }
      // if (data) {
      //   // console.log(data.data, "token 29");
      //   const pagesWithLikesAndFollowers = data.data.map((page) => ({
      //     ...page,
      //     likes: page.likes,
      //     followers: page.fan_count,
      //   }));
      //   const mypages=async()=>{
      //     let data= await ConnectedFbPgeApi(ServerUrl,storedUser,setdataloading)
      //     if (data.length>0) {
      //       const uIds = data.filter((x)=>!x.Instagram).map((item) => item.id);
      //         // const uIds = data.map((item) => item.id);
      //            const allConnected = pagesWithLikesAndFollowers.filter((x) => uIds.includes(x.id));
      //           //  console.log(allConnected,"here")
      //            setallpages(allConnected);
              
      //     }else{
      //         setallpages([]);
      //         toast.warn("please connect the page")

      //     }
      //     await GetInstaMediaWithConnectedPage(pagesWithLikesAndFollowers);
      //     }
      //     mypages()
      //   // setallpages(pagesWithLikesAndFollowers);

      //   setpagesloading(false);
      // }
    } catch (error) {
      //   console.log(error);
      let err =
        error.message && error.response.data.error.message
          ? error.response.data.error.message
          : error.message;
      toast.error(err);
      setpagesloading(false);
    }
  }, [storedUser]);

  useEffect(() => {
    // For the facebook
    const mydata = async () => {
      await Fbpage(ServerUrl, setdataloading, storedUser, gettingpageapi);
    };
    mydata();
  }, [gettingpageapi, storedUser]);

  const GetInstaMediaWithConnectedPage = async (x) => {
    console.log(x, "aya");
    for (const i of x) {
      try {
        const { data } = await axios.get(
          `https://graph.facebook.com/v18.0/${i?.id}?fields=instagram_business_account,name&access_token=${i?.access_token}`
        );

        if (data.instagram_business_account) {
          console.log(data, "sha");
          setallpages((prev) => [
            ...prev,
            { ...data, access_token: i.access_token },
          ]);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };


  const CheckBox = (x) => {
    console.log("insta",InstaPageIds)
    if (x.instagram_account!=="insta") {
      const Exist = FbPageIds?.find((y) => y.id === x.id);
      if (Exist) {
        setFbPageIds((p) => {
          return p.filter((z) => z.id !== x.id);
        });
      } else {
        setFbPageIds((p) => {
          return [...p, x];
        });
      }
    } else if (x.instagram_account==="insta") {
      const Exist = InstaPageIds?.find((y) => y.id === x.id);
      if (Exist) {
        setInstaPageIds((p) => {
          return p.filter((z) => z.id !== x.id);
        });
      } else {
        setInstaPageIds((p) => {
          return [...p, x];
        });
      }
    }
  };

  console.log(FbPageIds, "ye all page ye");

  return (
    <Layout>
      {dataloading && <FaSpinner className="mx-auto mt-4 text-4xl" />}
      <div className="text-center text-4xl font-bold mb-4">Pages</div>
      {pagesloading && <FaSpinner className="mx-auto mt-4 text-4xl" />}
      <div className="flex flex-wrap mx-5">
        {allpages.length > 0
          ? allpages?.map((x) => (
              <div
                key={x.id}
                className="mr-10 my-5 p-5 w-72 border-2 border-black h-[80px] flex  "
              >
                {/* <Input type='checkbox'></Input> */}
                <input
                  type="checkbox"
                  className="mr-[10px]"
                  onClick={() => CheckBox(x)}
                ></input>
                <ul className="flex">
                  {x.instagram_account==="insta" ? (
                    <FaInstagram className="mb-2 mr-[15px]"></FaInstagram>
                  ) : (
                    <li className="mb-2 mr-[15px]">
                      <img
                        src={x?.cover?.source}
                        alt="coverphoto"
                        className="mb-4 h-[50px] w-[50px] "
                      />
                    </li>
                  )}
                  {/* <li className="mb-2"> {x.instagram_business_account&&<CgInstagram/>}</li> */}
                  <li className="mb-2"> {x.name}</li>
                </ul>
              </div>
            ))
          : "No Pages are Available"}
      </div>

      <CreatePostSocail
        token={token}
        currentPageClicked={currentPageDetails}
        FbPageIds={FbPageIds}
        InstaPageIds={InstaPageIds}
      />
    </Layout>
  );
};

export default AllSocail;
