import { Button } from '@chakra-ui/react';
import axios from 'axios';
import { Fbpage } from 'components/AllScocail/FbAllcomponenet/FbAll';
import useOrderStore from 'contexts/Userhook';
import Layout from 'layouts/Layout/Layout'
import React, { useCallback, useEffect, useState } from 'react'
import { FaInstagram, FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { ConnectedFbPgeApi, ConnectedInstaPgeApi } from './Connectedpageapi/ConnectedinstapageApi';
import { useNavigate } from 'react-router-dom';
const ServerUrl = process.env.REACT_APP_BACKEND_URL;
const ConnectIntsapage = () => {
    const { storedUser } = useOrderStore();
    const [dataloading, setdataloading] = useState(false);
    const [pagesloading, setpagesloading] = useState(false);
    const [allpages, setallpages] = useState([]);
    const [ConnectingPageLoading, setConnectingPageLoading] = useState(false);
     const [FbPageIds, setFbPageIds] = useState([]);

 

    const gettingpageapi = useCallback(async (userid, token) => {
        try {
          setpagesloading(true);
          const { data } = await axios.get(
            `https://graph.facebook.com/v18.0/${userid}/accounts?access_token=${token}&fields=access_token,id,name,cover,category,instagram_business_account`
          );
          if (data) {
               console.log(data.data,"ye raha ")
            //    if (data.length>0) {
            //     const uIds = data.filter((x)=>!x.Instagram).map((item) => item.id);
                
            //        const allConnected = pagesWithLikesAndFollowers.filter((x) => !uIds.includes(x.id));
            //        setallpages(allConnected);
                
            // }else{
            //     setallpages(pagesWithLikesAndFollowers);

            // }
                 const mypages=async()=>{
                let mydata= await ConnectedInstaPgeApi(ServerUrl,storedUser,setdataloading)
                const Exist = data?.data
                ?.map((item) => item?.instagram_business_account?.id)
                .filter((id) => id !== undefined);
                console.log(Exist,"mydata")
                if (Exist.length>0) {
                  const uIds = mydata
                  ?.map((item) => item?.instagram_business_account?.id)
                  .filter((id) => id !== undefined);
                  console.log(uIds,"conncted")
                    
                      //  const allConnected = data.data.filter((x) => !uIds.includes(x.instagram_business_account?.id));
                      const allConnected = data.data.filter((x)=>{
                        if (x.instagram_business_account?.id&&!uIds.includes(x.instagram_business_account?.id)) {
                          // let exsit=data.data.filter((x) => !uIds.includes(x.instagram_business_account?.id))
                          return x
                          
                        }else{
                          return null
                        }

                       });
                       setallpages(allConnected);


                      console.log(allConnected,"here all cone")
                
                
                }else{
                  // const uIds = data?.data?.map((item) => item?.instagram_business_account?.id)
                  // .filter((id) => id !== undefined);
                  // const allConnected = data.data.filter((x) => uIds.includes(x.instagram_business_account?.id));
                  // console.log(allConnected,"l")
                  //   setallpages(allConnected);
                    setallpages([]);

                }
                }
                mypages()
            
        
  
            
    
            setpagesloading(false);
          }
        } catch (error) {
         
          let err =
            error.message && error.response.data.error.message
              ? error.response.data.error.message
              : error.message;
          toast.error(err);
          setpagesloading(false);
        }
      }, [storedUser]);
    
    

      useEffect(() => {
       
    
     

        // For the facebook
        const mydata = async () => {
          await Fbpage(ServerUrl, setdataloading, storedUser, gettingpageapi);
          console.log("done fb 2")
        };
        mydata();
      }, [gettingpageapi, storedUser]);
    

  



    const CheckBox = (x) => {
      let instaid=x?.instagram_business_account?.id
          const Exist = FbPageIds?.find((y) => y.id === instaid);
          if (Exist) {
            setFbPageIds((p) => {
              return p.filter((z) => z.id !== instaid);
            });
          } else {
            setFbPageIds((p) => {
              
              console.log(instaid,"aya??")
              return [...p, {...x,id:instaid,Instagram:true}];
            });
          }
        
       
      };

console.log(FbPageIds,"see all")
const navigate=useNavigate()
const Connect=async()=>{
    try {
        setConnectingPageLoading(true)
        const data=await axios.post(
            `${ServerUrl}/fb/ConnectPage`,{ConnectedPage:FbPageIds,user:storedUser._id}
          );
          if (data) {
            navigate("/connectedPages")
          }
        
    } catch (error) {
        
    }
      
   

      }
  return (
    <Layout>
    {dataloading && <FaSpinner className="mx-auto mt-4 text-4xl" />}
    <div className="text-center text-4xl font-bold mb-4">Connect the Pages</div>
    {pagesloading && <FaSpinner className="mx-auto mt-4 text-4xl" />}
    <Button onClick={Connect} disabled={FbPageIds.length>0?false:true} bg={"blue.200"}>Connect</Button>
    <div className="flex flex-wrap mx-5">
      {allpages.length > 0
        ? allpages?.map((x) => (
            <div
              key={x.id}
              className="mr-10 my-5 p-5 w-72 border-2 border-black h-[80px] flex  "
            >
              {/* <Input type='checkbox'></Input> */}
              <input
                type="checkbox"
                className="mr-[10px]"
                onClick={() => CheckBox(x)}
              ></input>
              <ul className="flex">
             
                  <li className="mb-2 mr-[15px]">
                   <FaInstagram/>
                  </li>
                
                {/* <li className="mb-2"> {x.instagram_business_account&&<CgInstagram/>}</li> */}
                <li className="mb-2"> {x.name}</li>
              </ul>
            </div>
          ))
        : "No Pages are Available"}
    </div>

    
  </Layout>
  )
}

export default ConnectIntsapage