import {
  Box,
  Button,
  FormControl,
  Input,
  Select,
  FormLabel,
} from "@chakra-ui/react";
import axios from "axios";
import useOrderStore from "contexts/Userhook";

import Layout from "layouts/Layout/Layout";
import React, { useState } from "react";
import { toast } from "react-toastify";

const services = ["Outlook365", "gmail", "yahoo", "Zoho"];
export const Nodemailer = () => {
  const { storedUser } = useOrderStore();
  const [mydata, setDate] = useState({
    password: "",
    email: "",
    service: "Outlook365",
  });
  const Mydata = (e) => {
    const { value } = e.target;
    const { name } = e.target;
    console.log(name, "name", value);
    if (name) {
      setDate((pre) => ({ ...pre, [name]: value }));
    } else {
      setDate((pre) => ({ ...pre, service: value }));
    }

    //     ...prev
    //    [name]:value
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        "http://localhost:4000/email/nodemailerRoute",
        {
          Providers: mydata,
          user: storedUser._id,
        }
      );
      if (data) {
        toast.success("Email has been Saved");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <Layout>
      <Box maxW="400px" ml={20}>
        <FormControl>
          <FormLabel mt={20}>Email Service</FormLabel>
          <Select onChange={Mydata} mb={5} bg="gray.300">
            {services.map((x) => (
              <option value={x}>{x}</option>
            ))}
          </Select>

          <FormLabel>Username</FormLabel>
          <Input
            name="email"
            placeholder="email"
            onChange={Mydata}
            mb={5}
            bg="gray.300"
          />
          <FormLabel>Password</FormLabel>
          <Input
            name="password"
            type="password"
            placeholder="password"
            onChange={Mydata}
            mb={5}
            bg="gray.300"
          />
          {/* <FormLabel>Sender Email</FormLabel>
          <Input
            name="emailTo"
            placeholder="emailTo"
            onChange={Mydata}
            mb={5}
            bg="gray.300"
          /> */}

          {/* <FormLabel>Message</FormLabel>
          <Input
            name="message"
            placeholder="message"
            onChange={Mydata}
            mb={5}
            bg="gray.300"
          /> */}

          <Button
            type="submit"
            onClick={SubmitForm}
            color={"white"}
            backgroundColor={"#0B1EFC"}
          >
            Sumbit
          </Button>
        </FormControl>
      </Box>
    </Layout>
  );
};
