import React, { useState } from "react";

import { CreatePost } from "./CreatePost/CreatePost";

import { Dropdown, message, Space } from "antd";
import { FaCaretDown, FaSpinner } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import Likes from "./Likes";
import Comments from "./comments/Comments";
import useOrderStore from "../../contexts/Userhook";
import { useNavigate } from "react-router-dom";

const Posts = ({ allpost, token, currentPageClicked, setallpost }) => {
  const { storedUser } = useOrderStore();
  console.log(allpost, "all try---------->");
  const isManager = storedUser && (storedUser.role === "manager"&&!storedUser.socailpermission);
  // console.log(allpost, "allpost compo",storedUser.role === "manager"&&storedUser.socailpermission);

  const [deleteloading, setdeleteloading] = useState(false);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const handleclick = async (key) => {
    console.log(key);
    // if (key==="1") {
    try {
      setdeleteloading(true);
      const { data } = await axios.delete(
        `https://graph.facebook.com/v18.0/${key}?access_token=${token}`
      );
      console.log(data, "see data");
      if (data) {
        console.log(data, "see data");

        const updatedPosts = allpost.data.filter((post) => post.id !== key);

        // Assuming allpost is an object with a 'data' property

        setallpost({ data: updatedPosts });
        setdeleteloading(false);
        // console.log()
      }
    } catch (error) {
      toast.error(error.message);
      setdeleteloading(false);
    }

    // }
  };

  const items = [
    {
      label: "Delete",
      key: "1",
    },
  ];
  const Mediadata = (x) => {
    let datatype = x?.attachments?.data[0]?.type;
    let dataurl = x?.attachments?.data[0];
    console.log(datatype, x, dataurl);
    if (datatype === "photo" || datatype === "album") {
      return (
        <img
          style={{
            borderRadius: "10px",
            margin: "10px",
            width: "250px",
            height: "200px",
          }}
          src={dataurl?.media?.image?.src}
          alt="photopic"
        />
      );
    } else if (datatype === "video_inline" || datatype === "video_autoplay") {
      return <video src={dataurl?.media.source} controls />;
    }
  };

  // OpenPost


  const OpenPost=(x)=>{
    console.log(x,"u")
    let url=x?.attachments?.data[0].url
    if(url)
    window.open(url)

  }

  return (
    <>
      {/* {isManager  ? (
        <div
          style={{
            color: "black",
          }}
        >
          <CreatePost token={token} currentPageClicked={currentPageClicked} />
          <div
            style={{
              textAlign: "center",
              fontSize: "30px",
              color: "black",
              fontWeight: "bolder",
            }}
          >
            You Are A Manager. Facebook Posts Are Not Visible.
          </div>
        </div>
      ) : ( */}
        <>
          <div
            style={{
              textAlign: "center",
              fontSize: "30px",
              color: "black",
              fontWeight: "bolder",
            }}
          >
            Posts
          </div>
          <CreatePost token={token} currentPageClicked={currentPageClicked} />
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {deleteloading && <FaSpinner />}
            {allpost?.posts?.map((x) => (
              <div key={x.id} style={{margin:"5px"}}>
                {" "}
                <ul
                  style={{
                    marginRight: "30px",
                    margin: "10px",
                    padding: "10px",
                    width: "300px",
                    minHeight: "100px",
                    height: "100%",
                    borderRadius: "5px",
                    border: "2px solid black",
                    
                  }}
                  
                >
                  <li>
                    <Dropdown
                      menu={{ items, onClick: () => handleclick(x.id) }}
                    >
                      <Space>
                        <FaCaretDown />
                      </Space>
                    </Dropdown>
                  </li>
                  <li style={{cursor:"pointer"}} onClick={()=>OpenPost(x)}>
                    View this Post
                  </li>

                  <li style={{ display: "flex", alignItems: "center" }}>
                    {
                      <img
                        style={{ borderRadius: "50px", margin: "10px" }}
                        src={x?.from?.picture?.data?.url}
                        alt="profile"
                      />
                    }
                    {x.from.name}
                  </li>
                  <li>{x.message}</li>
                  <li>{Mediadata(x)}</li>

                  <hr />
                  <hr />

                  <div>
                    <Likes post={x} token={token} />
                  </div>

                  <div>
                    <Comments
                      post={x}
                      token={token}
                      currentPageClicked={currentPageClicked}
                    />
                    <p className="text-sm">
                      Created At: {formatDate(x.created_time)}
                    </p>
                  </div>
                </ul>
              </div>
            ))}
          </div>
        </>
    
    </>
  );
};

export default Posts;
