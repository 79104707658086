import React from "react";

const Comment = ({ x }) => {
  // console.log(x)
  return (
    <>
      <li style={{ border: "2px solid black", margin: "5px", padding: "5px" }}>
        <li>name:{x?.from?.name}</li>
        <li>{x?.message}</li>

        <ul style={{ marginLeft: "10px" }}>
          {x?.comments?.data?.map((x) => (
            <Comment x={x} />
          ))}
        </ul>
      </li>
    </>
  );
};

export default Comment;
